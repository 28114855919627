import { WalkthroughService } from 'src/app/walkthrough/walkthrough.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { Platform } from '@angular/cdk/platform';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';
import { Globals } from './globals/globals';
import { ActivitiesService } from './activities/activities.service';
import { StorageService } from './storage/storage.service';
import { AuthService } from './auth/auth.service';
import { EditorToolsService } from './editor-tools/editor-tools.service';
import { ActivityEditorService } from './activities/activity-editor/activity-editor.service';
import { CloudStorageService } from './cloud-storage/cloud-storage.service';
import { MediaStorageService } from './media-storage/media-storage.service';
import { DatabaseService } from './database/database.service';
import { CloudDatabaseService } from './cloud-database/cloud-database.service';
import { UserService } from './user/user.service';
import { SampleBoardsService } from './helpers/sample-boards.service';
import { AudioRecorderService } from './editor-tools/audio-recorder/audio-recorder.service';
import { SubscriptionService } from './subscription/subscription.service';
import { LanguageService } from './language/language.service';
import { WalkthroughModule } from './walkthrough/walkthrough.module';
import { TooltipModule } from './tooltip-pro';
import { ShareActivityPopupModule } from './activities/share-activity-popup/share-activity-popup.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from './user/user.module';
import { InvitesPageModule } from './invites/invites.module';
import { GroupsModule } from './groups/groups.module';
import { EditorToolsModule } from './editor-tools/editor-tools.module';

// import { USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/compat/auth';
// import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from "@angular/fire/compat/functions";
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from "@angular/fire/compat/firestore";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: ''
        }),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWithDelay:5000' }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        TooltipModule,
        WalkthroughModule,
        // ShareButtonsModule, // TODO UPDATE ANGULAR
        ShareActivityPopupModule,
        BrowserAnimationsModule,
        UserModule,
        InvitesPageModule,
        GroupsModule,
        EditorToolsModule
    ],
    providers: [
        StatusBar,
        //SplashScreen,
        AngularFirestore,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Globals,
        EditorToolsService,
        ActivitiesService,
        ActivityEditorService,
        StorageService,
        CloudStorageService,
        MediaStorageService,
        DatabaseService,
        CloudDatabaseService,
        AuthService,
        UserService,
        SampleBoardsService,
        AudioRecorderService,
        SubscriptionService,
        LanguageService,
        WalkthroughService,
        File,
        Platform,
        // Firestore, Functions and Auth Emulators
        // { provide: USE_FIRESTORE_EMULATOR, useValue: ["localhost", 8080] },
        // { provide: USE_FUNCTIONS_EMULATOR, useValue: ["localhost", 5001] },
        // { provide: AUTH_EMULATOR, useValue: ['http://localhost:9099'] },
    ],
    bootstrap: [AppComponent] 
})
export class AppModule { }
