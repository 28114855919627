import { Injectable } from '@angular/core';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import { ActivitiesService } from '../activities/activities.service';
import { ImageCropInfo } from './models/image-crop-info.model';
import { LanguageService } from '../language/language.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class EditorToolsService {

  theme = 'aac';
  goBackCounterAfterImageCrop = 0;

  imageSelectedUrl = '';
  imageSelectedType = '';
  searchText: string;
  imageCropInfo: ImageCropInfo;
  questionImageSrc = '';
  searchedImageSrc = '';
  isSelectedImgFromDevice = false;
  imgSearchSource = '';

  constructor(private navCtrl: NavController, private activitiesService: ActivitiesService, private popoverController: PopoverController,
    public alertController: AlertController, private lang: LanguageService, private sanitizer: DomSanitizer) { }

  imageSelected(imageSrc: string, imageType: string, searchText?: string) {
    this.setImageSelectedUrl(imageSrc, imageType, searchText);
    this.navCtrl.navigateForward('/editor-tools/image-crop');
  }

  setImageCropInfo(imageCropInfo: ImageCropInfo) {
    this.imageCropInfo = imageCropInfo;
  }

  getAndClearImageCropInfo() {
    const imageCropInfo = JSON.parse(JSON.stringify(this.imageCropInfo));
    this.imageCropInfo = undefined;
    return imageCropInfo;
  }

  setImageSelectedUrl(imageUrl: string, imageType: string, searchText?: string) {
    this.imageSelectedUrl = imageUrl;
    this.imageSelectedType = imageType;
    this.searchText = searchText;
  }

  getImageSelectedUrl() {
    this.isSelectedImgFromDevice = false;
    return this.imageSelectedUrl;
  }

  loadImageFromDevice(ev: any) {
    const file = ev.target.files[0];
    let reader = new FileReader();

    if (!file.type.includes('image')) { this.imageNotValidAlert(); return; }

    // Is this a "real" file? In other words, is this an instance of the original `File` class
    // (not the one overriden by cordova-plugin-file). If so, then we need to use the "real"
    // FileReader (not the one overriden by cordova-plugin-file).
    if (file instanceof Blob) {
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
    }

    reader.onload = e => {
      let dataURL = reader.result.toString();

      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const cx = canvas.getContext('2d');

      const img = new Image();

      img.onload = ev => {
        let width = 0;
        let height = 0;
        const aspectRatio = img.width / img.height;

        if (img.width >= img.height) {
          width = 600;
          height = width / aspectRatio;
        } else {
          height = 600;
          width = height * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;

        cx.drawImage(img, 0, 0, width, height);

        dataURL = canvas.toDataURL(file.type);

        this.popoverController.dismiss({action: 'load-image-from-device'});
        this.isSelectedImgFromDevice = true;
        this.goBackCounterAfterImageCrop = -1;
        this.imageSelected(dataURL, file.type);
      };

      img.src = dataURL;
    };

    reader.onerror = (error) => {
      console.log(error);
    };

    reader.readAsDataURL(file);
  }

  loadImageFromDevice2(ev: any) {
    return new Promise((resolve, reject) => {
      const file = ev.target.files[0];
      let reader = new FileReader();

      if (!file.type.includes('image')) { this.imageNotValidAlert(); reject(); return; }

      // Is this a "real" file? In other words, is this an instance of the original `File` class
      // (not the one overriden by cordova-plugin-file). If so, then we need to use the "real"
      // FileReader (not the one overriden by cordova-plugin-file).
      if (file instanceof Blob) {
        const realFileReader = (reader as any)._realReader;
        if (realFileReader) {
          reader = realFileReader;
        }
      }

      reader.onload = e => {
        let dataURL = reader.result.toString();

        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const cx = canvas.getContext('2d');

        const img = new Image();

        img.onload = ev => {
          let width = 0;
          let height = 0;
          const aspectRatio = img.width / img.height;

          if (img.width >= img.height) {
            width = 600;
            height = width / aspectRatio;
          } else {
            height = 600;
            width = height * aspectRatio;
          }

          canvas.width = width;
          canvas.height = height;

          cx.drawImage(img, 0, 0, width, height);

          dataURL = canvas.toDataURL(file.type);

          resolve({ imageSrc: dataURL, imageType: file.type });
        };

        img.src = dataURL;
      };

      reader.onerror = (error) => {
        console.log(error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  async imageNotValidAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      subHeader: this.lang.words.alerts.imageNotValid.subHeader,
      message: this.lang.words.alerts.imageNotValid.message,
      buttons: [this.lang.words.alerts.imageNotValid.button]
    });

    await alert.present();
  }

  loadAudioFromDevice(ev: any) {
    // console.log(ev);
    const file = ev.target.files[0];
    let reader = new FileReader();

    /**
     * For now we only allow audios in format wav and mp3 to be load from device
     */
    if (file.type !== 'audio/wav' && file.type !== 'audio/mpeg') { this.audioNotValidAlert(); return; }

    // Is this a "real" file? In other words, is this an instance of the original `File` class
    // (not the one overriden by cordova-plugin-file). If so, then we need to use the "real"
    // FileReader (not the one overriden by cordova-plugin-file).
    if (file instanceof Blob) {
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
    }

    reader.onload = () => {
      const blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))], { type: file.type });
      const blobUrl: string = URL.createObjectURL(blob);
      this.popoverController.dismiss({ action: 'load-audio-from-device', audioBlobUrl: blobUrl, audioBlob: blob });
    };

    reader.onerror = (error) => {
      console.log(error);
    };

    reader.readAsArrayBuffer(file);
  }

  async audioNotValidAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      subHeader: this.lang.words.alerts.audioNotValid.subHeader,
      message: this.lang.words.alerts.audioNotValid.message,
      buttons: [this.lang.words.alerts.audioNotValid.button]
    });

    await alert.present();
  }
}
