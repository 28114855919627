import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { DatabaseService } from '../database/database.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UserBasicInfo } from './user-basic-info.model';
import { User } from './user.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserContact } from './user-contacts/user-contact/user-contact.model';
import { ActivitiesService } from '../activities/activities.service';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NotificationService } from '../notification/notification.service';
import { FeedbackService } from '../home/feedback/feedback.service';

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userBS = new BehaviorSubject<User>(new User());
  userObs = this.userBS.asObservable();
  private userContactsBS = new BehaviorSubject<UserContact[]>([]);
  userContactsObs = this.userContactsBS.asObservable();
  private userContactsAndGroupsBS = new BehaviorSubject<UserContact[]>([]);
  userContactsAndGroupsObs = this.userContactsAndGroupsBS.asObservable();
  private userContactsSub: Subscription;
  userContacts: UserContact[] = [];
  newContactsCount = 0;

  constructor(private app: Globals, private databaseService: DatabaseService,
    private fns: AngularFireFunctions, private activitiesService: ActivitiesService, public firestore: AngularFirestore,
    private notificationService: NotificationService, public feedbackService: FeedbackService) { }

  updateUserAsync(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.databaseService.updateUserAsync(this.app.user)
        .then((data) => { resolve(data); })
        .catch((err) => reject(err));
    });
  }

  updateUserProps(updates: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.databaseService.updateUser(updates)
        .then((data) => { resolve(data); })
        .catch((err) => reject(err));
    });
  }

  // Atualiza apenas o bloco de estatísticas
  updateStatsAsync(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.databaseService.updateStatsAsync(this.app.user)
        .then((data) => { resolve(data); })
        .catch((err) => reject(err));
    });
  }

  // Registro de eventos nos contadores de estatísticas globais do realtime database
  async realTimeEventCounter(eventName: string) {
    /*var callable = this.fns.httpsCallable('globalEventLoggerIncrement');
    var ret = callable({ event: eventName });
    console.log('realTimeEventCounter: ', ret);*/
    /*const callable = await*/
    this.fns.httpsCallable('globalEventLoggerIncrement')
      ({ event: eventName }).toPromise();
    // console.log('realTimeEventCounter: ', callable);
  }

  userChanged(user: User) {
    this.userBS.next(user);
    this.watchUserContacts(user.uid);
    this.notificationService.watchNotifications(user.uid);

    if (this.app.isUserLogged()) {
      this.feedbackService.watchUserFeedbacks(user.uid);
    }
  }

  private watchUserContacts(uid: string) {
    if (this.userContactsSub) {
      this.userContactsSub.unsubscribe();
    }

    if (uid) {
      this.userContactsSub = this.firestore.collection<ActivityBoard>(`shared-spaces/activities/activity-boards`,
      ref => ref.where('membersUid', 'array-contains', uid).where('isSharedSpace', '==', true)).valueChanges().subscribe(sharedSpaces => {
        //console.log(sharedSpaces);
        const contacts = [];
        const groups = [];
        for (const sharedSpace of sharedSpaces) {
          if (sharedSpace.isContact && !sharedSpace.status.includes('removed')) {
            const contact: UserContact = sharedSpace.membersInfo.find(member => member.uid !== this.app.user.uid);
            if (contact) {
              contact.updatedAt = sharedSpace.updatedAt;
              contacts.push(contact);
            }
          } else if (sharedSpace.isGroup) {
            const group: UserContact = {
              uid: sharedSpace._id,
              name: sharedSpace.title,
              isGroup: sharedSpace.isGroup,
              updatedAt: sharedSpace.updatedAt,
              photo: sharedSpace.img,
            };
            groups.push(group);
          }
        }
        this.userContacts = contacts;
        this.userContactsBS.next(contacts);
        this.userContactsAndGroupsBS.next(contacts.concat(groups));
      });
    } else {
      this.userContacts = [];
      this.userContactsBS.next([]);
      this.userContactsAndGroupsBS.next([]);
    }
  }

  hasViewed(board: ActivityBoard) {
    return board.viewedBy && board.viewedBy.includes(this.app.user.uid);
  }

  updateSharedSpaceUserInfo(userInfo: UserContact) {
    this.activitiesService.activityBoardsSharedSpace.filter(board => board.isSharedSpace).forEach(sharedSpace => {
      this.databaseService.updateSharedSpaceUserInfo(this.app.user.uid, sharedSpace, userInfo);
    });
  }

  deleteUserProfilePhoto(updateSharedSpace = false) {
    if (updateSharedSpace) {
      const userInfo: UserBasicInfo = { uid: this.app.user.uid, name: this.app.user.name, photo: '' };
      this.updateSharedSpaceUserInfo(userInfo);
    }
    const profile = Object.assign(this.app.user.profile, { photo: '' });
    this.databaseService.updateUser({ profile });
  }

  async getTrackingStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    //console.log('getTrackingStatus')
    //console.log(response);

    return response;
  }

  async requestTrackingPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    //console.log(response);

    return response;
  }

  deleteAccount() {
    return this.fns.httpsCallable('deleteUser')({}).toPromise();
  }
}
