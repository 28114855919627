import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Globals } from '../globals/globals';
import { UserService } from '../user/user.service';
import { AppSubscription } from './subscription.model';
// import { IAPProduct, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
import { Platform } from '@ionic/angular';
import { StorageService } from '../storage/storage.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js';
import { InAppPurchaseService } from './in-app-purchase/in-app-purchase.service';
import { UtilsService } from '../utils/utils.service';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  subscriptionFlowState = '';
  subscription = new AppSubscription();
  discountPromoCode = '';

  constructor(public actionSheetController: ActionSheetController, public navCtrl: NavController, public storage: StorageService,
    public alertController: AlertController, public app: Globals, public userService: UserService, public modalController: ModalController,
    public platform: Platform, public firestore: AngularFirestore, public fns: AngularFireFunctions, public http: HttpClient,
    public loadingController: LoadingController, private inAppPurchaseService: InAppPurchaseService, private utils: UtilsService,
    private analytics: AnalyticsService) { }

  /**
   * Subscribes to PRO or Essential Plan. When running on native app, we use in app puchase to proceed to checkout.
   * On web, we use stripe platform instead.
   */
  async subscribeToPlan(planId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {

      // const checkoutMethod = this.app.isRunningOnNative() && !this.app.isRunningOnAndroidApp() ? 'in-app-purchase' : 'stripe';
      let checkoutMethod = this.app.isRunningOnNative() ? 'in-app-purchase' : 'stripe';

      try {
        await this.utils.showLoading('Preparando assinatura');

        const fetchSettingsTimeout = setTimeout(() => {
          throw new Error('fetching settings timed out');
        }, 5000);

        const settingsDoc = await this.firestore.collection<{ checkoutMethodForAndroidApp: string }>('in-app-purchase').doc('settings').get({ source: 'server' }).toPromise();
        clearTimeout(fetchSettingsTimeout);
        
        const settings = settingsDoc.data();

        // To minimize the impact of changing the checkout method for android device to use in app purchase,
        // there is a way of falling back to stripe by setting checkoutMethodForAndroidApp to 'stripe'.
        if (this.app.isRunningOnAndroidApp() && settings?.checkoutMethodForAndroidApp === 'stripe') {
          checkoutMethod = 'stripe';
        }
      } catch (err) {
        console.log(err);
      }

      // Closes preparing subscription loading. 
      this.utils.closeLoading();

      if (this.app.canCreateNewSubscription()) {
        switch(checkoutMethod) {
          case 'in-app-purchase': {
            // Hardcoded promotion. Usuários que preencherem que conheceu o Expressia no CBF 2024, podem resgatar o cupom até o dia 30/11.
            // Este trecho será removido nas próximas versões e será implementado um mecanismo dinámico para obter promoções.
            if (planId === 'expressia_pro' && this.app.user.profile.howDidYouHear === 'Congresso Brasileiro de Fonoaudiologia' && Date.now() < 1733021999000) {
              planId = planId + '_35_off_lifetime';
            }
            
            this.inAppPurchaseService.subscribeToPlan(planId).then(() => resolve()).catch(err => reject(err));
            break;
          }
          default: {
            this.subscribeToPlanWithStripe(planId).then(() => resolve()).catch(err => reject(err));
          }
        }
      } else {
        this.userAlreadySubscribedAlert();
        reject(new Error('user-already-subscribed'));
      }
    });
  }

  /**
   * Open the subscription management interface for the current platform.
   */
  manageSubscriptions() {
    this.analytics.logEvent('subscription_manage');
    switch(this.app.user.subscription.origin) {
      case 'app-store': {
        this.analytics.logEvent('subscription_app_store_manage');

        if (this.app.isRunningOnNative() && !this.app.isRunningOnAndroidApp() ) {
          this.inAppPurchaseService.manageSubscriptions();
        } else {
          window.location.href = 'https://apps.apple.com/account/subscriptions';
        }
        break;
      }
      case 'play-store': {
        this.analytics.logEvent('subscription_play_store_manage');

        // Encaminha para gerenciador de assinatura do Google Play
        if (this.app.isRunningOnAndroidApp() ) {
          this.inAppPurchaseService.manageSubscriptions();
        } else {
          window.location.href = 'https://play.google.com/store/account/subscriptions';
        }
        break;
      }
      default: {
        this.analytics.logEvent('subscription_stripe_manage');
        this.stripeManageSubscriptions();
        break;
      }
    }
  }

  private async userAlreadySubscribedAlert() {
    this.analytics.logEvent('subscribe_user_already_subscribed_alert');
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: 'Você já possui uma assinatura',
      message: 'Acesse o gerenciador de assinaturas para cancelar a assinatura atual se desejar trocar de plano',
      buttons: [
        {
          text: 'Entendido!'
        },
        {
          text: 'Gerenciar assinatura',
          handler: () => {
            this.manageSubscriptions();
          }
        }
      ]
    });
    await alert.present();
  }

  showSubscriptionSuccessPage() {
    this.navCtrl.navigateForward('/subscription-success');
  }

  setDiscountPromoCode(promoCode: string) {
    this.discountPromoCode = promoCode;
  }

  // *************************************************//
  // Funções do Stripe para criar e gerenciar        //
  // assinaturas no navegador                        //
  // *************************************************//
  private subscribeToPlanWithStripe(planId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.analytics.logEvent('subscribe_with_in_app_purchase_init');

      let priceID = '';

      switch(planId) {
        case 'expressia_essential': {
          priceID = this.app.user.local.stripeAacPriceID;
          break;
        }
        case 'expressia_pro': {
          priceID = this.app.user.local.stripeActivitiesPriceID;
          break;
        }
      }

      const loading = await this.loadingController.create({
        cssClass: 'app-standard-loading',
        message: 'Abrindo ambiente de compra seguro',
        spinner: 'dots',
        animated: false
      });
  
      await loading.present();
  
      if (priceID) {
        this.checkout(priceID).then(() => resolve()).catch(err => reject(err)).finally(() => loading.dismiss());
      } else {
        loading.dismiss();
        reject(new Error('stripe-checkout-invalid-priceID-error'));
      }
    });
  }

  // Replace planID with a SKU for your own product (created either in the Stripe Dashboard or with the API)
  async checkout(priceID): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const stripe = await loadStripe(environment.stripePublishableKey);
        const docRef = await this.firestore
          .collection('customers')
          .doc(this.app.user.uid)
          .collection('checkout_sessions')
          .add({
            price: priceID,
            allow_promotion_codes: true,
            promotion_code: this.discountPromoCode,
            success_url: 'https://web.expressia.life/subscription-success',
            cancel_url: 'https://web.expressia.life',
            billing_address_collection: 'required',
            tax_id_collection: {enabled: true}, //https://docs.stripe.com/api/payment_links/payment_links/create#create_payment_link-tax_id_collection
            customerEmail: this.app.user.email
          });

        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and then inspect your function logs.
            alert(`Ocorreu um erro: ${error.message}`);
            document.querySelectorAll('button').forEach((b) => (b.disabled = false));
          }
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            try {
              // Init Stripe
              stripe.redirectToCheckout({ sessionId }).then(this.handleStripeCheckoutResult);

              // Waits a little bit just to prevent the loading from dimissing before redirecting to stripe.
              setTimeout(() => {
                resolve();
              }, 5000);
            } catch (error) {
              console.error('checkout() try catch error', error);
              reject(error);
            }
          } else {
            reject(new Error('stripe-checkout-no-session-id-error'));
          }
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  handleStripeCheckoutResult(result) {
    //console.log('handleResult()', result);
  }

  // Gerencia assinaturas existentes: cancelar, editar cartão, trocar de plano
  async stripeManageSubscriptions() {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: 'Abrindo gerenciador de assinatura',
      spinner: 'dots',
      animated: false
    });

    await loading.present();
    setTimeout(() => { loading.dismiss(); }, 8000);

    // Call billing portal function
    const callable = await this.fns.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
      ({ returnUrl: 'https://web.expressia.life' }).toPromise();
    window.location.assign(callable.url);
  }
}
