import { Injectable } from '@angular/core';
import { DatabaseService } from '../../database/database.service';
import { Globals } from 'src/app/globals/globals';
import { ExpressiaAnalyticsEvent } from './expressia-analytics-event.model';

@Injectable({
  providedIn: 'root'
})
export class ExpressiaAnalyticsService {

  // A temporary queue (buffer) that holds the events generated before the initialization of the user authentication.
  private eventsQueue: Array<ExpressiaAnalyticsEvent> = [];

  // Flag that indicates if the user authentication has been initiated.
  private hasAuthInitiated = false;

  constructor(private database: DatabaseService, private app: Globals) { }

  logEvent(eventName: string , eventParams?: any, paramName?: string) {
    this.recordUserStatistics(eventName);
    
    // Expressia event object.
    const expressiaEvent = this.createExpressiaEventObj(eventName, eventParams, paramName);

    // Adds the event in the events queue.
    this.eventsQueue.push(expressiaEvent);

    // Dispatches the events if the auth has been initiated.
    if (this.hasAuthInitiated) {
      this.dispatchEventsQueue();
    }
  }

  recordUserStatistics(eventName: string) {
    let updates = null;
    switch(eventName) {
      case 'aac_board_store_add': { updates = { statistics: { aacBoardStoreAdd: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_board_create': { updates = { statistics: { aacBoardsCreated: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_card_create': { updates = { statistics: { aacCardsCreated: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'aacLockAccepted': { updates = { statistics: { aacLockAccepted: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'aacLockDisplayed': { updates = { statistics: { aacLockDisplayed: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_sentence_bar_play': { updates = { statistics: { aacSentencesSpoken: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_shared_board_save': { updates = { statistics: { aacSharedSaved: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_card_create': { updates = { statistics: { activitiesCardsCreated: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_end': { updates = { statistics: { activitiesConcluded: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_create': { updates = { statistics: { activitiesCreated: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressiacase 'activitiesLockAccepted': { updates = { statistics: { activitiesLockAccepted: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'activitiesLockDisplayed': { updates = { statistics: { activitiesLockDisplayed: this.app.firebaseIncrement(1) }}; break; }
      // Evento não implementado no analytics do Expressia case 'activitiesReceived': { updates = { statistics: { activitiesReceived: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_link_share': { updates = { statistics: { activitiesShared: this.app.firebaseIncrement(1) }}; break; }
      // // Evento não implementado no analytics do Expressia case 'activitiesSharedPlayed': { updates = { statistics: { activitiesSharedPlayed: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_shared_save': { updates = { statistics: { activitiesSharedSaved: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_start': { updates = { statistics: { activitiesStarted: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_store_add': { updates = { statistics: { activityStoreAdd: this.app.firebaseIncrement(1) }}; break; }
      case 'activity_store_play_btn_click': { updates = { statistics: { activityStorePlayBtnClicked: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_pdf_export': { updates = { statistics: { aacPdfExport: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_card_image_origin_from_device': { updates = { statistics: { aacCardImageOriginFromDevice: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_card_image_origin_from_pictograms': { updates = { statistics: { aacCardImageOriginFromPictograms: this.app.firebaseIncrement(1) }}; break; }
      case 'aac_card_image_origin_from_internet': { updates = { statistics: { aacCardImageOriginFromInternet: this.app.firebaseIncrement(1) }}; break; }
    }

    if (updates) {
      this.database.updateUser(updates);
    }
  }

  /**
   * Handles when the authentication is initiated.
   */
  onAuthStateInitiated() {
    // Wait a little bit just to avoid sending the events at the app's startup.
    setTimeout(() => {
      // Updates the user uid on events inside the queue.
      this.eventsQueue.forEach(ev => {
        ev.uid = this.app.user.uid;
      });  

      // Dispatches the events.
      this.dispatchEventsQueue();

      // The user authentication has been initiated!
      this.hasAuthInitiated = true;
    }, 5000);
  }

  /**
   * Creates the object that represents an analytics event from Expressia Analytics.
   */
  private createExpressiaEventObj(eventName: string , eventParams?: any, paramName?: string) {
    const ev: ExpressiaAnalyticsEvent = {
      eventName: eventName,
      timestamp: Date.now(),
      timezoneOffset: (new Date()).getTimezoneOffset(),
      serverTimestamp: this.app.timeNow()
    };
    // Adds the event params, if any.
    if (eventParams) { ev.params = eventParams; }

    // Adds the event param name, if any.
    if (paramName) { ev.paramName = paramName; }

    let user = this.app.user;
    if (user && user.uid) {
      ev.uid = user.uid;
      ev.guestId = localStorage.getItem('guest-id') || "";
    } else {
      // Usuário não está logado, vamos atribuir um código único para a sessão do Expressia para conseguirmos identificar
      // o acesso do usuário não logado.
      let guestId = localStorage.getItem('guest-id');
      if (guestId) {
        ev.guestId = guestId;
      } else {
        guestId = `${this.app.firebaseCreateId()}`;
        ev.guestId = guestId;
        localStorage.setItem('guest-id', guestId);
      }
    }

    return ev;
  }

  /**
   * Dispatches the events queue.
   */
  private dispatchEventsQueue() {
    const queueLenght = this.eventsQueue.length;

    for (let i = 0; i < queueLenght; i++) {
      // Removes the first event from the queue.
      const ev = this.eventsQueue.shift();

      // Dispatch the event.
      this.database.logEvent(ev);
    }
  }
}
