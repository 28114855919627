import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { GridSettings } from './grid-settings.model';
import { GridService } from '../grid.service';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-grid-settings',
  templateUrl: './grid-settings.component.html',
  styleUrls: ['./grid-settings.component.scss'],
})
export class GridSettingsComponent  implements OnInit {

  @Input() gridSettings: GridSettings;

  // Max range allowed for the width of the grid item. It can be the full width or 70% of the height (since 30% is the item's title)
  // depending on which is the lowest.
  maxItemSizeRange = window.innerWidth < window.innerHeight ? window.innerWidth : (0.7 * window.innerHeight);

  constructor(private popoverController: PopoverController, private gridService: GridService, public app: Globals, public lang: LanguageService) { }

  ngOnInit() {
    if (this.app.user.preferences.useScanMode) {
      this.gridSettings.type = 'fixed';
    }
  }

  async onBackBtnClicked() {
    const topPopover = await this.popoverController.getTop();
    topPopover.animated = false;
    this.popoverController.dismiss('back');
  }

  async onGridTypeChanged(ev: any) {
    //console.log('Grid type changed!');
    setTimeout(() => {
      this.gridService.dispatchGridTypeChangeEvent(ev.detail.value);
      this.gridService.resizeGrid();
    }, 1);
  }

  onItemSizeChanged(ev: any) {
    //console.log('Item Size changed!');
    this.gridService.setItemSize(ev.detail.value);
  }

  onGridRowsChanged(ev: any) {
    //console.log('Grid rows changed!');
    this.gridService.resizeGrid();
  }

  onGridColsChanged(ev: any) {
    //console.log('Grid cols changed!');
    this.gridService.resizeGrid();
  }

  onSpacingValueChanged(ev: any) {
    const spacing = ev.detail.value;
    this.gridSettings.horizontalSpacing = spacing;
    this.gridSettings.verticalSpacing = spacing;
    this.gridService.resizeGrid();
  }
}
