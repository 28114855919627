import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';
import { Globals } from '../globals/globals';
import { AnalyticsService } from '../analytics/analytics.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private token = '';

  constructor(private app: Globals, private analytics: AnalyticsService, private navCtrl: NavController) {}

  /**
   * Initialize the push notification.
   */
  async initPushNotification() {
    if (this.app.isRunningOnNative()) {
      await this.addPushNotificationListeners();
      await this.registerNotifications();
    }
  }

  /**
   * Refreshes the user FCM token.
   */
  async refreshUserFcmToken() {
    if (this.token) {
      this.analytics.logEvent('push_notification_registration_success', { token: this.token });
    }
  }

  /**
   * Register in the notification system to be able to receive push notification.
   */
  private async registerNotifications() {
    try {
      let permStatus = await PushNotifications.checkPermissions();
  
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
    
      if (permStatus.receive !== 'granted') {
        this.analytics.logEvent('push_notification_permission_deny');
        throw new Error('User denied permissions!');
      }
    
      await PushNotifications.register();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Adds listeners for push notification's events.
   */
  private async addPushNotificationListeners() {
    await PushNotifications.addListener('registration', token => {
      this.onPushNotificationRegistrationSuccess(token);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      this.onPushNotificationRegistrationError(err);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      this.onPushNotificationReceived(notification);
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
      this.onPushNotificationActionPerformed(actionPerformed);
    });
  }

  /**
   * Handles when the push notification is registered.
   * @param token token object.
   */
  onPushNotificationRegistrationSuccess(token: Token) {
    //console.log('Registration token: ', token.value);
    this.token = token.value;
    this.analytics.logEvent('push_notification_registration_success', { token: token.value });
  }

  /**
   * Handles when the push notification registration fails.
   * @param error 
   */
  onPushNotificationRegistrationError(err: RegistrationError) {
    console.error('Registration error: ', err.error);
    this.analytics.logEvent('push_notification_registration_error', { err: err.error });
  }

  /**
   * Handles when a push notification is received.
   */
  onPushNotificationReceived(notification: PushNotificationSchema) {
    // console.log('Push notification received: ', notification);
    const notificationId = notification.data?.id || '';
    this.analytics.logEvent(`push_notification_${notificationId}_receive`, { notification });
  }

  /**
   * Handles when the user clicks on notification. 
   */
  onPushNotificationActionPerformed(actionPerformed: ActionPerformed) {
    // console.log('Push notification action performed', actionPerformed.actionId, actionPerformed.inputValue);
    const notificationId = actionPerformed.notification.data?.id || '';
    this.analytics.logEvent(`push_notification_${notificationId}_action_perform`, { actionPerformed });

    // Navigates to the relative url provided in the notification's data, in the navTo prop. 
    if (actionPerformed.notification?.data?.navTo) {
      this.navCtrl.navigateForward(actionPerformed.notification.data.navTo);
    }
  }
}
