import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { Globals } from '../globals/globals';
import { PlanFeatures } from './plan-features.model';
import { Plan } from './plan.model';

// tslint:disable:max-line-length

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  FREE_SLOTS = 3;

  plan: Plan;
  planBS: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(new Plan());

  constructor(private app: Globals, private firestore: AngularFirestore) { }

  /**
   * Updates the user plan based on a plan features. The plan features contains all features present in a plan taking into account
   * plataform-specific features. It's important to mention that a plan is more releated with the conditions at runtime rather than
   * static as plan features. For example, on activities plan, the plan features it has won't change at runtime. But the plan may
   * change if the user subscription expire or when the trial period ends.
   */
  updatePlan(planFeat: PlanFeatures) {
    let planFeatures = planFeat;
    if (this.app.isUserLogged()) {

      /**
       * Check if the plan has expired. If so, we considere the plan with free features
       */
      let hasExpired = false;
      if (new Date().getTime() > this.app.user.subscription.expiryTimeMillis) {
        planFeatures = new PlanFeatures('free');
        hasExpired = true;
      } else {
        if (this.plan) { this.plan.freeSlotsAvailable = this.FREE_SLOTS; }
      }

      /**
       * Create a plan based on the plan features. The plan features has all features
       * available in the app and also takes account the platform that the app is running.
       * On the other hand, a plan is a more general class that also contains features related
       * stuff, but with the platform-specific already resolved.
       */
      let plan: Plan;
      if (this.app.isRunningOnNative()) {
        plan = planFeatures.createPlanForApp();
      } else {
        plan = planFeatures.createPlanForWeb();
      }

      // Set the expiry info and also finds out the plan type based on the subscription id
      plan.hasExpired = hasExpired;
      plan.type = Plan.subscriptionIdToPlanType(this.app.user.subscription.id);

      // Set the original plan feature that we recived as parameter of this method
      plan.features = planFeat;

      // Keep track of available free slots before overwriting the plan
      plan.freeSlotsAvailable = this.plan ? this.plan.freeSlotsAvailable : this.FREE_SLOTS;

      this.plan = plan;
    } else {
      /**
       * When not logged, the base plan is free
       */
      planFeatures = new PlanFeatures('free');
      let plan: Plan;
      if (this.app.isRunningOnNative()) {
        plan = planFeatures.createPlanForApp();
      } else {
        plan = planFeatures.createPlanForWeb();
      }

      /**
       * If the user is not logged in, him/her can play the activities for a few time
       * before we lock it.
       */
      if (this.app.activitiesPlayCount >= 10) {
        plan.canAccessActivities = false;
      } else {
        plan.canAccessActivities = true;
      }

      // Set the free plan features since the user is not logged
      plan.features = planFeatures;

      this.plan = plan;
    }

    // Set the plan's name
    this.plan.name = this.getPlanName();

    // Set the plan's status
    this.plan.status = this.getPlanStatus();

    // Send an update of the plan to everyone who is listening to it
    this.planBS.next(this.plan);
  }

  setfreeSlotsAvailable(value: number) {
    if (this.plan && this.plan.freeSlotsAvailable !== value) {
      this.plan.freeSlotsAvailable = value;
      this.planBS.next(this.plan);
    }
  }

  /**
   * Checks if the user has full access of the app's features.
   * @returns true if the use has access to all features in the app
   */
  hasFullAccess() {

    if (this.app.isBetaTesting() || this.app.user.isSubscriber() || this.app.user.plan.planLockEnabled === false) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if the user can use remote text to speech (natural voice)
   * @returns true if the use use remote tts
   */
  canUseRemoteTts() {
    return this.app.user.subscription.id === 'monthly_subscription' && (!this.app.user.plan.planLockEnabled || this.app.user.subscription.isActive);
  }

  /**
   * Gets the plan status
   * @returns plan's status
   */
  getPlanStatus() {
    if (!this.app.user.plan.planLockEnabled || this.app.user.subscription.isActive) {
      return 'active';
    } else {
      return 'free';
    }
  }

  /**
   * Gets the plan name.
   * @returns plan's name
   */
  getPlanName() {
    let planId = '';

    if (!this.app.user.plan.planLockEnabled || this.app.user.subscription.isActive) {
      planId = this.app.user.subscription.id;
    } else {
      planId = 'free';
    }

    switch(planId) {
      case 'monthly_subscription': {
        return 'Expressia PRO';
      }
      case 'monthly_subscription_aac': {
        return 'Expressia Essencial';
      }
      case 'free': {
        return 'Versão gratuíta';
      }
    }
  }

  /**
   * Checkes if the current plan includes access to activities module
   * @returns true if the plan includes activities module or false otherwise. 
   */
  includesActivitiesModule() {
    return this.app.user.subscription.id === 'monthly_subscription';
  }

  /**
   * Clears the current plan.
   */
  clearPlan() {
    this.plan = new Plan();

    // Send an update of the plan to everyone who is listening to it
    this.planBS.next(this.plan);
  }
}
