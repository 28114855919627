import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Globals } from 'src/app/globals/globals';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

const APPLE_SUBSCRIPTIONS = ['expressia_essential', 'expressia_pro', 'expressia_pro_35_off_lifetime'];
const GOOGLE_SUBSCRIPTIONS = ['expressia_essential', 'expressia_pro', 'expressia_pro_35_off_lifetime'];

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {

  validProduct: boolean = false;
  products = [];
  store?: CdvPurchase.Store;
  transactionApprove = new EventEmitter<CdvPurchase.Transaction>();
  loadingValidationTimeout = null;

  constructor(private plt: Platform, public fns: AngularFireFunctions, private utils: UtilsService, private authService: AuthService,
    private alertController: AlertController, private modalController: ModalController, private app: Globals, private analytics: AnalyticsService) {
    // console.log('InAppPurchaseService');
    this.plt.ready().then(() => {
      this.store = CdvPurchase.store;
      this.initialize();
    });
  }

  /**
   * Subscribes to Expressia PRO or Essential plan using in app purchase
   * @param productId Plan's id 
   */
  subscribeToPlan(productId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.analytics.logEvent('subscribe_with_in_app_purchase_init');

      // Gets the in app purchase product.
      const product = this.store.get(productId);

      this.showSubscriptionDetail(product).then(() => {
        this.subscribeToPlanAfterAgreement(productId).then(() => resolve()).catch(err => reject(err));
      }).catch(err => {
        reject(err);
      });
    });
  }

  /**
   * Open the subscription management interface for the current platform.
   */
  manageSubscriptions() {
    const platform = this.plt.is('android') ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE;
    this.store.manageSubscriptions(platform);
  }

  /**
  * Initialize the in-app purchase plugin
  */
  private async initialize() {
    // console.log('initialize store');

    // We should first register all products or we cannot use them in the app.
    const products = [
      ...APPLE_SUBSCRIPTIONS.map(id => ({ id, platform: CdvPurchase.Platform.APPLE_APPSTORE, type: CdvPurchase.ProductType.PAID_SUBSCRIPTION })),
      ...GOOGLE_SUBSCRIPTIONS.map(id => ({ id, platform: CdvPurchase.Platform.GOOGLE_PLAY, type: CdvPurchase.ProductType.PAID_SUBSCRIPTION })),
    ];

    // console.log('Products: ', products);

    this.store.register(products);
    
    this.setupEventHandlers();

    // Load information about products and purchases
    await this.store.initialize([
      CdvPurchase.Platform.GOOGLE_PLAY,
      CdvPurchase.Platform.APPLE_APPSTORE,
    ])
    .then((value: CdvPurchase.IError[]): void => {
      // console.log(value);
      // console.log('STORE INITIALIZED!');
      // console.log('PRODUCTS: ', this.store.products);

      // Deals with unfinished in app purchase checkout (if any)
      this.manageUnfinishedCheckoutSession();
    });
  }

  // Setups event handles for in app purchases.
  private setupEventHandlers() {
    this.store.when()
      .approved(transaction => {
        // console.log('VERIFY TRANSACTION: ', transaction);
        const productId = transaction?.products[0]?.id || '';
        const transactionObj = {
          ...transaction,
          parentReceipt: transaction.parentReceipt
        }
        localStorage.setItem(`in-app-purchase-lastest-local-transaction-${productId}`, JSON.stringify(transactionObj));
        transaction.finish();
      });

    this.store.error(error => {
      console.log(error);
      if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
        // console.log('The user cancelled the purchase flow.');
        return;
      }
    });
  }

  private subscribeToPlanAfterAgreement(productId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const platform = this.plt.is('android') ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE;

      try {
        try {
          // Shows a loading while we are checking if there is a subscription associated with App or Play Store account.
          await this.utils.showLoading('Abrindo ambiente de compra seguro');

          // Checks if there is an in app subscription associated with App or Play Store account.
          const res = await this.checkIfHasInAppSubscription();

          if (res.status === 'subscription-exists-for-receipt') {
            this.alreadyHasSubscriptionInAppPurchaseAlert(res.email);
            throw new Error(res.status);
          }

        } catch (err) {
          this.utils.closeLoading();
          throw new Error(err);
        }

        // console.log('SETING ONGOING IN APP PURCHASE FLAG: ', productId);
        if (this.hasUnfinishedCheckoutSession()) {
          await this.utils.closeLoading();
          this.unfinishedCheckoutSessionAlert();
          throw new Error('unfinished-in-app-purchase-checkout-session-error');
        } else {
          // Sets the productId for the current in app purchase checkout session.
          this.setCheckoutSessionProductId(productId)
        }

        this.store.get(productId, platform)?.getOffer()?.order()
        .then(async error => {
          if (error) {
            console.log(error);
  
            if (error?.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
              // payment cancelled by the user
              // console.log('payment cancelled by the user');

              // Clears the in app purchase checkout session.
              this.clearCheckoutSession();
            }
            this.utils.closeLoading();
            reject(error);
          } else {
            this.utils.closeLoading();

            // Waits a litte bit to give time for the just made transaction reaches the device.
            this.utils.showLoading('Finalizando compra')
            setTimeout(() => {
              this.utils.closeLoading();
              // Validates the subscription that was just made.
              const latestLocalTransaction = this.getLatestLocalTransaction(productId);
              this.validateSubscription(latestLocalTransaction, { throwErrorIfUndefined: true })
                .then(() => { 
                  this.analytics.logEvent(`in_app_purchase_${platform.replace('-','_')}_subscribe`, { id: productId });
                  resolve(); 
                })
                .catch(err => {
                  this.analytics.logEvent(`in_app_purchase_${platform.replace('-','_')}_validate_error`, { id: productId });
                  reject(err);
                });
            }, 3000);
          }
        })
        .catch(err => {
          this.utils.closeLoading();
          console.log(err);
          reject(err);
        })
      } catch(err) {
        reject(err);
      }
    });
  }

  private validateSubscription(transaction: CdvPurchase.Transaction, opts = { throwErrorIfUndefined: false }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      // Validates the subscription that was just made.
      try {
        if (transaction) {
          // Shows a short loading after the purchase while the transaction is being validated.
          await this.utils.showLoading('Validando assinatura');

          const res = await this.verifyTransaction(transaction);
          await this.handleVerifyTransactionReponse(res);

          // Closes the loading.
          await this.utils.closeLoading();
        } else if (opts.throwErrorIfUndefined) {
          throw new Error('validate-subscription-transaction-undefined-error');
        }

        // Clears the in app purchase checkout session.
        this.clearCheckoutSession();
        resolve();
      } catch(err) {
        console.log(err);

        // Closes the loading.
        await this.utils.closeLoading();

        // Shows an alert telling the user about the error during the subscription validation.
        this.validateSubscriptionErrorAlert();

        reject(err);
      }
    });
  }

  /**
  * Verifies the transaction.
  */
  private verifyTransaction(transaction: CdvPurchase.Transaction): Promise<{ status: string }> {
    return new Promise((resolve, reject) => {
      this.verifyReceipt(transaction.parentReceipt).then(res => {
        // console.log(res);
        resolve(res);
      }).catch(err => {
        console.log(err);
        // console.log('TODO: Handle error');
        reject(err);
      });
    });
  }

  private async handleVerifyTransactionReponse(res: { status: string }) {
    switch(res.status) {
      case 'subscription-expired':
      case 'subscription-exists-for-receipt':
      case 'subscription-created': {
        try {
          await this.authService.refreshUser();
        } catch (err) {
          console.log(err);
        }
        break;
      }
      default: {
        throw new Error(res.status);
      }
    }
  }

  private getLatestLocalTransaction(productId?: string, opts = { fromStorage: false }) {
    if (productId && !opts.fromStorage) {
      const product = this.store.products.find(p => p.id === productId);
      const latestTransaction = this.store.findInLocalReceipts(product);
      return latestTransaction;
    } else if (productId) {
      try {
        const latestTransaction = JSON.parse(localStorage.getItem(`in-app-purchase-lastest-local-transaction-${productId}`));
        return latestTransaction;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    } else {
      let latestTransaction = undefined;
      try {
        latestTransaction  = JSON.parse(localStorage.getItem(`in-app-purchase-lastest-local-transaction-expressia_pro`));
        return latestTransaction;
      } catch (err) {
        console.log(err);
      }

      try {
        latestTransaction  = JSON.parse(localStorage.getItem(`in-app-purchase-lastest-local-transaction-expressia_essential`));
        return latestTransaction;
      } catch (err) {
        console.log(err);
      }

      try {
        latestTransaction  = JSON.parse(localStorage.getItem(`in-app-purchase-lastest-local-transaction-expressia_pro_35_off_lifetime`));
        return latestTransaction;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    }
  }

  /**
  * Verifies the transaction's receipt regarding the subscription made in app.
  */
  private verifyReceipt(receipt: CdvPurchase.Receipt) {
    return this.fns.httpsCallable('verifyInAppPurchaseReceipt')({ receipt }).toPromise();
  }

  private checkIfHasInAppSubscription(): Promise<{ status: string, email?: string }>  {
    return new Promise((resolve, reject) => {
      const latestLocalReceipt = this.getLatestLocalTransaction()?.parentReceipt;
      if (latestLocalReceipt) {
        this.fns.httpsCallable('checkIfHasInAppSubscription')({ latestLocalReceipt }).toPromise()
          .then((res) => resolve(res))
          .catch(err => reject(err));
      } else {
        resolve({ status: 'latest-local-receipt-not-found' });
      }
    });
  }

  private async alreadyHasSubscriptionInAppPurchaseAlert(email: string) {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert-large',
      header: 'Você já possui uma assinatura do Expressia pela loja de aplicativos',
      message: `A sua assinatura está vinculada ao email <strong>${email}</strong><br><br>Acesse o gerenciador de assinaturas para cancelar a assinatura atual se desejar trocar de plano ou vincular a assinatura a outra conta do Expressia.<br><br>Se você precisar de ajuda, entre em contato conosco por<br><br><a href='https://api.whatsapp.com/send?phone=5531994285993&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20app%20Expressia.' target='_blank'>WhatsApp<br> +55 31 99428-5993</a> <br><br>ou por e-mail <br>suporte@expressia.life.<br><br>Obrigado :)`,
      buttons: [
        {
          text: 'Entendido!',
        },
        {
          text: 'Gerenciar assinatura',
          handler: () => {
            this.manageSubscriptions();
          }
        }
      ]
    });
    await alert.present();
  }

  private async validateSubscriptionErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert-large',
      header: 'Opss... Encontramos um problema ao validar sua assinatura.',
      message: `Por favor, verifique sua conexão com a internet, feche e abra o aplicativo novamente.<br><br>Se o problema persistir, entre em contato conosco por<br><br><a href='https://api.whatsapp.com/send?phone=5531994285993&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20app%20Expressia.' target='_blank'>WhatsApp<br> +55 31 99428-5993</a> <br><br>ou por e-mail <br>suporte@expressia.life.<br><br>Obrigado :)`,
      buttons: [
        {
          text: 'Entendido!',
        }
      ]
    });
    await alert.present();
  }

  private async unfinishedCheckoutSessionAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert-large',
      header: 'Opss... Detectamos um tentativa de assinatura que não foi finalizada',
      message: `Por favor, verifique sua conexão com a internet, feche e abra o aplicativo novamente.<br><br>Se o problema persistir, entre em contato conosco por<br><br><a href='https://api.whatsapp.com/send?phone=5531994285993&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20app%20Expressia.' target='_blank'>WhatsApp<br> +55 31 99428-5993</a> <br><br>ou por e-mail <br>suporte@expressia.life.<br><br>Obrigado :)`,
      buttons: [
        {
          text: 'Entendido!',
        }
      ]
    });
    await alert.present();
  }

  async showSubscriptionDetail(product: CdvPurchase.Product): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        mode: 'md',
        cssClass: 'app-standard-alert-large',
        header: 'Confira os detalhes da assinatura:',
        message: `Plano ${product?.title}<hr>Assinatura auto-renovável com intervalo de renovação mensal.<hr>Preço: ${product?.pricing?.price} por mês<hr><a  class="app-text-small" href="https://expressia.life/privacidade" target="_blank">Política de Privacidade</a><hr><a class="app-text-small" href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">Termos de Uso</a>`,
        buttons: [
          {
            role: 'cancel',
            text: 'Voltar',
            cssClass: 'app-text-transform-none',
            handler: () => {
              reject();
            }
          },
          {
            text: 'Ir para pagamento',
            cssClass: 'app-alert-button-success',
            handler: () => {
              resolve();
            }
          }
        ]
      });
      await alert.present();
    });
  }

  /**
   *  Too ugly code to make sure that the validation for unfinished checkout occurs only if the app is online.
   *  It is important because we should ensure that all in app purchase transaction has been received in
   *  the device before attempting to validate the lastest transaction.
   */
  private async manageUnfinishedCheckoutSession() {
    // Check if the app is online three times before handling unfinished checkout session.
    if (this.hasUnfinishedCheckoutSession()) {
      this.app.isOnline().then(async () => {
        await this.utils.showLoading('Verificando assinatura');

        setTimeout(() => {
          this.app.isOnline().then(() => {
            setTimeout(() => {
              this.app.isOnline().then(async () => {
                setTimeout(async () => {
                  this.store.update().then(async () => {
                    clearTimeout(verifySubscriptionTimeout);
                    await this.utils.closeLoading();

                    const productId = this.getCheckoutSessionProductId();
                    const latestLocalTransaction = this.getLatestLocalTransaction(productId, { fromStorage: true });
                    this.validateSubscription(latestLocalTransaction);
                  });
                }, 5000);
              });
            }, 5000);
          });
        }, 5000);

        const verifySubscriptionTimeout = setTimeout(async () => {
          await this.utils.closeLoading();
        }, 17000);
      })
    }
  }

  /**
   * Checks if there is an unfinished in app purchase.
   * @returns true if there is an unfinished in app purchase.
   */
  private hasUnfinishedCheckoutSession() {
    return localStorage.getItem('checkout-session-in-app-purchase') ? true : false;
  }

  /**
   * Gets productId of the in app purchase checkout session
   * @returns 
   */
  private getCheckoutSessionProductId() {
    return localStorage.getItem('checkout-session-in-app-purchase');
  }

  /**
   * Sets the productId for the current in app purchase checkout session.
   * @param productId 
   */
  private setCheckoutSessionProductId(productId: string) {
    localStorage.setItem('checkout-session-in-app-purchase', productId);
  }

  /**
   * Clears the in app purchase checkout session.
   */
  private clearCheckoutSession() {
    localStorage.removeItem('checkout-session-in-app-purchase');
  }
}