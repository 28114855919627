import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { GridComponent } from './grid.component';
import { CardComponent } from './card/card.component';
import { IonicModule } from '@ionic/angular';
import { ImageCropPageModule } from '../editor-tools/image-crop/image-crop.module';
import { CardActivityCoverComponent } from './card/card-activity-cover/card-activity-cover.component';
import { ImgModule } from '../layout/img/img.module';
import { CardMorePopoverComponent } from './card/card-more-popover/card-more-popover.component';
import { BoardPickerComponent } from '../tools/board-picker/board-picker.component';
import { BoardSearchComponent } from '../tools/board-search/board-search.component';
import { GridSettingsComponent } from './grid-settings/grid-settings.component';

@NgModule({
 imports:      [ CommonModule, IonicModule, FormsModule, ImageCropPageModule, ImgModule ],
 declarations: [ GridComponent, CardComponent, CardActivityCoverComponent, CardMorePopoverComponent, BoardPickerComponent, BoardSearchComponent, GridSettingsComponent ],
 exports:      [ GridComponent, CardComponent, CardActivityCoverComponent, CardMorePopoverComponent, BoardPickerComponent, BoardSearchComponent ]
})
export class GridModule {}
