// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';  // TODO UPDATE ANGULAR
import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AlertController, LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { ImageCropV2Component } from 'src/app/editor-tools/image-crop-v2/image-crop-v2.component';
import { ImageSearchV2Component } from 'src/app/editor-tools/image-search-v2/image-search-v2.component';
import { ImageSourceMorePopoverComponent } from 'src/app/editor-tools/image-source-more-popover/image-source-more-popover.component';
import { ImageCropInfo } from 'src/app/editor-tools/models/image-crop-info.model';
import { Globals } from 'src/app/globals/globals';
import { Feedback } from 'src/app/home/feedback/feedback.model';
import { FeedbackService } from 'src/app/home/feedback/feedback.service';
import { LanguageService } from 'src/app/language/language.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';
import { UserBasicInfo } from '../user-basic-info.model';
import { UserService } from '../user.service';
import { UserHowDidYouHearComponent } from '../user-how-did-you-hear/user-how-did-you-hear.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {

  @Input() canShowMessages = false;

  theme = 'aac';
  userProfilePhotoSrc;

  hasEditedName = false;
  newUserName = '';
  hasEditedOccupation = false;
  newUserOccupation = '';
  newUserWhatsApp = '';
  newUserInstitution = '';

  onProfile = true;
  onMessages = false;

  userFeedbacksSub: Subscription;
  userFeedbacks: Feedback[] = [];
  feedbacksDate = [];

  audio: string;
  audioName: SafeUrl;
  audioPlayer = new Audio();
  isPlayingAudio = false;

  constructor(public app: Globals, private modalController: ModalController, public lang: LanguageService,
    private mediaStorageService: MediaStorageService, private popoverController: PopoverController,
    private userService: UserService, private alertController: AlertController, private analyticsService: AnalyticsService,
    public feedbackService: FeedbackService, private loadingController: LoadingController) { }

  ngOnInit() {
    this.analyticsService.logEvent('user_profile_page');
    if (window.location.pathname.includes('activities')) { this.theme = 'activities'; }
    this.userProfilePhotoSrc = this.mediaStorageService.resolveImageSrc(this.app.user.profile.photo);

    this.userFeedbacksSub = this.feedbackService.userFeedbacksObs.subscribe(feedbacks => {
      this.userFeedbacks = feedbacks;
      this.userFeedbacks.sort(this.compare);
      this.resolveFeedbacksDate();
    });

    this.audioPlayer.onended = () => {
      this.onAudioEnded();
    };
  }

  compare(a: any, b: any) {
    const creationA = a.createdAt;
    const creationB = b.createdAt;

    let comparison = 0;
    if (creationA < creationB) {
      comparison = 1;
    } else if (creationA > creationB) {
      comparison = -1;
    }
    return comparison;
  }

  onBackBtnClicked(ev: any) {
    this.modalController.dismiss();
  }

  async onDeleteProfileBtnClicked() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.header,
      subHeader: this.lang.words.alerts.deleteAccount.subHeader,
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        },
        {
          text: this.lang.words.common.yes,
          handler: async () => {
            this.onDeleteProfileFirstConfirmation()
          }
        }
      ]
    });
    await alert.present();
  }

  async onDeleteProfileFirstConfirmation() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.header,
      subHeader: this.lang.words.alerts.deleteAccount.subHeader,
      message: this.lang.words.alerts.deleteAccount.messageInfo,
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        },
        {
          text: this.lang.words.common.yes,
          handler: async () => {
            this.onDeleteProfileSecondConfirmation()
          }
        }
      ]
    });
    await alert.present();
  }

  async onDeleteProfileSecondConfirmation() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.header,
      subHeader: this.lang.words.alerts.deleteAccount.subHeader,
      message: this.lang.words.alerts.deleteAccount.messageConfirmation,
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        },
        {
          text: this.lang.words.common.yes,
          handler: (data) => {
            if (data.email_confirmation == this.app.user.email) {
              this.onDeleteProfile()
            } else {
              this.onWrongEmail()
            }
          }
        }
      ],
      inputs: [
        {
          name: 'email_confirmation',
          type: 'text',
          placeholder: "E-mail"
        }
      ]
    });
    await alert.present();
  }

  async onWrongEmail() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.wrongEmail,
      buttons: [
        {
          text: this.lang.words.common.ok,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            this.onDeleteProfileSecondConfirmation()
          }
        }
      ]
    });
    await alert.present();
  }

  async onDeleteProfile() {
    const loading = await this.loadingController.create({
      cssClass: 'app-standard-loading',
      message: this.lang.words.loadings.deleteAccount.message,
      spinner: 'dots',
      animated: false
    });

    await loading.present()

    try {
      this.userService.deleteAccount().then(async data => {
        this.loadingController.dismiss()
        this.accountDeletionSuccessAlert()
      })
    } catch (err) {
      this.accountDeletionErrorAlert()
    }
  }

  async accountDeletionSuccessAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.success,
      buttons: [
        {
          text: this.lang.words.common.ok,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            location.reload()
          }
        }
      ]
    });
    await alert.present();
  }

  async accountDeletionErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      header: this.lang.words.alerts.deleteAccount.error,
      buttons: [
        {
          text: this.lang.words.common.ok,
          role: 'cancel',
          cssClass: 'danger',
          handler: () => { }
        }
      ]
    });
    await alert.present();
  }

  onProfileBtnClicked() {
    this.onProfile = true;
    this.onMessages = false;
  }

  onMessagesBtnClicked() {
    this.onProfile = false;
    this.onMessages = true;
  }

  onSaveBtnClicked(ev: any) {
    this.modalController.dismiss();
    if (this.hasEditedName) this.updateUserName();
    if (this.hasEditedOccupation) this.updateUserOccupation();
  }

  async onProfilePhotoClicked(ev: any) {
    this.analyticsService.logEvent('user_profile_photo_click');

    const popover = await this.popoverController.create({
      component: ImageSourceMorePopoverComponent,
      event: ev,
      translucent: true,
      cssClass: 'app-popover-lg',
      componentProps: {
        canDeleteImg: this.app.user.profile.photo ? true : false,
        webSearchEnabled: false
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data && data.action === 'load-image-from-device') {
      this.openImageCrop(data);
    } else if (data && data.action === 'search-image') {
      this.openImageSearch();
    } else if (data && data.action === 'delete-image') {
      this.userProfilePhotoSrc = '';
      this.userService.deleteUserProfilePhoto(true);
    }
  }

  async openImageSearch() {
    const modal = await this.modalController.create({
      component: ImageSearchV2Component,
      backdropDismiss: false,
      showBackdrop: false
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.updateUserPhoto(data);
    }
  }

  async openImageCrop(ev: any) {
    const modal = await this.modalController.create({
      component: ImageCropV2Component,
      componentProps: {
        imgSrc: ev.data.imageSrc,
        imgType: ev.data.imageType
      },
      backdropDismiss: false,
      showBackdrop: false
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.updateUserPhoto(data);
    }
  }

  async updateUserPhoto(imageCropInfo: ImageCropInfo) {
    this.analyticsService.logEvent('user_profile_photo_change');

    // Deletes the old profile photo
    this.userService.deleteUserProfilePhoto();

    // Sets the new profile photo
    this.userProfilePhotoSrc = JSON.stringify(imageCropInfo);

    // Saves the new profile photo
    await this.mediaStorageService.saveImage(imageCropInfo)
      .then(saveImageName => {
        const profile = JSON.parse(JSON.stringify(Object.assign(this.app.user.profile, { photo: saveImageName })));
        this.userService.updateUserProps({ profile });

        const userInfo: UserBasicInfo = { uid: this.app.user.uid, name: this.app.user.name, photo: saveImageName };
        this.userService.updateSharedSpaceUserInfo(userInfo);
      })
      // TODO: Handle error
      .catch(err => console.log(err));
  }

  onEditName(ev: any) {
    this.newUserName = ev.target.value;
    this.hasEditedName = true;
    //console.log(this.newUserName);
  }

  /**
   * Updates the user name if it has changed
   */
  updateUserName() {
    this.analyticsService.logEvent('user_profile_name_change', { newName: this.newUserName, oldName: this.app.user.name });
    this.app.user.name = this.newUserName;
    this.userService.updateUserProps({ name: this.newUserName });

    const userInfo: UserBasicInfo = { uid: this.app.user.uid, name: this.app.user.name, photo: this.app.user.profile.photo };
    this.userService.updateSharedSpaceUserInfo(userInfo);
  }

  onEditOccupation(ev: any) {
    this.newUserOccupation = ev.target.value;
    this.hasEditedOccupation = true;
    //console.log(this.newUserOccupation);
  }

  updateUserOccupation() {
    this.analyticsService.logEvent('user_profile_occupation_change', {
      newOccupation: this.newUserOccupation,
      oldOccupation: this.app.user.profile.occupation || ''
    });
    this.app.user.profile.occupation = this.newUserOccupation;

    const profile = JSON.parse(JSON.stringify(Object.assign(this.app.user.profile, { occupation: this.newUserOccupation })));

    this.userService.updateUserProps({ profile });
  }

  updateUserInstitution() {
    this.analyticsService.logEvent('user_profile_institution_change', {
      newInstitution: this.newUserInstitution,
      oldInstitution: this.app.user.profile.institution || ''
    });
    this.app.user.profile.institution = this.newUserInstitution;

    const profile = JSON.parse(JSON.stringify(Object.assign(this.app.user.profile, { institution: this.newUserInstitution })));

    this.userService.updateUserProps({ profile });
  }

  updateUserWhatsApp() {
    this.analyticsService.logEvent('user_profile_whatsapp_change', {
      newWhatsApp: this.newUserWhatsApp,
      oldWhatsApp: this.app.user.profile.whatsapp || ''
    });
    this.app.user.profile.whatsapp = this.newUserWhatsApp;

    const profile = JSON.parse(JSON.stringify(Object.assign(this.app.user.profile, { whatsapp: this.newUserWhatsApp })));

    this.userService.updateUserProps({ profile });
  }

  onEditNameBtnClicked() {
    this.userNameAlert();
  }

  onEditOccupationBtnClicked() {
    this.userOccupationAlert();
  }

  onEditInstitutionBtnClicked() {
    this.userInstitutionAlert();
  }

  onEditWhatsAppBtnClicked() {
    this.userWhatsAppAlert();
  }

  onEditHowDidYouHearBtnClicked() {
    this.showHowDidYouHearModal();
  }

  /**
   * Opens a modal asking the user how did he/she know us.
   */
  async showHowDidYouHearModal() {
    const modal = await this.modalController.create({
      component: UserHowDidYouHearComponent,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
  }

  async userNameAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-category-alert',
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: this.lang.words.alerts.userName.placeHolder,
          value: this.app.user.name
        }
      ],
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.lang.words.common.ok,
          handler: (data) => {
            if (data.name !== '') {
              this.newUserName = data.name;
              this.updateUserName();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async userInstitutionAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-category-alert',
      inputs: [
        {
          name: 'institution',
          type: 'text',
          placeholder: this.lang.words.alerts.institution.placeHolder,
          value: this.app.user.profile.institution
        }
      ],
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.lang.words.common.ok,
          handler: (data) => {
            if (data.institution !== '') {
              this.newUserInstitution = data.institution;
              this.updateUserInstitution();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async userOccupationAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-category-alert',
      inputs: [
        {
          name: 'occupation',
          type: 'text',
          placeholder: this.lang.words.alerts.occupation.placeHolder,
          value: this.app.user.profile.occupation
        }
      ],
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.lang.words.common.ok,
          handler: (data) => {
            if (data.occupation !== '') {
              this.newUserOccupation = data.occupation;
              this.updateUserOccupation();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async userWhatsAppAlert() {
    const alert = await this.alertController.create({
      message: this.app.lang === 'pt-BR' ? 'Digite o DDD e o número do celular. Por exemplo:<br>31 9 9999-9999' : 'Phone',
      cssClass: 'app-user-whatsapp-alert',
      inputs: [
        {
          id: 'user-whatsapp-alert-input',
          name: 'whatsapp',
          type: 'text',
          placeholder: this.lang.words.alerts.whatsapp.placeHolder,
          value: this.sanitizeWhatsApp(this.app.user.profile.whatsapp),
        }
      ],
      buttons: [
        {
          text: this.lang.words.common.cancel,
          role: 'cancel'
        }, {
          id: 'user-whatsapp-alert-btn-ok',
          text: this.lang.words.common.ok,
          handler: (data) => {
            if (data.whatsapp !== '') {
              this.newUserWhatsApp = this.sanitizeWhatsApp(data.whatsapp);
              this.updateUserWhatsApp();
            }
          }
        }
      ]
    });
    await alert.present();

    // Enables phone validation only for pt-BR language
    if (this.app.lang === 'pt-BR') {
      document.getElementById('user-whatsapp-alert-input').addEventListener('input', this.onUserWhatsAppInput)
      this.validateWhatsApp(this.app.user.profile.whatsapp);
    }

    await alert.onWillDismiss();

    // Removes event listener from whatsapp input
    if (this.app.lang === 'pt-BR') {
      document.getElementById('user-whatsapp-alert-input').removeEventListener('input', this.onUserWhatsAppInput)
    }
  }

  onUserWhatsAppInput = (ev: any) => {
    this.validateWhatsApp(ev.target.value);
  }

  validateWhatsApp(whatsapp: string) {
    const phoneNumber = whatsapp ? whatsapp.replace(/[\s()]/g,"").replace(/[_-]/g, "") : '';
    if (!isNaN(Number(phoneNumber)) && (phoneNumber.length === 11)) {
      this.enableWhatsAppAlertOkBtn();
    } else {
      this.disableWhatsAppAlertBtn();
    }
  }

  enableWhatsAppAlertOkBtn() {
    const btnElm = document.getElementById('user-whatsapp-alert-btn-ok');
    btnElm.classList.remove('app-alert-btn-disabled');   
  }

  disableWhatsAppAlertBtn() {
    const btnElm = document.getElementById('user-whatsapp-alert-btn-ok');
    btnElm.classList.add('app-alert-btn-disabled');
  }

  sanitizeWhatsApp(whatsapp: string) {
    return whatsapp ? whatsapp.replace(/[\W_]+/g,"").replace(/[^\d.-]+/g, '') : '';
  }

  resolveFeedbacksDate() {
    for (let feedback of this.userFeedbacks) {
      let date = new Date(feedback.createdAt);

      this.feedbacksDate['feedback_' + feedback._id] = date.toLocaleDateString(this.app.lang) + ', ';
      if (this.app.lang === 'en-GB') {
        this.feedbacksDate['feedback_' + feedback._id] += date.toLocaleTimeString(this.app.lang, { hour12: true, hour: '2-digit', minute: '2-digit' });
      } else {
        this.feedbacksDate['feedback_' + feedback._id] += date.toLocaleTimeString(this.app.lang, { hour: '2-digit', minute: '2-digit' });
      }
    }
  }

  onPlayBtnClicked(src: SafeUrl) {
    this.audioName = src;
    this.audio = this.getAudioSrc(src);

    if (this.audioPlayer.src === this.audio + '' && this.isPlayingAudio) {
      this.stopAudio();
      return;
    }

    this.audioPlayer.src = this.audio + '';
    this.audioPlayer.load();

    this.playAudio();
  }

  getAudioSrc(src: SafeUrl): string {
    return this.mediaStorageService.resolveAudioSrc(src + '');
  }

  playAudio() {
    this.audioPlayer.play();
    this.isPlayingAudio = true;
  }

  stopAudio() {
    this.audioPlayer.pause();
    this.audioPlayer.currentTime = 0;
    this.isPlayingAudio = false;
  }

  onAudioEnded() {
    this.stopAudio();
  }

  onPlayBtnUp() {
    if (this.isPlayingAudio) {
      this.stopAudio();
    } else {
      this.playAudio();
    }
  }
}
