import { Injectable } from '@angular/core';
import { CloudStorageService } from '../cloud-storage/cloud-storage.service';
import { Platform } from '@ionic/angular';
import { Globals } from '../globals/globals';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ImageCropInfo } from '../editor-tools/models/image-crop-info.model';
import { NativeStorageService } from '../native-storage/native-storage.service';
import { StorageService } from '../storage/storage.service';
import * as uuid from 'uuid';
import { DomSanitizer } from '@angular/platform-browser';
import toWav from 'audiobuffer-to-wav';
import { IDatabaseMedia } from './database-media.model';
import { DatabaseService } from '../database/database.service';
import { HttpClient } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';

import lamejs from 'lamejs';

import * as sampleBoardsMediasJson from '../../assets/sample-boards-medias/sample-boards-medias-obj.json';

interface IDownloadMediasProgress {
  isDownloading: boolean, 
  processed: number, 
  total: number,
  percentage: number,
  errorCount: number
}

@Injectable({
  providedIn: 'root'
})
export class MediaStorageService {

  /**
   * Images and audios stored temporarily in the local database. Since they are local, the app can use them immediately
   * while they are being saved as file on the device or cloud in a background process.
   */
  private localDatabaseMedias: IDatabaseMedia[] = [];

  /**
   * Medias that were added from in-app search will be pushed to cloudDatabaseMedias.
   * These medias haven't been saved as file yet but their info are available on the cloud databse and therefore can be accessed.
   * Meanwhile, the sync process will take place on backend and it will be responsible to save the media as file afterwards.
   */
  private cloudDatabaseMedias: IDatabaseMedia[] = [];

  /**
   * Medias that are cached on the device and can be accessed right away
   */
  private cacheDatabaseMedias;

  // Queue with medias that is going to be saved on device's cache
  private downloadQueue = [];

  // Download queue length before processing it
  downloadQueueInitialLength = 0;

  // Progress of the download queue. Percentage (0 => not started yet; 100 => done (100%))
  downloadProgressSubject = new Subject<IDownloadMediasProgress>();
  mediasDownloadProgressSub: Subscription;

  // Object used to inform the sample boards medias that are stored in the app's assets.
  sampleMediasObj = {}

  downloadMediasProgress: IDownloadMediasProgress = { processed: 0, errorCount: 0, percentage: 0, isDownloading: false, total: 0 };

  downloadMediasAsyncTimeout;
  downloadMediasEnabled = false;

  constructor(public app: Globals, public platform: Platform, public cloudStorageService: CloudStorageService,
    public nativeStorageService: NativeStorageService, private storageService: StorageService, public sanitizer: DomSanitizer,
    private databaseService: DatabaseService, private http: HttpClient) {
    //console.log('Media Storage Started. Running at platforms: ', this.platform.platforms());

    // Setup up the local medias array from database
    this.initLocalDatabaseMedias();

    // Setup up the cache medias from database
    // this.initCacheDatabaseMedias();

    this.sampleMediasObj = (sampleBoardsMediasJson as any).default;
  }

  /**
   * Setup up the local medias array from database
   */
  private async initLocalDatabaseMedias() {
    try {
      /**
       * Gets the local medias from local database.
       */
      const localMedias = await this.storageService.getObject('local-database-medias');

      /**
       * Sets the local medias array to the value read from database if it exists.
       * Otherwise, starts from an empty array.
       */
      if (typeof localMedias !== 'undefined' && localMedias) {
        this.localDatabaseMedias = localMedias;
      } else {
        this.localDatabaseMedias = [];
      }
    } catch (err) {
      console.log(err);
      return;
    }

    // Tries to save all local medias as files
    this.saveMidiasAsFile();
  }

  /**
   * Setup up the cache medias array from database
   */
  private async initCacheDatabaseMedias(): Promise<void> {
    return new Promise((resolve, reject) => {
      /**
       * Gets the cache medias from cache database.
       */
      this.loadCacheDatabaseMedias().then(cacheMedias => {
        /**
         * Sets the local medias array to the value read from database if it exists.
         * Otherwise, starts from an empty object.
         */
        if (typeof cacheMedias !== 'undefined' && cacheMedias) {
          this.cacheDatabaseMedias = cacheMedias;
        } else {
          this.cacheDatabaseMedias = {};
        }
        resolve();
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    });
  }

  /**
   * Loads the cache database from device
   */
  private async loadCacheDatabaseMedias() {
    console.time('listNativeMedias');
    return new Promise((resolve, reject) => {
      // For now we only save the medias for offline access on native platforms
      if (this.app.isRunningOnNative()) {
        this.nativeStorageService.listNativeMedias().then(medias => {
          console.timeEnd('listNativeMedias');
          //console.log('listNativeMedias: (length):  ' + medias.length);
          //console.log(medias);

          const cacheDatabaseMedias = {};
          for (const media of medias) {
            cacheDatabaseMedias[media] = this.nativeStorageService.resolveMedia(media);
          }

          resolve(cacheDatabaseMedias);
        })
          .catch(err => {
            console.log(err);
            reject({});
          });
      } else {
        reject({});
      }
    });
  }

  /**
   * Save the boards' medias on device so them can be accessed without internet connection.
   * Only native platform supports it.
   * @param boards Boards to be synced
   */
  cacheMedias(boards = []): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const uid = this.app.user.uid;
        if (this.app.isRunningOnNative() && uid) {
          console.time('CACHE MEDIAS');

          // Init the cache medias if it wasn't initialize yet
          if (typeof this.cacheDatabaseMedias === 'undefined') {
            await this.initCacheDatabaseMedias();
          }
    
          const medias = this.getBoardsMedias(boards);
    
          for (const media of medias) {
            if (this.sampleMediasObj[media]) {
              // console.log('Media in assets');
            } else if (this.cacheDatabaseMedias[media]) {
              // console.log('Media já em cache');
            } else {
              if (!this.downloadQueue.includes(media)) {
                this.downloadQueue.push(media);
                this.downloadQueueInitialLength++;
                // console.log('Media added to sync queue: ', media);
              } else {
                // console.log('Media já na fila');
              }
            }
          }
  
          /* Watch changes on medias download progress */
          this.mediasDownloadProgressSub = this.downloadProgressSubject.subscribe(progress => {
            // console.log('mediasDownloadProgress => ', progress);
            if (progress.isDownloading === false) {
              this.mediasDownloadProgressSub.unsubscribe();
              resolve();
              console.timeEnd('CACHE MEDIAS');
            }
          });

          // this.processDownloadQueue();

          this.downloadMediasProgress.isDownloading = true;
          this.downloadMediasProgress.total = this.downloadQueue.length;
          this.downloadMediasProgress.processed = 0;
          this.downloadMediasProgress.errorCount = 0;
          this.downloadMediasProgress.percentage = 0;

          this.startDownloadMediasAsync(this.downloadQueue);
        } else {
          resolve();
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  startDownloadMediasAsync(medias = []) {
    this.downloadMediasEnabled = true;
    this.downloadMediasAsync(medias);
  }

  downloadMediasAsync(medias = []) {
    //console.log('medias na fila (downloadMediasAsync) => ', medias.length);
    for (const media of medias.splice(0, 10)) {
      let mediaUrl = this.resolveMedia(media);

      if (mediaUrl.includes('imageCroppedEvent')) {
        //console.log('Não conseguimos sincronizar IMAGE CROPPED EVENT. Vamos alterar para a url de acesso de armazenamento na núvem');
        mediaUrl = this.cloudStorageService.resolveImageSrc(media);
      }

      // let mediaUrlFixed = `https://us-central1-cors-fix.cloudfunctions.net/fixImageCors?url=${mediaUrl}`;

      const mediaMime = this.getMimeType(media);
      if (mediaMime.includes('audio')) {
        this.fetchAndSaveAudio(mediaUrl, media);
      } else {
        this.loadAndSaveImage(mediaUrl, media);
      }
    }

    if (medias.length && this.downloadMediasEnabled) {
      this.downloadMediasAsyncTimeout = setTimeout(() => {
        this.downloadMediasAsync(medias);
      }, 1200);
    } else {
      this.stopDownloadMediasAsync();
    }
  }

  stopDownloadMediasAsync() {
    clearTimeout(this.downloadMediasAsyncTimeout);

    this.downloadMediasEnabled = false;
    this.downloadMediasProgress.isDownloading = false;
    this.downloadMediasProgress.percentage = 100;
    this.downloadProgressSubject.next(this.downloadMediasProgress);
  }

  async loadAndSaveImage(url: string, media: string, fixCors = true) {
    //console.log('creating new image');
    var img = new Image();

    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          // console.log(blob);
          //console.log(this.downloadMediasProgress);
          this.nativeStorageService.saveFile('medias', media, blob)
            .then(fileUrl => { 
              this.cacheDatabaseMedias[media] = fileUrl;
            })
            .catch(err => console.log(err))
            .finally(() => { 
              this.downloadMediasProgress.processed++; 
            });
        }, this.getMimeType(url));
    };

    img.onerror = () => {
      if (fixCors) {
        const urlCorsFix = `https://us-central1-cors-fix.cloudfunctions.net/fixImageCors?url=${this.resolveMedia(media)}`;
        this.loadAndSaveImage(urlCorsFix, media, false);
      } else {
        this.downloadMediasProgress.processed++;
        this.downloadMediasProgress.errorCount++;
      }
    }

    img.src = url;
  }

  fetchAndSaveAudio(mediaUrl: string, media: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get(mediaUrl, { responseType: 'arraybuffer' }).subscribe(data => {

        const blob = new Blob([data], { type: this.getMimeType(mediaUrl) });

        this.nativeStorageService.saveFile('medias', media, blob)
        .then(fileUrl => { 
          this.cacheDatabaseMedias[media] = fileUrl;
        })
        .catch(err => console.log(err))
        .finally(() => { 
          this.downloadMediasProgress.processed++; 
        });
      },
        err => {
          this.downloadMediasProgress.processed++;
          this.downloadMediasProgress.errorCount++;
          reject(err);
        });
    });
  }

  /**
   * Gets the media's data URL (base64)
   * @param media Media name
   * @returns Media's data URL
   */
  private async getMediaDataUrl(media: string) {
    let canFallBackToCorsFix = true;
    return new Promise((resolve, reject) => {
      this.fetchMedia(this.resolveMedia(media))
        .then(dataUrl => resolve(dataUrl))
        .catch(err => {
          if (canFallBackToCorsFix) {
            // We may face an error regarding cors. Before giving up, let's try fetch the image using the proxy cors-fix.
            this.fetchMedia(`https://us-central1-cors-fix.cloudfunctions.net/fixImageCors?url=${this.resolveMedia(media)}`)
              .then(dataUrl => resolve(dataUrl))
              .catch(error => reject(error));

            // We only try to fallback to cors-fix once
            canFallBackToCorsFix = false;
          } else {
            reject(err);
          }
        });
    });
  }

  /**
   * Fetches the media as dataURL
   * @param mediaUrl Media's URL
   * @returns Media's data URL
   */
  private fetchMedia(mediaUrl: string) {
    return new Promise((resolve, reject) => {
      this.http.get(mediaUrl, { responseType: 'arraybuffer' }).subscribe(data => {

        const blob = new Blob([data], { type: this.getMimeType(mediaUrl) });

        const reader = this.getFileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = ev => {
          reject(ev);
        };

        reader.readAsDataURL(blob);
      },
        err => {
          reject(err);
        });
    });
  }

  /**
   * Gets the file reader. Since the javascript FileReader API doesn't work right away on native platforms, we have to
   * make sure we are getting the right instance of file reader.
   * @returns File reader
   */
  private getFileReader(): FileReader {
    const fileReader = new FileReader();
    if (this.app.isRunningOnNative()) {
      const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
      return zoneOriginalInstance || fileReader;
    } else {
      return fileReader;
    }
  }

  /**
   * Finds out how to access the media
   * @param media Media name
   * @returns Media's url, dataUrl or ImageCropInfo.
   */
  resolveMedia(media: string) {
    const mimeType = this.getMimeType(media);
    if (mimeType.includes('image')) {
      return this.resolveImageSrc(media);
    } else if (mimeType.includes('audio')) {
      return this.resolveAudioSrc(media);
    } else {
      return '';
    }
  }

  /**
   * Gets the media's mime type
   * @param media Media name
   * @returns Mime type
   */
  getMimeType(media: string) {
    const mediaExt = media.split('.').slice(-1)[0];
    let mimeType = '';
    switch (mediaExt) {
      case 'jpg': mimeType = 'image/jpeg'; break;
      case 'png': mimeType = 'image/png'; break;
      case 'mp3': mimeType = 'audio/mpeg'; break;
      case 'ogg': mimeType = 'audio/ogg'; break;
      default: console.log('Critical: Mime Type not found for media ' + media); break;
    }
    return mimeType;
  }

  /**
   * Gets boards' medias (img, audio, questionImg and questionImgSrc).
   * @param boards Boards
   */
  private getBoardsMedias(boards = []) {
    const medias = [];
    for (const board of boards) {
      if (board.img) {
        medias.push(board.img);
      }
      if (board.audio) {
        medias.push(board.audio);
      }
      if (board.questionImgSrc) {
        medias.push(board.questionImgSrc);
      }
      if (board.questionAudio) {
        medias.push(board.questionAudio);
      }
    }
    return medias;
  }

  saveImage(imgCropInfo: ImageCropInfo = null, imageName = uuid.v4()): Promise<any> {
    return new Promise(async (resolve, reject) => {
      /**
       * Creates a local image structure that will be stored in the local database
       */
      //console.log('Save Image => image crop info:');
      //console.log(imgCropInfo);

      if (imgCropInfo.imageType === 'image/png') {
        imageName += '.png';
      } else {
        imageName += '.jpg';
      }

      const media: IDatabaseMedia = {
        name: imageName,
        type: 'image',
        imageCropInfo: imgCropInfo
      };

      // If the image's source is from a web link, we add the image to the cloud database medias to
      // quickly access it later on.
      if (imgCropInfo.imageUrl.startsWith('http')) {
        delete media.imageCropInfo.imageCroppedEvent.base64;
        this.cloudDatabaseMedias.push(media);
        this.databaseService.createCloudDatabaseMedia(media);
      } else {
        // Adds the image to local medias array to quickly access it later on
        this.localDatabaseMedias.push(media);

        /**
         * Saves the new local medias array in the local database
         */
        try {
          await this.storageService.setObject('local-database-medias', this.localDatabaseMedias);
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }

        //console.log('localDatabaseMedias:');
        //console.log(this.localDatabaseMedias);
      }

      // The image data was saved in the local database and we resolve this promise with the image name
      resolve(imageName);
    });
  }

  /**
   * Saves the local medias as files
   */
  private async saveMidiasAsFile() {
    //console.log('Refresh saveMidiasAsFile');
    if (this.localDatabaseMedias.length > 0) {
      for (const localMedia of this.localDatabaseMedias) {
        try {
          await this.saveMediaAsFile(localMedia.name);
        } catch (err) {
          console.error(err);
        }
      }
    }
    /**
     * Refreshes if there are some midias to save as file again in 5s
     */
    setTimeout(() => this.saveMidiasAsFile(), 5000);
  }

  /**
   * Stores a media as file on the device or cloud
   */
  private async saveMediaAsFile(mediaName: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      /**
       * Gets the index of the media in the local database medias array
       */
      const mediaIndex = this.localDatabaseMedias.findIndex(media => media.name === mediaName);
      if (mediaIndex >= 0) {
        // Name and dataURL of the media
        const localMedia = this.localDatabaseMedias[mediaIndex];
        //console.log(localMedia);

        /**
         * Uploads the media to the cloud
         */
        try {
          /**
           * When the media is image
           */
          if (localMedia.type === 'image') {
            await this.cloudStorageService.saveImage(localMedia.name, localMedia.imageCropInfo);
          }

          /**
           * When the media is audio
           */
          if (localMedia.type === 'audio') {
            await this.cloudStorageService.saveAudioDataUrl(localMedia.name, localMedia.dataURL);
          }
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }

        /**
         * Since the media has been saved as file, we remove it from the local media array and update the database
         * with this new array
         */
        this.localDatabaseMedias.splice(mediaIndex, 1);

        try {
          await this.storageService.setObject('local-database-medias', this.localDatabaseMedias);
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }

        //console.log('localDatabaseMedias:');
        //console.log(this.localDatabaseMedias);

        // All done! The media is saved as file and the local database medias is updated
        resolve(true);
      } else {
        reject(false);
      }
    });
  }

  deleteImage(imageName: string): Promise<any> {
    // if (this.app.isRunningOnNative()) {
    //   return this.nativeStorageService.deleteImage(imageName);
    // } else {
    //   return this.cloudStorageService.deleteImage(imageName);
    // }

    return this.cloudStorageService.deleteImage(imageName);
  }

  saveAudio(audioBlob: Blob): Promise<any> {
    return new Promise(async (resolve, reject) => {
      /**
       * if the audio is not in mp3 format, we convert it to mp3 before saving it.
       */
      if (audioBlob.type !== 'audio/mpeg') {
        try {
          if (audioBlob.type === 'audio/wav') {
            audioBlob = await this.wavBlobToMp3(audioBlob);
          } else {
            const wav = await this.decodeToWav(audioBlob);
            audioBlob = await this.wavToMp3(wav.wavBuffer, wav.audioBuffer);
          }
        } catch (err) {
          reject(err);
          return;
        }
      }

      /**
       * Reads the audio blob as dataURL to get its data as base 64
       */
      let reader = new FileReader();
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async () => {
        // Gets the audio dataURL (Base 64)
        const base64data = reader.result.toString();

        /**
         * Creates a local audio structure that will be stored in the local database
         */
        const audioName = uuid.v4() + '.mp3';
        const localMedia: IDatabaseMedia = {
          name: audioName,
          type: 'audio',
          dataURL: base64data
        };

        // Adds the audio to local medias array to quickly access it later on
        this.localDatabaseMedias.push(localMedia);

        /**
         * Saves the new local medias array in the local database
         */
        try {
          await this.storageService.setObject('local-database-medias', this.localDatabaseMedias);
        } catch (err) {
          console.log(err);
          reject(err);
          return;
        }

        //console.log('localDatabaseMedias:');
        //console.log(this.localDatabaseMedias);
        //console.log(audioName);

        // The audio data was saved in the local database and we resolve this promise with the audio name
        resolve(audioName);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  /**
   * Decodes an audio blob to wav
   */
  private decodeToWav(audioBlob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
      reader.readAsArrayBuffer(audioBlob);

      reader.onloadend = () => {
        const audioContext = new AudioContext();

        audioContext.decodeAudioData(reader.result as ArrayBuffer, (buffer) => {
          const wavBuf = toWav(buffer);
          resolve({ wavBuffer: wavBuf, audioBuffer: buffer });
        }, (err) => {
          console.log(err);
          reject(err);
        });
      };
      reader.onerror = (err) => {
        console.log(err);
        reject(err);
      };
    });
  }

  /**
   * Converts Wav buffer to a MP3 Blob
   */
  private async wavToMp3(wavBuffer: ArrayBuffer, audioBuffer: AudioBuffer): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const audioDuration = audioBuffer.duration;
      //console.log('Audio duration: ' + audioDuration + ' seconds');

      const mp3Data = [];
      let mp3encoder;
      const rawAudio = wavBuffer;

      // Obtém apenas as amostras do audio, eliminando o header. Isso evita que o header seja
      // codificado e produza um som de clique logo no início do áudio
      const samples = rawAudio.slice(44);

      // Configurações: mono, sampleRate, 192kbps
      mp3encoder = new lamejs.Mp3Encoder(audioBuffer.numberOfChannels, audioBuffer.sampleRate, 192);

      // Codifica as amostras de áudio para mp3
      let mp3Tmp = mp3encoder.encodeBuffer(new Int16Array(samples));

      // Adiciona os dados do audio codificado em mp3
      mp3Data.push(mp3Tmp);

      // Obtém a parte final do mp3
      mp3Tmp = mp3encoder.flush();

      // Adiciona o último dado codificado em mp3. Agora a variável mp3Data contem todos os dados do áudio.
      mp3Data.push(mp3Tmp);

      const blob: Blob = new Blob(mp3Data, { type: 'audio/mpeg' });

      resolve(blob);
    });
  }

  /**
   * Converts Wav blob to a MP3 Blob
   */
  private async wavBlobToMp3(audioBlob: Blob): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
      reader.readAsDataURL(audioBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        const audio = new Audio();
        audio.src = base64data + '';
        audio.addEventListener('loadedmetadata', async () => {
          const audioDuration = audio.duration;
          //console.log('Audio duration: ' + audioDuration + ' seconds');

          const mp3Data = [];
          let mp3encoder;
          const rawAudio = await (new Response(audioBlob)).arrayBuffer();

          // Obtém apenas as amostras do audio, eliminando o header. Isso evita que o header seja
          // codificado e produza um som de clique logo no início do áudio
          const samples = rawAudio.slice(44);
          const sampleRate = Math.round(parseFloat((samples.byteLength / audioDuration / 2).toString()));
          //console.log('Audio Sample Rate: ' + sampleRate);

          mp3encoder = new lamejs.Mp3Encoder(1, sampleRate, 128); // Configurações: mono [sampleRate] encode to 128kbps

          // Codifica as amostras de áudio para mp3
          let mp3Tmp = mp3encoder.encodeBuffer(new Int16Array(samples));

          // Adiciona os dados do audio codificado em mp3
          mp3Data.push(mp3Tmp);

          // Obtém a parte final do mp3
          mp3Tmp = mp3encoder.flush();

          // Adiciona o último dado codificado em mp3. Agora a variável mp3Data contem todos os dados do áudio.
          mp3Data.push(mp3Tmp);

          const blob: Blob = new Blob(mp3Data, { type: 'audio/mpeg' });

          resolve(blob);

        }, false);
      };
    });
  }

  deleteAudio(audioName: string): Promise<any> {
    // if (this.app.isRunningOnNative()) {
    //   return this.nativeStorageService.deleteAudio(audioName);
    // } else {
    // }

    return this.cloudStorageService.deleteAudio(audioName);
  }

  /**
   * Resolves the image source. The source of the image can come from local database, cloud database, device or cloud.
   * @param imageName Image name to be resolved
   */
  resolveImageSrc(imageName: string): string {
    /**
     * Checks if the media is in cache. If not, check the local database (medias that haven't been saved as file yet). If so, check if the
     * audio we want to resolve is in it. If this is the case, return its data url.
     * Otherwise, the audio is resolved accordingly to its URL.
     */

    if (this.sampleMediasObj[imageName]) {
      return Capacitor.convertFileSrc(`../../../assets/sample-boards-medias/${imageName}`);
    } else if (this.cacheDatabaseMedias && this.cacheDatabaseMedias[imageName]) {
      //console.log(`Media ${imageName} retornada do cache`);
      return this.cacheDatabaseMedias[imageName];
    } else if (this.localDatabaseMedias.length > 0 && this.localDatabaseMedias.findIndex(media => media.name === imageName) >= 0) {
      const mediaIndex = this.localDatabaseMedias.findIndex(media => media.name === imageName);
      return JSON.stringify(this.localDatabaseMedias[mediaIndex].imageCropInfo);
    } else if (this.cloudDatabaseMedias.length > 0 && this.cloudDatabaseMedias.findIndex(media => media.name === imageName) >= 0) {
      const mediaIndex = this.cloudDatabaseMedias.findIndex(media => media.name === imageName);
      return JSON.stringify(this.cloudDatabaseMedias[mediaIndex].imageCropInfo);
    } else {
      return this.cloudStorageService.resolveImageSrc(imageName);
    }
  }

  /**
   * Resolves the audio source. The source of the audio can come from local database, device or cloud.
   * @param audioName Audio name to be resolved
   */
  resolveAudioSrc(audioName: string): string {
    /**
     * Checks if the media is i cache. If not, check the local database (medias that haven't been saved as file yet). If so, check if the
     * audio we want to resolve is in it. If this is the case, return its data url.
     * Otherwise, the audio is resolved accordingly to its URL.
     */
    if (this.sampleMediasObj[audioName]) {
      return Capacitor.convertFileSrc(`../../../assets/sample-boards-medias/${audioName}`);
    } else if (this.cacheDatabaseMedias && this.cacheDatabaseMedias[audioName]) {
      //console.log(`Media ${audioName} retornada do cache`);
      return this.cacheDatabaseMedias[audioName];
    } else if (this.localDatabaseMedias.length > 0 && this.localDatabaseMedias.findIndex(media => media.name === audioName) >= 0) {
      const mediaIndex = this.localDatabaseMedias.findIndex(media => media.name === audioName);
      return this.localDatabaseMedias[mediaIndex].dataURL;
    } else {
      return this.cloudStorageService.resolveAudioSrc(audioName);
    }
  }

  /**
   * Resolves the media source from cloud database.
   * @param mediaName Media name to be resolved
   */
  resolveCloudDatabaseMedia(mediaName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.databaseService.getCloudDatabaseMedia(mediaName).then(media => {
        /**
         * Only consider the media if its upload has been failed. The medias in cloud-database-medias collection is expected to
         * stay there just during its upload, which shouldn't take that more than a few seconds. If the upload fails, then we must
         * consider the media from cloud-database-medias to be able to render it.
         */
        if (media && media.status === 'upload-failed') {
          this.cloudDatabaseMedias.push(media);
          resolve(media);
        } else {
          resolve(undefined);
        }
      }).catch(err => reject(err));
    });
  }

  private dataURLtoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });

    const audioURL = URL.createObjectURL(blob);
    const audioURLBypassed = this.sanitizer.bypassSecurityTrustUrl(audioURL);

    const audio = new Audio();
    audio.src = dataURI;
    audio.load();
    audio.play();

    return audioURL;
  }
}
