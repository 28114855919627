export class CardSelectEvent {
    cardIndex: number;
    cardSelected: any;
    cardElementRef: any;
    triggerEv: any;
};

export class CardDragEvent {
    floatingCard: any;
    targetCard: any;
    cardElementRef: any;
}

export class CardDropEvent {
    floatingCard: any;
    targetCard: any;
    cardElementRef: any;
}

export class CardReleaseEvent {
    floatingCard: any;
    targetCard: any;
    cardElementRef: any;
}