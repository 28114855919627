export interface TooltipProperties {
    event?: MouseEvent;
    targetElement?: HTMLElement;
    element?: HTMLElement;
    tooltipText?: string;
    component?: any;
    width?: string;
    height?: string;
    maxWidth?: string;
    minWidth?: string;
    maxHeight?: string;
    left?: number;
    top?: number;
    right?: string;
    bottom?: string;
    zIndex?: number;
    placement?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 
    'left' | 'left-top' | 'left-bottom' | 'right' | 'right-top' | 'right-bottom';
    autoPlacement?: boolean;
    alignToCenter?: boolean;
    overlayBackdrop?: boolean;
    offset?: number;
    theme?: 'dark' | 'light' | 'red' | 'blue' | 'green' | 'yellow';
    animationDuration?: number;
    animationTimingFunction?: string;
    animationTranslateY?: string;
    animation?: 'fade' | 'upwards';
    tooltipClass?: string | string[];
    padding?: string;
    noArrow?: boolean;
    metadata?: any;
    hideDelay?: number;
    showDelay?: number;
    trigger?: 'hover' | 'click';
    position?: {top: number, left: number};
    whiteSpace?: 'nowrap' | 'normal';
    borderRadius?: string;
    shadow?: boolean;
    pointerEvents?: "auto" | "none";
    fontSize?: string;
    display?: boolean;
}