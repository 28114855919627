import { Injectable } from '@angular/core';
import { User } from '../user/user.model';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { StorageService } from '../storage/storage.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Plan } from '../plan/plan.model';
import { UserLocalParameters } from '../user/user-local-parameters.model';
import { LanguageService } from '../language/language.service';
import { environment } from './../../environments/environment';
import packageInfo from 'package.json';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable()
export class Globals {
  appVersion = packageInfo.version;
  cloudStorageEndpoint = 'https://api-simplix-life.cloudno.de/v1'; // 'http://localhost:3020/v1';
  idiom = 'pt-br';
  searchEndpoint = 'https://simplix-life-search.cloudno.de/v1'; // 'https://simplix-life.cloudno.de'; // 'http://localhost:3030/v1'
  apiEndpoint = environment.firebaseFunctionsDomain;
  storageLocation = environment.storageUrl.default;
  aacBoardsRootLocation = '';
  user: User = new User();
  plan: Plan = new Plan();
  private countryFromIP = '';

  originUrl = window.location.origin;
  imageStorageLocation = this.storageLocation + '/medias/images/optimized/';
  audioStorageLocation = this.storageLocation + '/medias/audios/';

  lang = 'pt-BR';
  langSuffix = '';
  currencySymbol = '';
  aacSubscriptionValue = ''
  activitiesSubscriptionValue = ''

  activitiesPlayCount = 0;

  isRunningOnIos: boolean;

  constructor(public platform: Platform, public storageService: StorageService, public firestore: AngularFirestore,
    private langService: LanguageService, private http: HttpClient, private fns: AngularFireFunctions) {
    // console.log('Plataform: ' + this.platform.platforms);

    // Para teste de functions simuladas localmente
    // if (!environment.production) {
    //   this.fns.useFunctionsEmulator('http://localhost:5001');
    // }

    // Inicia o número de atividades que já foram executadas (útil para limitar o uso gratuito do app se o usuário não criar conta)
    this.storageService.get('activities-play-count')
      .then((value) => {
        if (value) {
          this.activitiesPlayCount = value;
        } else {
          this.activitiesPlayCount = 0;
        }
        //console.log('activitiesPlayCount: ' + this.activitiesPlayCount);
      });

    /**
     * Watch for any changes on app language
     */
    this.langService.langObs.subscribe((lang) => {
      this.lang = lang;

      // We have an special case when the lang is pt-BR, where the aac-boards and activity-boards doesn't have lang suffix.
      // For the other languages, we append the suffix -lang in lower case.
      this.langSuffix = lang === 'pt-BR' ? '' : `-${lang.toLocaleLowerCase()}`;
    });

    // Seleciona servidor mais apropriado
    // TODO

    // Set the correspoding platform flags that indicates which platform the app is being used.
    // It's better to read it from a simple variable than a function to improve performance, since functions
    // are called on every change in the view
    this.isRunningOnIos = this.platform.is('ios') ? true : false;

    this.originUrl = this.isRunningOnNative() ? 'https://web.expressia.life' : window.location.origin;
  }

  getUserRootAac() {
    return this.user.rootAac.find(o => o.idiom === 'pt-br');
  }

  isUserLogged() {
    return this.user.uid ? true : false;
  }

  isUserOnTrial() {
    var d = new Date();
    return (this.user.subscription.expiryTimeMillis > d.getTime() && (this.user.subscription.isActive === false))
  }

  daysLeftOnTrial() {
    var d = new Date();
    const days = (this.user.subscription.expiryTimeMillis - d.getTime()) / (24 * 60 * 60000);
    //console.log('DAYS: ', days, this.user.subscription.expiryTimeMillis);
    return days;
  }

  timeNow() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /**
   * Finds out if the app is running on browser (desktop browser or mobile browser)
   */
  isRunningOnBrowser() {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }
  }

  isRunningOnMobile() {
    if (this.platform.is('mobile') && (this.platform.is('android') || this.platform.is('ios'))) {
      return true;
    } else {
      return false;
    }
  }

  isRunningOnNative() {
    if (this.platform.is('capacitor')) {
      return true;
    } else {
      return false;
    }
  }

  isRunningOnAndroidApp() {
    if (this.platform.is('android') && this.platform.is('capacitor')) {
      return true;
    } else {
      return false;
    }
  }

  getPlatform() {
    if (this.isRunningOnAndroidApp()) {
      return 'android';
    } else if (this.platform.is('desktop')) {
      return 'desktop';
    } else if (this.platform.is('mobileweb') && this.platform.is('android')) {
      return 'mobileweb-android';
    } else if (this.platform.is('mobileweb') && this.platform.is('ios')) {
      return 'mobileweb-ios';
    } else {
      return this.platform.platforms();
    }
  }

  addActivitiesPlayCount() {
    this.activitiesPlayCount++;
    this.storageService.set('activities-play-count', this.activitiesPlayCount);
  }

  // Returns true if the current subscription is not active or scheduled to be canceled
  canCreateNewSubscription() {
    // Already have active subscription
    if (this.user.subscription.isActive &&
      (this.user.subscription.cancelWhenExpire == undefined ||
        this.user.subscription.cancelWhenExpire == false)) {
      return false;
    }
    // Current subscription is not active
    return true;
  }

  identifyAppType() {
    if (this.isRunningOnBrowser()) {
      return 'web';
    } else if (this.isRunningOnAndroidApp) {
      return 'android';
    } else if (this.isRunningOnIos) {
      return 'ios';
    }
  }

  // Consulta arquvo com redirecionamento i18n para determinar local com base em IP
  // TODO: Criar versão da chamada de localização baseada apensa no i18n para usuários não logados
  getLocalParameters() {

    // Default URL to JSON file hosted behind i18n redirect
    var localizedJsonUrl = environment.localizedParametersUrl;
    // console.log('getLocalParameters: ', this.isUserLogged());
    if (this.isUserLogged() || this.isRunningOnNative()) {
      //console.log('getLocalParameters: user logged')
      // Pergunta ao worker "location" do cloudflare qual é a localização
      this.http.get('https://expressia.life/geolocation').toPromise().then(data => {

        // Se houver resposta da API de localização
        if (data.hasOwnProperty('country_code')) {

          const code = data['country_code'].toLowerCase();
          this.countryFromIP = code;
          //console.log('location: ' + code);
          // URL for the local file
          localizedJsonUrl = 'assets/i18n/ALL_' + code + '/assets/local-parameters.json';
        }
        this.parseLocalizedJson(localizedJsonUrl);
      }).catch((err) => this.parseLocalizedJson(localizedJsonUrl)); // Em caso de falha utiliza i18n da hospedagem
    } else {
      this.parseLocalizedJson(localizedJsonUrl);
    }
  }

  parseLocalizedJson(localizedJsonUrl: string) {
    //this.updateLocalVariables (); // Essa chamada garante que não vai mostrar o preço default antes de buscar o salvo
    this.http.get(localizedJsonUrl).toPromise().then(data => {

      //console.log(data);
      var dataObj = new UserLocalParameters();
      dataObj = JSON.parse(JSON.stringify(data));

      // Atualiza o campo de assinatura do usuário no banco de dados
      if (typeof dataObj.country !== 'undefined') {

        this.user.local = JSON.parse(JSON.stringify(data));
        if (this.countryFromIP !== '') { // Sobrescreve o país do json com o país da consulta por IP
          this.user.local.countryCode = this.countryFromIP;
        }

        // Salva no firestore se estiver logado
        if (this.isUserLogged()) {
          const updateData = {
            local: this.user.local
          };
          this.firestore.collection('users').doc(this.user.uid).update(updateData);
        }
      }
      this.updateLocalVariables();
      // console.log('Location: ', this.user.local.countryCode, '| Server: ', this.user.local.storageServerRegion);
    });
  }

  // Constroi string de preço e simbolo da moeda
  updateLocalVariables() {
    // Gambis para pegar o símbolo da moeda formatado
    var value = 0;
    this.currencySymbol = value.toLocaleString(this.lang, { style: 'currency', currency: this.user.local.currency }); // get currency formatted according to locale
    this.currencySymbol = this.currencySymbol.replace(/[0-9,.]/g, ''); // Remove o valor e fica só com o símbolo da moeda
    this.aacSubscriptionValue = Number(this.user.local.stripeAacPriceValue).toLocaleString(this.lang);
    this.activitiesSubscriptionValue = Number(this.user.local.stripeActivitiesPriceValue).toLocaleString(this.lang);

    switch (this.user.local.storageServerRegion) {
      case 'saopaulo':
        this.storageLocation = environment.storageUrl.saopaulo;
        break;
      case 'london':
        this.storageLocation = environment.storageUrl.london;
        break;
      case 'ohio':
        this.storageLocation = environment.storageUrl.ohio;
        break;
      default:
        this.storageLocation = environment.storageUrl.saopaulo;
    }

    this.imageStorageLocation = this.storageLocation + '/medias/images/optimized/';
    this.audioStorageLocation = this.storageLocation + '/medias/audios/';
  }

  firebaseArrayUnion(value: any) {
    return firebase.firestore.FieldValue.arrayUnion(value);
  }

  firebaseArrayUnion2(value: any) {
    return firebase.firestore.FieldValue.arrayUnion(...value);
  }

  firebaseArrayRemove(value: any) {
    return firebase.firestore.FieldValue.arrayRemove(value);
  }

  firebaseCreateId() {
    return this.firestore.createId();
  }

  firebaseIncrement(n: number) {
    return firebase.firestore.FieldValue.increment(n);
  }

  /**
   * Checks if the app is connected to the internet
   * @returns Resolves to true if the app is conneted
   */
  isOnline() {
    return this.fns.httpsCallable('isOnline')({}).toPromise();
  }

  /**
   * Get the user uid (if logged) or gestId (if not logged)
   */
  getUserIdentity() {
    return new Promise(async (resolve, reject) => {
      if (this.user.uid) {
        resolve({ uid: this.user.uid });
      } else {
        const user = await this.storageService.getObject('user');
        if (user && user.uid) {
          resolve({ uid: user.uid });
        } else {
          const guestId = localStorage.getItem('guest-id');
          if (guestId) {
            resolve({ guestId: guestId });
          } else {
            resolve({});
          }
        }
      }
    });
  }

  /**
   * Checks if the beta testing is available.
   *
   * @returns return true if the app is on beta testing
   */
  isBetaTesting() {
    // The beta testing will allow full access to the app until December 31, 2022.
    const betaEndDate = new Date('2022-12-31T23:59:00.000Z');

    // Checks if we are on beta environment and if the test period still available
    if ((environment.environmentName === 'beta') && (Date.now() <= betaEndDate.getTime())) {
      return true;
    } else {
      return false;
    }
  }
}
