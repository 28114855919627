import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesService } from 'src/app/activities/activities.service';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';

@Component({
  selector: 'app-card-activity-cover',
  templateUrl: './card-activity-cover.component.html',
  styleUrls: ['./card-activity-cover.component.scss'],
})
export class CardActivityCoverComponent implements OnInit {

  @Input() width = 0;
  @Input() height = 0;
  @Input() cards = [];

  coverImgsSrc = [];

  constructor(private activitiesService: ActivitiesService, private mediaStorageService: MediaStorageService ) { }

  ngOnInit() {
    setTimeout(() => {
      this.coverImgsSrc = this.activitiesService.getCardsByIds(this.cards.slice(0, 4))
                            .map(card => 
                              {
                                const mediaSrc = this.mediaStorageService.resolveImageSrc(card.img);
                                return typeof mediaSrc === 'string' ? mediaSrc.replace('/images/optimized/', '/images/small/'): mediaSrc;
                              }
                          );
    });
  }
}
