import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-how-did-you-hear',
  templateUrl: './user-how-did-you-hear.component.html',
  styleUrls: ['./user-how-did-you-hear.component.scss'],
})
export class UserHowDidYouHearComponent  implements OnInit {

  @ViewChild('otherRadio') otherRadio: any;
  @ViewChild('radioGroup') radioGroup: any;

  @Input() isPresentedJustAfterSignUp = false;

  otherInputValue = '';

  constructor(public lang: LanguageService, private modalController: ModalController, public app: Globals,
    private analyticsService: AnalyticsService, public userService: UserService) { }

  ngOnInit() {
    this.analyticsService.logEvent(`user_how_did_you_know_page`);

    // let totalViews = parseInt(localStorage.getItem('occupation-popup-views'), 10);
    // if (totalViews) {
    //   totalViews++;
    //   localStorage.setItem('occupation-popup-views', String(totalViews));
    // } else {
    //   localStorage.setItem('occupation-popup-views', '1');
    // }
  }

  onBackBtnClicked() {
    this.modalController.dismiss();
  }

  onOtherItemClicked() {
    this.otherRadio.el.click();
  }

  onSubmitBtnClicked(ev: any) {
    const selectedOption = this.radioGroup.value

    if (selectedOption && selectedOption !== 'other') {
      this.submit(selectedOption, this.lang.words.userHowDidYouHear[selectedOption]);  
    } else if (selectedOption === 'other' && this.otherInputValue) {
      this.submit(selectedOption, this.otherInputValue);  
    }
  }

  submit(selectedOption: string, optionValue: string) {
    const params = {};
    params[selectedOption] = 1;

    this.analyticsService.logEvent(`user_how_did_you_know_select`, params, selectedOption);

    const profile = JSON.parse(JSON.stringify(Object.assign(this.app.user.profile, { howDidYouHear: optionValue })));
    this.userService.updateUserProps({ profile });
    this.modalController.dismiss();
  }
}
