export class UserPreferences {
    useWithTix = false;
    useGridPaginated = false;
    useScanMode = false;
    vocalizeCardsOnScan = false;
    scanInterval = 1500;
    activitiesEnabled = true;
    aacEnabled = true;
    professionalsEnabled = true;

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
        }
    }
}
