export class UserStatistics {
    aacBoardStoreAdd = 0;
    activityStoreAdd = 0;

    lastUsedTool = 'none';
    activitiesShared = 0;
    activitiesReceived = 0;
    activitiesStarted = 0;
    activitiesConcluded = 0;
    activitiesCreated = 0;
    activitiesCardsCreated = 0;
    activitiesSharedPlayed = 0;
    activitiesSharedSaved = 0;
    aacBoardsCreated = 0;
    aacCardsCreated = 0;
    aacSharedSaved = 0;
    aacSentencesSpoken = 0;
    lastCountry = 'none';
    lastLanguage = 'none';
    aacLockDisplayed = 0;
    aacLockAccepted = false;
    activitiesLockDisplayed = 0;
    activitiesLockAccepted = false;
    activityStorePlayBtnClicked = 0;
    aacPdfExport = 0;
    aacCardImageOriginFromDevice = 0;
    aacCardImageOriginFromPictograms = 0;
    aacCardImageOriginFromInternet = 0;

    constructor(obj = null) {
        if (typeof obj !== 'undefined' && obj !== null) {
            Object.assign(this, obj);
        }
    }
}
