// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyDhiDGr9sFhLvJVYGIrlUck6uTIaIjIbKk',
    authDomain: 'expressia-dev.firebaseapp.com',
    databaseURL: 'https://expressia-dev.firebaseio.com',
    projectId: 'expressia-dev',
    storageBucket: 'expressia-dev.appspot.com',
    messagingSenderId: '949450906979',
    appId: '1:949450906979:web:36066d8eccd037175b0756',
    measurementId: 'G-1NM078Q6FH'
  },
  googleAnalyticsDebugMode: true,
  googleTagManagerID: 'GTM-TNGFHN6',
  // Replace with your cloud functions location
  firebaseFunctionLocation: 'us-central1',
  // Replace with your publishable key
  // https://dashboard.stripe.com/apikeys
  // Test Key: 
  stripePublishableKey: 'pk_test_51HoppGBeYeuC8kuMeLxHMtxDr4YUlXK6ozoxg40BKTEykdiWHlVaV1hm9bTBUmVgzDhQKu8ruyKznTVf2JdSUElM00gFrpA3lU',
  //stripePublishableKey: 'pk_live_51HoppGBeYeuC8kuM0Pe5WItMD0pVfNhWVmUZXIH5PsX94jvCVr3Yaj2xBTQHr8bprEiuGoQGOuFiG6Xkl3rGK9rS00b7PT2g7K',
  // Replace with your tax ids
  // https://dashboard.stripe.com/tax-rates
  // Test Key: 
  stripeTaxRates: 'txr_1HotTNBeYeuC8kuMQJkcrNR2',
  //stripeTaxRates: 'txr_1HorgRBeYeuC8kuMxEyd7VGa',

  stripeActivitiesPriceID: 'price_1LowpJBeYeuC8kuM8t9lVf6M',
  stripeAacPriceID: 'price_1HqqMiBeYeuC8kuMImVusxS0',

  localizedParametersUrl: 'https://beta.expressia.life/assets/local-parameters.json',
  storageUrl: {
    default: 'https://saopauloms.expressia.life',
    saopaulo: 'https://saopauloms.expressia.life',
    london: 'https://londonms.expressia.life',
    ohio: 'https://ohioms.expressia.life'
  },

  firebaseFunctionsDomain: 'https://us-central1-expressia-dev.cloudfunctions.net',

  fireAuthServerClientId: '949450906979-0juirpvcj82omb2jfdafifaojmslbuvv.apps.googleusercontent.com',
  expressiaHubEndpoint: 'http://localhost:8101' // https://expressia-hub-dev.web.app
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
