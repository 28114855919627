import { Injectable } from '@angular/core';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { SpeakOptions, TTSAudiosVolatileCache, Voice, VoiceSettings, VoiceTTSOptions } from './text-to-speech.model';
import { AlertController } from '@ionic/angular';
import { naturalVoicesPtBr } from './text-to-speech-natural-voices';
import { AudioPlayerService } from 'src/app/utils/audio-player/audio-player.service';
import { AuthService } from 'src/app/auth/auth.service';
import { PlanService } from 'src/app/plan/plan.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  lang = 'pt-BR';
  speechVoice = null;
  availableVoicesWebSpeech = [];

  voiceSettings: VoiceSettings;
  // voiceTTSOptions: VoiceTTSOptions = {};
  supportedVoicesForCurrentLang: Voice[] = [];
  isLangSupportedForLocalTTS: boolean;

  private ttsAudiosVolatileCache: TTSAudiosVolatileCache = {};

  constructor(public app: Globals, private langService: LanguageService, private alertController: AlertController, private authService: AuthService,
    private audioPlayerService: AudioPlayerService, private plan: PlanService, private analyticsService: AnalyticsService)
  {
    this.langService.langObs.subscribe(lang => {
      // Sets the current lang for TTS
      this.lang = lang;

      // Setups the voice settings (like rate, pitch, voice) for the current app's language.
      this.setupVoiceSettingsForCurrentLang();
    });

    this.authService.authUserBS.subscribe(() => {
      // Setups the voice settings (like rate, pitch, voice) for the current app's language.
      this.setupVoiceSettingsForCurrentLang();
    });
  }

  /**
   * Speaks the text using TTS engine
   * @param text text
   * @param opts voice tss option (optional)
   * @returns void
   */
  async speak(words: string | Array<string>, opts?: SpeakOptions): Promise<void> {
    // Stops any ongoing speaking before starting a new one.
    this.audioPlayerService.stop();
    this.cancelSpeak();
    return new Promise(async (resolve, reject) => {
      // Converts to lowercase to reduce the change of spelling the words
      // instead of saying it.
      let text = '';
      if (typeof words === 'string') {
        text = words.toLowerCase();
      }

      // If the text is empty, there is nothing to speak.
      if (words === undefined || words === '' || words.length === 0) {
        resolve();
      } else {
        // Uses the provided TTS voice options. If it wasn't provided, uses the current options instead.
        const voiceSettings = opts?.voiceSettings || this.voiceSettings;

        if (voiceSettings.voice?.isRemote) {
          // Checks if the user can speak with remote tts voice.
          if (this.plan.canUseRemoteTts() || opts?.skipPlanCheck) {
            this.speakWithRemoteTTS(words, voiceSettings).then(() => resolve()).catch((err) => reject(err));
          } else {
            // if the user no longer can speak with remote tts voice, we clear the voice settings and speak with default voice.
            this.clearVoiceSettings();
            this.speak(words).then(() => resolve()).catch((err) => reject(err));
          }
        } else {
          // If the flags indicates the lang is not supported, checks again to make sure it's still not supported (maybe the user has installed the voice package meanwhile)
          if (!this.isLangSupportedForLocalTTS) {
            this.isLangSupportedForLocalTTS = await this.checkIfLangHasSupportedForLocalTTS();
          }

          if (this.isLangSupportedForLocalTTS) {
            // Speaks the text.
            TextToSpeech.speak({ text, lang: this.lang, ...voiceSettings.voiceTTSOptions, category: 'playback'})
              .then(() => { resolve(); })
              .catch((reason: any) => { console.log(reason); reject(reason); });
          } else {
            reject('lang-not-supported');
          }
        }
      }
    });
  }

  /**
   * Stop speaking
   */
  async cancelSpeak() {
    try {
      await TextToSpeech.stop();
      this.audioPlayerService.stop();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Checks if the current lang has supported for local TTS
   */
  async checkIfLangHasSupportedForLocalTTS() {
    // On Android web mobile, the lang uses _ as mark to separate the lang and the country (ex: pt_BR). To garantee that we will get all
    // voices available for a particular language, we check both case (ex: pt-BR and pt_BR).
    const suported = (await TextToSpeech.isLanguageSupported({ lang: this.lang })).supported;
    const suportedMobileAndroid = (await TextToSpeech.isLanguageSupported({ lang: this.lang.replace('-','_') })).supported;

    return suported || suportedMobileAndroid;
  }

  /**
   * Get the supported voices for the current app's language
   * @returns A list of supported voices for the current language
   */
  getSupportedVoicesForCurrentLang(): Promise<Voice[]> {
    const lang = this.lang;
    return new Promise((resolve, reject) => {
      TextToSpeech.getSupportedVoices().then(async result => {
        // Filters only the voices for the giving lang.
        let supportedVoicedForLang = (result.voices.filter(voice => this.isVoiceFromLang(voice, lang)) as Voice[]);
        
        // On Android web mobile, the lang uses _ as mark to separate the lang and the country (ex: pt_BR). To garantee that we will get all
        // voices available for a particular language, we load both case (ex: pt-BR and pt_BR).
        const supportedVoicedForLangMobileAndroid = (result.voices.filter(voice => this.isVoiceFromLang(voice, lang.replace('-', '_'))) as Voice[]);
        supportedVoicedForLang = supportedVoicedForLang.concat(supportedVoicedForLangMobileAndroid);

        // Filters only the voices that are supplied by a local speech synthesizer service when running on native app.
        // In the case there isn't any local voice available, it will keep the network voices, if any.
        if (this.app.isRunningOnNative()) {
          const localVoices = supportedVoicedForLang.filter(voice => voice.localService);
          if (localVoices.length > 0) {
            supportedVoicedForLang = localVoices;
          } 
        }

        // As of 06/05/2024, the majority of voices listed in iOS devices are really unsuable. Until this problem is fixed,
        // only the default voice will be available. 
        if (this.app.isRunningOnIos) {
          supportedVoicedForLang = [];
        }

        // Gets the supported natural voices for the current language
        const supportedNaturalVoicesForCurrentLang = await this.getSupportedNaturalVoicesForCurrentLang();

        // Adds the default voice if the local TTS is supported
        let defaultVoice: Voice = {
          default: true,
          lang: this.lang,
          localService: true,
          name: 'default',
          displayName: this.langService.words.aac.more.tts.defaultVoice,
          voiceURI: 'default-device-voice'
        }

        if (this.isLangSupportedForLocalTTS) {
          supportedVoicedForLang.push(defaultVoice);
        } 

        supportedVoicedForLang = supportedVoicedForLang.concat(supportedNaturalVoicesForCurrentLang);

        resolve(supportedVoicedForLang);
      }).catch(err => reject(err));
    });
  }

  getSupportedNaturalVoicesForCurrentLang(): Promise<Voice[]> {
    const lang = this.lang;
    return new Promise((resolve, reject) => {
      let supportedNaturalVoicedForLang: Array<Voice> = [];

      switch (lang) {
        case 'pt-BR': {
          supportedNaturalVoicedForLang = naturalVoicesPtBr;
          break;
        }
      }

      resolve(supportedNaturalVoicedForLang);
    });
  }

  /**
   * Gets all TTS supported voices.
   * @returns A list of all supported voices
   */
  getSupportedVoices(): Promise<SpeechSynthesisVoice[]> {
    return new Promise((resolve, reject) => {
      TextToSpeech.getSupportedVoices().then(result => {
        resolve(result.voices);
      }).catch(err => reject(err));
    });
  }

  /**
   * Setups the voice settings (like rate, pitch, voice) for the current app's language.
   */
  async setupVoiceSettingsForCurrentLang() {
    try {
      // Checks if the current lang has support to local TTS.
      this.isLangSupportedForLocalTTS = await this.checkIfLangHasSupportedForLocalTTS();

      // Reads the voice settings for the current language. If the user hasn't set the voice settings yet, 
      // the voice settings will be an empty object.
      this.voiceSettings = JSON.parse(localStorage.getItem(`app-voice-settings-${this.lang.toLocaleLowerCase()}-${this.app.user.uid}`) || '{ "voiceTTSOptions": {} }');
      // this.voiceTTSOptions = this.voiceSettings.voiceTTSOptions || {};

      // In the case the user has chosen a voice, we need to find the voice's index among all supported voices.
      this.setupVoiceIndex();

      // Gets the supported voices for the current language
      this.supportedVoicesForCurrentLang = await this.getSupportedVoicesForCurrentLang();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Handles when the voice of the TTS voice changes. It sets the new value and persists it locally.
   * @param rate 
   */
  async onVoiceChanged(voice: Voice) {
    this.voiceSettings.voice = voice;
    await this.setupVoiceIndex();
    this.saveVoiceSettings();
  }

  /**
   * Handles when the rate of the TTS voice changes. It sets the new value and persists it locally.
   * @param rate 
   */
  onVoiceRateChanged(rate: number) {
    this.voiceSettings.voiceTTSOptions.rate = rate;
    this.saveVoiceSettings();
  }

  /**
   * Handles when the pitch of the TTS voice changes. It sets the new value and persists it locally.
   * @param rate 
   */
  onVoicePitchChanged(pitch: number) {
    this.voiceSettings.voiceTTSOptions.pitch = pitch;
    this.saveVoiceSettings();
  }

  /**
   * Returns the TTS voice's index from its voice URI
   * @param voiceName voice's URI
   * @returns 
   */
  async voiceUriToIndex(voiceUri: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.getSupportedVoices().then(voices => {
        resolve(voices.findIndex(voice => voice.voiceURI === voiceUri));
      }).catch(err => reject(err));
    });
  }

  /**
   * Shows an alert advising the user that the TTS voice's package for the current language is not installed.
   */
  async showRemoteTtsInternetRequiredAdviseAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert-large',
      subHeader: this.langService.words.alerts.remoteTtsInternetRequiredAdvise.subHeader,
      message: this.langService.words.alerts.remoteTtsInternetRequiredAdvise.message,
      buttons: [this.langService.words.common.ok]
    });

    await alert.present();
  }

  /**
   * Shows an alert advising the user that the TTS voice's package for the current language is not installed.
   */
  async showVoicePackageNotInstalledErrorAlert() {
    this.analyticsService.logEvent(`tts_voice_package_not_installed_error`);
    let message = this.langService.words.alerts.voicePackageNotInstalledError.message;
    if (this.app.isRunningOnAndroidApp()) {
      message = this.langService.words.alerts.voicePackageNotInstalledError.messageAndroid;
    }

    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert-large',
      subHeader: this.langService.words.alerts.voicePackageNotInstalledError.subHeader,
      message,
      buttons: [this.langService.words.common.ok]
    });

    await alert.present();
  }

  /**
   * Checks if the user has already seen the alert telling about the tts settings feature. It should be
   * displayed once by user and by lang.
   * @returns 
   */
  shouldShowTtsSettingsFeatureAlert() {
    const key = `tts-settings-feature-alert-viewed-${this.lang.toLocaleLowerCase()}-${this.app.user.uid}`;
    const viewed = localStorage.getItem(key);
    if (viewed) {
      return false;
    } else {
      localStorage.setItem(key, 'true');
      return true;
    }
  }

  /**
   * In the case the user has chosen a voice, we need to find the voice's index among all supported voices.
   */
  private async setupVoiceIndex() {
    if (this.voiceSettings.voice && !this.voiceSettings.voice.isRemote) {
      const voiceIndex = (await this.getSupportedVoices()).findIndex(voice => voice.voiceURI === this.voiceSettings.voice.voiceURI);
      // Sets the voice index or remove the prop if voice's index wasn't found.
      if (voiceIndex >= 0) {
        this.voiceSettings.voiceTTSOptions.voice = voiceIndex;
      } else {
        this.voiceSettings.voiceTTSOptions.voice = undefined;
      }
    } else {
      this.voiceSettings.voiceTTSOptions = {};
    }
  }

  /**
   * Filter all voices for the giving lang
   * Checks if a voice is from a giving lang. It takes into account the different
   * prefix that TTS's engine may uses to indicate the lang of the voice.
   * @param voice 
   * @param lang BCP 47 language tag indicating the language of the voice. 
   */
  private isVoiceFromLang(voice: SpeechSynthesisVoice, lang: string) {
    // Checks if the lang tag is found in lang or voiceURI or name properties since there is no guarantee that
    // it will always the in lang property.
    return (voice.lang + voice.voiceURI + voice.name).toLowerCase().includes(lang.toLowerCase());
  }

  /**
   * Saves the current voice setting.
   */
  private saveVoiceSettings() {
    // Note: The SpeechSynthesisVoice objects will appear as {} after JSON.stringfy. To work around it,
    // we create a new voice settings object and set the voice properties accordingly.
    const voiceSettingsObj = {
      voiceTTSOptions: this.voiceSettings.voiceTTSOptions,
      voice: {
        isRemote: this.voiceSettings.voice?.isRemote,
        default: this.voiceSettings.voice?.default,
        lang: this.voiceSettings.voice?.lang,
        localService: this.voiceSettings.voice?.localService,
        name: this.voiceSettings.voice?.name,
        displayName: this.voiceSettings.voice?.displayName,
        voiceURI: this.voiceSettings.voice?.voiceURI
      }
    }
    localStorage.setItem(`app-voice-settings-${this.lang.toLocaleLowerCase()}-${this.app.user.uid}`, JSON.stringify(voiceSettingsObj));
  }

  /**
   * Clears the voice settings.
   */
  clearVoiceSettings() {
    localStorage.removeItem(`app-voice-settings-${this.lang.toLocaleLowerCase()}-${this.app.user.uid}`);
    this.setupVoiceSettingsForCurrentLang();
  }

  private async speakWithRemoteTTS(words: string | Array<string>, voiceSettings: VoiceSettings): Promise<void> {
    return new Promise(async (resolve, reject) => {

      let textArray = [];
      if (typeof words === 'string') {
        textArray = [words];
      } else {
        textArray = words;
      }

      try {
        for (const text of textArray) {
          // In this first implementation, the pitch and rate are not considered for natural voices.
          const ttsAudioDetail = {
            voice: voiceSettings.voice.name,
            lang: this.app.lang,
            text: text.toLocaleLowerCase().trim(),
          }
  
          // console.log('[text-to-speech-service] ttsAudioDetail: ', JSON.stringify(ttsAudioDetail));
  
          // Finds out the audio's name.
          const ttsAudioName = `${await this.generateHash(JSON.stringify(ttsAudioDetail))}.mp3`;
          // console.log('[text-to-speech-service] ttsAudioName: ', ttsAudioName);
  
          // Gets the tts audio as array buffer.
          const audioArrayBuffer = await this.getTtsAudioArrayBuffer(ttsAudioName, voiceSettings.voice.voiceURI, ttsAudioDetail);

          // Pushes the audio to stack.
          await this.audioPlayerService.pushAudioToStackFromBufferArray(audioArrayBuffer);
        }

        // Plays the audio stack.
        await this.audioPlayerService.playAudioStack();

        // All done!
        resolve();
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  private getTtsAudioArrayBuffer(ttsAudioName: string, voiceURI: string, ttsAudioDetail): Promise<ArrayBuffer> {
    return new Promise(async (resolve, reject) => {

      // console.log(this.ttsAudiosVolatileCache);

      // if (this.ttsAudiosVolatileCache[ttsAudioName]) {
      //   resolve(this.ttsAudiosVolatileCache[ttsAudioName]);
      //   return;
      // }

      // try {
      //   const ttsAudioNativeSrc = this.nativeStorageService.resolveTextToSpeechAudioSrc(ttsAudioName);
      //   const blob = await this.fetchTtsAudioBlob(ttsAudioNativeSrc);
      //   resolve(blob);
      //   console.log(`[text-to-speech-service] TTS audio ${ttsAudioName} NÃO disponivel no dispositivo. Tentanto reproduzir audio em cache na núvem...`);
      //   return;
      // } catch(err) {
      //   console.log(err);
      // }

      try {
        const ttsAudioCloudStorageSrc = this.app.storageLocation + '/cache/text-to-speech-audios/' +  ttsAudioName;
        const arrayBuffer = await this.fetchTtsAudioArrayBuffer(ttsAudioCloudStorageSrc);
        this.ttsAudiosVolatileCache[ttsAudioName] = arrayBuffer;

        this.analyticsService.logEvent(`tts_audio_cloud_storage_cache`, { ttsAudioName });

        // TODO: SAVE FILE
        resolve(arrayBuffer);
        return;
      } catch(err) {
        console.log(err);
        // console.log(`[text-to-speech-service] TTS audio ${ttsAudioName} NÃO disponivel na núvem. Tentando gerar o áudio...`);
      }

      try {
        const ttsAudioRemoteSrc = `${this.app.apiEndpoint}/${voiceURI}${this.audioDetailToQueryString(ttsAudioDetail)}`;
        this.fetchTtsAudioArrayBuffer(ttsAudioRemoteSrc).then((arrayBuffer) => {
          this.ttsAudiosVolatileCache[ttsAudioName] = arrayBuffer;
          this.analyticsService.logEvent(`tts_audio_generate`, { charCount: ttsAudioDetail.text.length, ttsAudioName }, 'charCount');
        });

        // TODO: SAVE FILE
        reject(`[text-to-speech-service] TTS Audio not cached yet`);
        return;
      } catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }

  private fetchTtsAudioArrayBuffer(ttsAudioSrc: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      fetch(ttsAudioSrc).then(response => { 
        if (response.status >= 200 && response.status < 300) {
          response.arrayBuffer().then(buffer => {
            resolve(buffer);
          }).catch(err => reject(err));
        } else {
          reject(response.statusText);
        }
      }).catch(err => {
        reject(err);
      })
    });
  }

  private audioDetailToQueryString(obj: any) {
    let queryString = '';
    Object.keys(obj).forEach(key => {
      // Skips the voice key since it is already in the voice URI.
      if (key !== 'voice') {
        queryString += `&${key}=${encodeURI(obj[key])}`;
      }
    });
    // console.log(queryString);
    return queryString;
  }

  private async generateHash(string: string) {
    return new Promise((resolve, reject) => {
      const text_encoder = new TextEncoder;
      const data = text_encoder.encode(string);
      window.crypto.subtle.digest("SHA-1", data).then((messageDigest) => {
        const octets = new Uint8Array(messageDigest);
        const hash = [].map.call(octets, octet => octet.toString(16).padStart(2, "0")).join("");
        resolve(hash);
      }).catch(err => {
        reject(err);
      })
    });
  }
}
