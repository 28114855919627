// tslint:disable: variable-name
export class Card {
    _id = '';
    name = '';
    img = '';
    title = '';
    audio = '';
    cards: Card[] = [];
    isOrderable: boolean;
    isEditable: boolean;
    isRemovable: boolean;
}
