import { Voice } from "./text-to-speech.model";

export const naturalVoicesPtBr: Array<Voice> = [
    { 
        isRemote: true,
        default: false,
        lang: 'pt-BR',
        localService: false,
        name: 'morena',
        displayName: 'Voz natural - Criança',
        voiceURI: 'narakeetTextToSpeech?voice=morena'
    }, { 
        isRemote: true,
        default: false,
        lang: 'pt-BR',
        localService: false,
        name: 'gisele',
        displayName: 'Voz natural - Adulto - Feminina',
        voiceURI: 'narakeetTextToSpeech?voice=gisele'
    }, { 
        isRemote: true,
        default: false,
        lang: 'pt-BR',
        localService: false,
        name: 'bernardo',
        displayName: 'Voz natural - Adulto - Masculina',
        voiceURI: 'narakeetTextToSpeech?voice=bernardo'
    }
]