import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Globals } from 'src/app/globals/globals';
import { LanguageService } from 'src/app/language/language.service';
import { InvitesService } from '../invites.service';
import { Invite } from './invite.model';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {

  theme = 'activities';
  invite: Invite;
  userName = '';
  inviteUrl = '';

  constructor(private invitesService: InvitesService, private toastController: ToastController, public lang: LanguageService,
              private modalController: ModalController, private app: Globals, public clipboardNative: Clipboard,
              private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.userName = this.app.user.name;
    this.invitesService.createInvite(this.app.user.uid).then(invite => {
      this.invite = invite;
      this.inviteUrl = `${this.app.originUrl}/s/invites/${invite.id}`;
      this.analyticsService.logEvent('invite_contact_link_create', { id: invite.id });
    });
  }

  onCloseBtnClicked() {
    this.modalController.dismiss();
  }

  onCopyLinkBtnClicked() {
    if (this.app.isRunningOnBrowser()) {
      console.error('navigator.clipboard');
      navigator.clipboard.writeText(this.inviteUrl);
    } else {
      console.error('clipboardNative');
      this.clipboardNative.copy(this.inviteUrl);
    }

    this.showCopiedToast();
  }

  async showCopiedToast() {
    const toast = await this.toastController.create({
      message: this.lang.words.toasts.linkCopiedToClipboard.message,
      cssClass: 'app-toast',
      duration: 1500
    });
    toast.present();
  }
}
