import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AacBoard } from '../aac/aac-board/aac-board.model';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import * as aacBoardsSampleJson from '../../assets/sample-boards/aac-boards-sample-pt-br.json';
import * as aacBoardsSampleEnGbJson from '../../assets/sample-boards/aac-boards-sample-en-gb.json';
import * as aacBoardsSampleArSAJson from '../../assets/sample-boards/aac-boards-sample-ar-sa.json';
import * as aacBoardsSampleItITJson from '../../assets/sample-boards/aac-boards-sample-it-it.json';
import * as activityBoardsSampleJson from '../../assets/sample-boards/activity-boards-sample-pt-br.json';
import * as activityBoardsSampleEnGbJson from '../../assets/sample-boards/activity-boards-sample-en-gb.json';
import * as activityBoardsSampleArSAJson from '../../assets/sample-boards/activity-boards-sample-ar-sa.json';
import * as activityBoardsSampleItITJson from '../../assets/sample-boards/activity-boards-sample-it-it.json';

@Injectable({
  providedIn: 'root'
})
export class SampleBoardsService {

  activityBoardsSample: ActivityBoard[] = [];
  aacBoardsSample: AacBoard[] = [];

  constructor(private firestore: AngularFirestore) {
    this.aacBoardsSample = (aacBoardsSampleJson as any).default;
    this.activityBoardsSample = (activityBoardsSampleJson as any).default;
  }

  /**
   * Gets the aac's sample boards for the giving lang.
   * @param lang language
   * @returns 
   */
  getAacSampleBoards(lang = 'pt-BR'): AacBoard[] {
    let sampleBoards = [];
    switch (lang) {
      case 'en-GB': { sampleBoards = (aacBoardsSampleEnGbJson as any).default; break; }
      case 'en-US': { sampleBoards = (aacBoardsSampleEnGbJson as any).default; break; }
      case 'ar-SA': { sampleBoards = (aacBoardsSampleArSAJson as any).default; break; }
      case 'it-IT': { sampleBoards = (aacBoardsSampleItITJson as any).default; break; }
      case 'pt-BR': { sampleBoards = (aacBoardsSampleJson as any).default; break; }
      case 'pt-PT': { sampleBoards = (aacBoardsSampleJson as any).default; break; }
      default: { sampleBoards = (aacBoardsSampleEnGbJson as any).default; break; }
    }
    return JSON.parse(JSON.stringify(sampleBoards));
  }

  /**
   * Gets the sample activities for the giving lang.
   * @param lang language
   * @returns 
   */
  getActivitiesSampleBoards(lang: string) {
    let sampleBoards = [];
    switch (lang) {
      case 'en-GB': { sampleBoards = (activityBoardsSampleEnGbJson as any).default; break; }
      case 'en-US': { sampleBoards = (activityBoardsSampleEnGbJson as any).default; break; }
      case 'ar-SA': { sampleBoards = (activityBoardsSampleArSAJson as any).default; break; }
      case 'it-IT': { sampleBoards = (activityBoardsSampleItITJson as any).default; break; }
      case 'pt-BR': { sampleBoards =  (activityBoardsSampleJson as any).default; break; }
      case 'pt-PT': { sampleBoards =  (activityBoardsSampleJson as any).default; break; }
      default: { sampleBoards =  (activityBoardsSampleEnGbJson as any).default; break; }
    }
    return JSON.parse(JSON.stringify(sampleBoards));
  }

  /**
   * Recursively finds all cards that are inside a board, including cards that are in sub categories.
   * @param boardId board's id
   * @param sampleBoards Array containing the aac cards
   * @param boards Empty array where the result will be placed
   * @returns new board id
   */
  discoveryBoards(boardId: string, sampleBoards = [], boards = []) {
    const board = sampleBoards.find(b => b._id === boardId);
    board.cards = board.cards.filter(c => c && c.length == 20);
    const newCardsIds = [];

    if (typeof board === 'undefined' || board === null) { return; }

    board._id = this.firestore.createId();
    delete board.owner;
    delete board.createdAt;
    delete board.updatedAt;
    delete board.__v;
    // delete board.question;

    for (const cardId of board.cards) {
      const newCardId = this.discoveryBoards(cardId, sampleBoards, boards);
      newCardsIds.push(newCardId);
    }

    board.cards = newCardsIds;
    boards.push(board);

    return board._id;
  }

  /**
   * List all medias name of the app's sample contents, including both aac and activiites module.
   * @returns array with the sample conttent's medias name
   */
  listSamplesMedias() {
    const sampleMedias = [];
    for (const board of this.aacBoardsSample) {
      if (board.img !== '') { sampleMedias.push(board.img); }
      if (board.audio !== '') { sampleMedias.push(board.audio); }
    }

    for (const activity of this.activityBoardsSample) {
      if (activity.img !== '') { sampleMedias.push(activity.img); }
      if (activity.questionImgSrc !== '') { sampleMedias.push(activity.questionImgSrc); }
      if (activity.audio !== '') { sampleMedias.push(activity.audio); }
      if (activity.questionAudio !== '') { sampleMedias.push(activity.questionAudio); }
    }

    return sampleMedias;
  }
}
