import { User } from './../user/user.model';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AacService } from '../aac/aac.service';
import { ActivitiesService } from '../activities/activities.service';
import { PlanService } from '../plan/plan.service';
import { PlanFeatures } from '../plan/plan-features.model';
import { LanguageService } from '../language/language.service';
import firebase from 'firebase/compat/app';
import { UserService } from '../user/user.service';
import { UserServiceV2 } from '../v2/user/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Globals } from '../globals/globals';
import { UserDataService } from '../user/user-data/user-data.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
   providedIn: 'root',
})
export class AuthService {

   userDocSub: Subscription;
   authUserBS: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
   authUserSubject: Subject<firebase.User> = new Subject<firebase.User>();
   authStateSubject: Subject<firebase.User> = new Subject<firebase.User>();

   // The pending Facebook credential.
   pendingCred: firebase.auth.UserCredential;
   // The provider account's email address.
   pendingEmail = '';
   pendingPwd = '';
   authStateInitiated = false;

   constructor( private app: Globals, private fireAuth: AngularFireAuth, private userDataService: UserDataService,
                private navCtrl: NavController, private aacService: AacService, private activityService: ActivitiesService, 
                private planService: PlanService, public fns: AngularFireFunctions, private lang: LanguageService,
                private userService: UserService, private userServiceV2: UserServiceV2, private http: HttpClient,
                private pushNotificationService: PushNotificationService, private analytics: AnalyticsService) {

                /**
                * Watch for any changes on app language
                */
                  this.lang.langObs.subscribe((lang) => {
                     this.fireAuth.languageCode = new Promise(() => lang); // Set language used by Firebase Auth Emails
                  });
                }

   initAuthListener() {
      this.fireAuth.authState.subscribe(user => {
         if (user) {
            this.setupUser(user);
         } else {
            this.clearUser();
            this.planService.updatePlan(PlanFeatures.fromSubscriptionId('free'));
         }
         
         // Emits the current fire auth user. 
         // NOTE: Deprecated. Avoid listening to authUserBS. It will be removed in the future versions. Use authStateSubject instead.
         this.authUserBS.next(user);

         // Emits the current state of fire auth user.
         this.authStateSubject.next(user);

         // Emitted only when the changes on auth is intended by the user. In other words, skips the
         // first emittion since il will be regarding the auth`s initialization.
         if (this.authStateInitiated) {
            this.authUserSubject.next(user);
            this.pushNotificationService.refreshUserFcmToken();
         } else {
            this.authStateInitiated = true;

            // Init the push notification service as soon as the auth has initiated.
            this.pushNotificationService.initPushNotification();

            // Let the Expressia Analytics service knows that the auth has been initiated.
            this.analytics.onAuthStateInitiated();
         }

         // Emits the app_info and geolocation events when the user auth changes
         this.analytics.refreshAppInfoAndGeolocation();
      });
   }

   setupUser(user: firebase.User) {
      // Workaround to ensure that app.user will have the most important data updated as fast as possible
      this.app.user.uid = user.uid;
      this.app.user.name = user.displayName;
      this.app.user.email = user.email;

      this.planService.clearPlan();

      this.userServiceV2.loadUser(user.uid).then(userDoc => {
         // As configurações relacionadas a varredura e uso com o TiX são mantidas à atuais do app
         if (userDoc.preferences) {
            userDoc.preferences.useScanMode = false;
            userDoc.preferences.useWithTix = false;
         }

         if (userDoc.name === '' || userDoc.email === '') {
            Object.assign(userDoc, { name: user.displayName, email: user.email});
         }

         this.app.user = userDoc;
         this.userService.userChanged(userDoc);

         /**
          * Updates the user plan based on the plan features. In the future, the ideia is get this features
          * from the user doc. This way, we will be able to create custom features that may suit specific needs.
          * For now, we are considering fixed features related with our main plans: Activities and Aac.
          */
         this.planService.updatePlan(PlanFeatures.fromSubscriptionId(this.app.user.subscription.id));
      
         // console.log('[auth] User Doc: ', userDoc);
      });
   }

   /**
    * Refreshes the current user's data. The loadUser function will try to get
    * the most updated version of the user's data. After the resfresh, the updated
    * data will be available in the app.user object. 
    */
   async refreshUser(): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            if (this.app.user.uid) {
               const beforeSyncIsSubscriber = this.app.user.isSubscriber();
               const beforeSyncPlanLockEnabled = this.app.user.plan.planLockEnabled;
      
               await this.userDataService.loadUser(this.app.user.uid, { skipPreferences: true }).then(() => {
                  // Shows a success subscription modal if the user has just subscribed. 
                  if (this.app.user.isSubscriber() && !beforeSyncIsSubscriber || !this.app.user.plan.planLockEnabled && beforeSyncPlanLockEnabled) {
                     this.navCtrl.navigateForward('/subscription-success-v2');
                  }
   
                  // Updates the user plan based on the plan features.
                  this.planService.updatePlan(PlanFeatures.fromSubscriptionId(this.app.user.subscription.id));
               });
            }
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   clearUser() {
      try {
         this.aacService.clearAacBoards();
         this.activityService.clearActivityBoards();
         this.userServiceV2.clearCurrentUser();
         this.app.user = new User();
         this.userService.userChanged(this.app.user);
      } catch (err) {
         console.log(err);
      }
   }

   signIn(email: string, password: string): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            await this.fireAuth.signInWithEmailAndPassword(email.toLowerCase(), password);
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   signInWithCustomToken(customToken: string): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            await this.fireAuth.signInWithCustomToken(customToken);
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   signOut(): Promise<void> {
      return new Promise(async (resolve, reject) => {
         try {
            await this.fireAuth.signOut();
            resolve();
         } catch (err) {
            console.log(err);
            reject(err);
         }
      });
   }

   async sendPasswordResetEmail(email: string): Promise<any> {
      this.fireAuth.languageCode = new Promise(() => this.app.lang); // Set language used by Firebase Auth Emails
      return new Promise <void> ( async (resolve, reject) => {
      this.fireAuth.sendPasswordResetEmail(email.toLowerCase()).then(
         () => {
          resolve();
         },
         err => {
           reject();
         }
       );
      });
   }

   isConversiaProvider(email: string) {
      return (email.endsWith('@conversia.life') || email.endsWith('.conversia.life') || email.endsWith("@conversia.com.br") || email.endsWith(".conversia.com.br"));
   }

   authenticateConversia(email: string, password: string) {
      return new Promise<string>((resolve, reject) => {
         // Call http onrequest firebase function to authenticate user
         const body = { email, password };
         let functionUrl: string;
         switch (environment.environmentName) {
            case 'prod':
            case 'beta':
               functionUrl = 'https://us-central1-conversia-prod.cloudfunctions.net/authenticateUser';
            break;
         default:
               functionUrl = 'https://us-central1-conversia-dev.cloudfunctions.net/authenticateUser';
            break;
         }

         this.http.post(functionUrl, body).subscribe((res: {customToken: string}) => {
            //console.log(res.customToken);
            resolve(res.customToken);
         }, err => {
            console.log(err);
            reject(err.error);
         });
      });
   }

   /**
   * Generates a custom token for the current user
   * @returns token
   */
  generateUserCustomToken(): Promise<string>  {
   return new Promise((resolve, reject) => {
     this.fns.httpsCallable('generateHubToken')({ }).subscribe(
       data => resolve(data.token),
       err => reject(err)
     );
   });
 }
}
