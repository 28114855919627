import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ImageCropInfo } from 'src/app/editor-tools/models/image-crop-info.model';
import { Globals } from 'src/app/globals/globals';
import { MediaStorageService } from 'src/app/media-storage/media-storage.service';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit, OnChanges {

  @Input() src = '';
  @Input() name = '';
  @Input() width = 0;
  @Input() height = 0;
  @Input() marginTop = 0;
  @Input() marginLeft = 0;
  @Input() marginBottom = 0;
  @Input() widthOffset = 0;
  @Input() imgLeftOffset = 0;
  @Input() imgTopOffset = 0;
  @Input() background = 'white';
  @Input() borderRadius = '';
  @Input() borderColor = '';
  @Input() borderEnabled = false;
  @Input() useHtmlImgTag = false;

  @Output() load = new EventEmitter();
  @Output() error = new EventEmitter();

  imageCropInfo: ImageCropInfo;
  canFallbackToCloudDatabaseMedias = true;
  skeletonLoadingVisible = false;
  initialSrc = '';
  refreshImgSrcEnabled = true;

  constructor(private app: Globals, private mediaStorageService: MediaStorageService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any): void {
    // TODO AND WARNING: I think its not a good idea to let the code bellow inside ngOnChages because it got called excessively

    if (changes.name) {
      // Since we've changed the img name, we can fallback to cloud database medias in the case we faced an error while loading
      // the image from its original source.
      this.canFallbackToCloudDatabaseMedias = true;
      this.imageCropInfo = null;
    }

    /**
     * In the case where the card image has information about crop image instead of the image url itself,
     * the image will be displayed by the html tag <app-cropped-img> so we set the image crop info to
     * render it properly
     */
    if (this.src && this.src.includes('imageCroppedEvent')) {
      //console.log(this.src);
      let src = this.src;

      if (this.src.includes(this.app.imageStorageLocation)) {
        src = this.src.replace(this.app.imageStorageLocation, '');
      }

      const obj = JSON.parse(src);
      //console.log(obj);
      this.imageCropInfo = new ImageCropInfo();
      Object.assign(this.imageCropInfo, obj);
    } else if (this.src.includes('localhost')) {
      this.refreshImgSrcEnabled = false;
      this.canFallbackToCloudDatabaseMedias = false;
    }

    this.initialSrc = this.src;
  }

  onCroppedImgLoad(ev: any) {
    this.load.emit(ev);
  }

  onCroppedImgError(ev: any) {
    this.error.emit(ev);
  }

  onImgLoad(ev: any) {
    this.skeletonLoadingVisible = false;
    this.load.emit(ev);
  }

  onImgError(ev: any) {
    /**
     * Tries to get the image info from cloud database medias if it was not possible to load the image from its original source.
     */
    this.skeletonLoadingVisible = true;
    //console.log('onImgError');
    if (this.name && this.canFallbackToCloudDatabaseMedias) {
      //console.log('resolving CloudDatabaseMedia');
      this.mediaStorageService.resolveCloudDatabaseMedia(this.name).then(media => {
        // Sets the image crop info if it loads successfully
        if (media && media.imageCropInfo) {
          this.imageCropInfo = media.imageCropInfo;
        } else {
          this.refreshImgSrc();
        }

        // Just tried to access the image from cloud database medias and it only makes sense to fallback to
        // cloud datadase medias again when the image name changes
        this.canFallbackToCloudDatabaseMedias = false;
      }).catch(err => {
        console.log(err);
        this.refreshImgSrc();
      });
    } else {
      this.refreshImgSrc();
    }

    this.error.emit(ev);
  }

  refreshImgSrc() {
    if (this.refreshImgSrcEnabled) {
    this.src = '';
    setTimeout(() => this.src = this.initialSrc, 3000);
    }

  }
}
