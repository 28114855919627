import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from 'src/app/database/database.service';
import { Feedback } from './feedback.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  userFeedbacksSub: Subscription;
  userFeedbacks = [];

  private userFeedbacksBS = new BehaviorSubject<Feedback[]>([]);
  userFeedbacksObs = this.userFeedbacksBS.asObservable();

  constructor(private databaseService: DatabaseService, private firestore: AngularFirestore) { }

  sendFeedback(feedback: Feedback) {
    return this.databaseService.sendFeedback(feedback);
  }

  watchUserFeedbacks(uid: string) {
    if (this.userFeedbacksSub) {
      this.userFeedbacksSub.unsubscribe();
    }

    //console.log('watchUserFeedbacks')

    this.userFeedbacksSub = this.firestore.collection<Feedback>(`feedbacks`, ref => ref.where('author', '==', uid)).valueChanges().subscribe(feedbacks => {
      this.userFeedbacks = feedbacks;

      this.userFeedbacksBS.next(feedbacks);
    })
  }

  updateFeedback(feedback: Feedback) {
    return this.databaseService.updateFeedback(feedback);
  }
}
