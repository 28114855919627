import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {

  private audio = new Audio();
  private audioContext = new AudioContext();
  private audioStack: Array<AudioBuffer> = [];
  private audioChunks = [];
  private nextTime = 0;
  private sources = [];
  stopAudioEvent = new EventEmitter<void>();

  constructor() { }

  play(src: string): Promise<void> {
    this.stop();
    return new Promise((resolve, reject) => {

      const stopAudioEventSub = this.stopAudioEvent.subscribe(() => {
        stopAudioEventSub.unsubscribe();
        resolve();
      });

      this.audio.src = src;
      this.audio.load();
      this.audio.play();
  
      this.audio.onended = (ev) => {
        stopAudioEventSub.unsubscribe();
        resolve();
      };
  
      this.audio.onerror = (ev) => {
        stopAudioEventSub.unsubscribe();
        reject();
      };
    });
  }

  playStream(url: string): Promise<Blob> {
    this.stop();
    return new Promise((resolve, reject) => {
      this.audioStack = [];
      this.audioChunks = [];
      this.nextTime = 0;
      fetch(url).then((response) => {
        // console.log(response);
        if (response.status === 200) {
          const reader = response.body.getReader();
          this.read(reader, this.audioContext, this.audioStack).then(blob => {
            resolve(blob);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject(response.statusText);
        }
      }).catch(err => {
        console.log(err);
        reject(err);
      })
    });
  }

  /**
  * Stops any audio sources that are still playing.
  */
  stop() {
    this.sources.forEach((source) => {
        source.stop(0);
    });
    this.sources = [];
    this.nextTime = 0;

    this.audio.pause();
    this.audio.currentTime = 0;

    this.stopAudioEvent.emit();
  }

  /**
   * Plays the audio stack
   * @param nextTimeOffset duration offset when the next audio in the stack will start. 
   * @returns 
   */
  playAudioStack(nextTimeOffset = -0.8): Promise<void> {
    return new Promise((resolve, reject) => {
      const stopAudioEventSub = this.stopAudioEvent.subscribe(() => {
        stopAudioEventSub.unsubscribe();
        resolve();
      });

      if (this.audioStack.length === 0) {
        stopAudioEventSub.unsubscribe();
        resolve();
      } else {
        const audioStackSize = this.audioStack.length;
        let sourcesEndedCount = 0;
        while (this.audioStack.length) {
          const buffer    = this.audioStack.shift();
          const source    = this.audioContext.createBufferSource();
          source.buffer = buffer;

          // Keep track of all sources created, and stop tracking them once they finish playing.
          this.sources.push(source) - 1;
          source.onended = () => {
            source.stop(0);

            sourcesEndedCount++;
            
            // Resolves when all sources have been played.
            if (sourcesEndedCount === audioStackSize) {
              stopAudioEventSub.unsubscribe();
              this.stop();
              resolve();
            }
          };

          source.connect(this.audioContext.destination);

          if (this.nextTime == 0) {
            // Add 10ms latency to work well across systems - tune this if you like
            this.nextTime = this.audioContext.currentTime + 0.01;
          }
          source.start(this.nextTime);
          // Make the next buffer wait the length of the last buffer before being played
          this.nextTime += source.buffer.duration + nextTimeOffset;
        };
      }
    });
  }

  /**
   * Adds a new audio to stack from a buffer array
   * @param arrayBuffer 
   * @returns 
   */
  pushAudioToStackFromBufferArray(arrayBuffer: ArrayBuffer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        this.audioStack.push(buffer);
        resolve();
      },  (err) => {
        console.log("err (decodeAudioData): " + err);
        reject(err);
      });
    });
  }

  private async read(reader: ReadableStreamDefaultReader<Uint8Array>, audioContext: AudioContext, audioStack) {
    return new Promise<Blob>((resolve, reject) => {
      reader.read().then(({ value, done })=> {
        if (done) {
          // console.log('done');
          // console.log(this.audioChunks);
          const blob = new Blob([new Uint8Array(this.audioChunks)], { type: 'audio/mpeg' });
          resolve(blob);
          return;
        } else {
          // console.log(value,done);
          this.audioChunks.push(...value);
  
          audioContext.decodeAudioData(value.buffer, (buffer) => {
            audioStack.push(buffer);
  
            if (audioStack.length) {
                this.scheduleBuffers();
            }
          }, (err) => {
            console.log("err (decodeAudioData): " + err);
            reject(err);
          });
        }
        this.read(reader, audioContext, audioStack).then((blob) => {
          resolve(blob);
        }).catch(err => {
          reject(err);
        })
      });
    });
  }


  private scheduleBuffers() {
    while (this.audioStack.length) {
        const buffer    = this.audioStack.shift();
        const source    = this.audioContext.createBufferSource();
        source.buffer = buffer;

        // Keep track of all sources created, and stop tracking them once they finish playing.
        const insertedAt = this.sources.push(source) - 1;
        source.onended = () => {
          source.stop(0);
          this.sources.splice(insertedAt, 1);
        };

        source.connect(this.audioContext.destination);

        if (this.nextTime == 0) {
          // Add 10ms latency to work well across systems - tune this if you like
          this.nextTime = this.audioContext.currentTime + 0.01;
        }
        source.start(this.nextTime);
        // Make the next buffer wait the length of the last buffer before being played
        this.nextTime += source.buffer.duration;
    };
  }

  //
// loads remote file using fetch() streams and "pipe" it to webaudio API
// remote file must have CORS enabled if on another domain
//
// mostly from http://stackoverflow.com/questions/20475982/choppy-inaudible-playback-with-chunked-audio-through-web-audio-api
// 

// function play(url) {
//   var context = new (window.AudioContext || window.webkitAudioContext)();
//   var audioStack = [];
//   var nextTime = 0;

//   fetch(url).then(function(response) {
//     var reader = response.body.getReader();
//     function read() {
//       return reader.read().then(({ value, done })=> {
//         if (done) {
//           console.log('done');
//           return;
//         }else{
//           console.log(value,done);
//           context.decodeAudioData(value.buffer, function(buffer) {
//             audioStack.push(buffer);
//             if (audioStack.length) {
//                 scheduleBuffers();
//             }
//           }, function(err) {
//             console.log("err(decodeAudioData): "+err);
//           });
//         }
//         read()
//       });
//     }
//     read();
//   })

//   function scheduleBuffers() {
//       while ( audioStack.length) {
//           var buffer    = audioStack.shift();
//           var source    = context.createBufferSource();
//           source.buffer = buffer;
//           source.connect(context.destination);
//           if (nextTime == 0)
//               nextTime = context.currentTime + 0.01;  /// add 50ms latency to work well across systems - tune this if you like
//           source.start(nextTime);
//           nextTime += source.buffer.duration; // Make the next buffer wait the length of the last buffer before being played
//       };
//   }
}
