import { EventEmitter, Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GridService {

  resizeEvent = new EventEmitter();
  itemSizeChangeEvent = new EventEmitter<number>();
  gridTypeChangeEvent = new EventEmitter<string>();
  goToLastPageEvent = new EventEmitter();
  releaseCardEvent = new EventEmitter();

  constructor(private storageService: StorageService) {}

  resizeGrid() {
    this.resizeEvent.emit();
  }

  setItemSize(size: number) {
    this.itemSizeChangeEvent.emit(size);
  }

  dispatchGridTypeChangeEvent(type: string) {
    this.gridTypeChangeEvent.emit(type);
  }

  goToLastPage() {
    this.goToLastPageEvent.emit();
  }

  releaseCard() {
    this.releaseCardEvent.emit();
  }

  async saveCardSizeChangedByUser(size: any) {
    let key = 'grid-card-size';

    if (location.pathname.includes('aac')) {
      key += '-aac';
    } else if (location.pathname.includes('activities')) {
      key += '-activities';
    }

    this.storageService.set(key, size.toString());
  }

  getCardSizeSetdByUser(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let key = 'grid-card-size';

      if (location.pathname.includes('aac')) {
        key += '-aac';
      } else if (location.pathname.includes('activities')) {
        key += '-activities';
      }

      const cardSize = await this.storageService.get(key);
      if (typeof cardSize !== undefined && cardSize !== null) {
        resolve(Number(cardSize));
      } else {
        resolve(115);
      }
    });
  }
}
