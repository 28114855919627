import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { StorageService } from '../storage/storage.service';
import { Capacitor } from '@capacitor/core';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class NativeStorageService {

  constructor(public app: Globals, public storage: StorageService, private file: File) { }

  resolveMedia(mediaName: string) {
    if (typeof mediaName === 'undefined' || mediaName === '') { return ''; }

    return this.getFileSource(this.file.dataDirectory + 'medias/' + mediaName);
  }

  resolveTextToSpeechAudioSrc(audioName: string) {
    return this.getFileSource(this.file.dataDirectory + 'text-to-speech-audios/' + audioName);
  }

  listNativeMedias(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.dataDirectory, 'medias');
      } catch (err) {
        try {
          await this.file.createDir(this.file.dataDirectory, 'medias', false);
        } catch (err) {
          reject(err);
          return;
        }
      }

      this.file.listDir(this.file.dataDirectory, 'medias').then(files => {
        resolve(files.map(file => file.name));
      }).catch(err => {
        reject(err);
      });
    });
  }

  async saveFile(path: string, fileName: string, blob: Blob): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.file.checkDir(this.file.dataDirectory, path);
      } catch (err) {
        try {
          await this.file.createDir(this.file.dataDirectory, path, false);
        } catch (err) {
          reject(err);
          return;
        }
      }

      const filePath = this.file.dataDirectory + path;
      try {
        this.file.writeFile(filePath, fileName, blob)
          .then(() => resolve(this.getFileSource(this.file.dataDirectory + path + '/' + fileName)))
          .catch(err => reject(err));
      } catch (err) {
        reject(err);
      }
    });
  }

  private getFileSource(filePath: string) {
    return Capacitor.convertFileSrc(filePath);
  }
}
