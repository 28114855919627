import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Globals } from 'src/app/globals/globals';
import { HttpClient } from '@angular/common/http';
import { EditorToolsService } from '../editor-tools.service';
import { ImageCropInfo } from '../models/image-crop-info.model';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from 'src/app/language/language.service';

@Component({
  selector: 'app-image-crop-v2',
  templateUrl: './image-crop-v2.component.html',
  styleUrls: ['./image-crop-v2.component.scss'],
})
export class ImageCropV2Component implements OnInit {

  @Input() imgSrc: string;
  @Input() imgType: string;
  @Input() imgSearchModal: any;

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('img') imgElm: ElementRef;
  @ViewChild('ionContent', { read: ElementRef }) ionContentElmRef: ElementRef;

  cx: CanvasRenderingContext2D;

  theme = 'aac';
  imageUrlCorsFixed = '';
  imageLoaded = false;
  imageCropInfo: ImageCropInfo = new ImageCropInfo();
  canFallbackToLoadImageFromApi = true;

  constructor(public editorToolsService: EditorToolsService, private navCtrl: NavController, private sanitizer: DomSanitizer,
              private app: Globals, private http: HttpClient, private alertController: AlertController,
              public lang: LanguageService, private modalController: ModalController) { }

  ngOnInit() {
    if (window.location.pathname.includes('activities')) { this.theme = 'activities'; }
  }

 onImgLoaded(ev: any) {
    // console.log(this.imgElm);
    this.imageLoaded = true;
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    const contentWidth = this.ionContentElmRef.nativeElement.clientWidth;
    const contentHeight = this.ionContentElmRef.nativeElement.clientHeight;

    /** Make sure the image will fit in the content area by limiting its width and height
     * with the minimum screen dimension. In addition, we removes 10px from it since it's the
     * padding of image cropper (5px top and bottom e 5 px left and right)
     */
    if (contentWidth > contentHeight) {
      this.imgElm.nativeElement.style.maxWidth = `${contentHeight - 10}px`;
      this.imgElm.nativeElement.style.maxHeight = `${contentHeight - 10}px`;
    } else {
      this.imgElm.nativeElement.style.maxWidth = `${contentWidth - 10}px`;
      this.imgElm.nativeElement.style.maxHeight = `${contentWidth - 10}px`;
    }

    const imgWidth = this.imgElm.nativeElement.clientWidth;
    const imgHeight = this.imgElm.nativeElement.clientHeight;

    const canvasWidth = imgWidth >= imgHeight ? imgWidth : imgHeight;
    const canvasHeight = canvasWidth;

    canvasEl.width = canvasWidth;
    canvasEl.height = canvasHeight;

    //console.log(canvasWidth)
    //console.log(canvasHeight)

    this.imageUrlCorsFixed = canvasEl.toDataURL();

    //console.log(this.imageUrlCorsFixed);

    this.imageCropInfo.imagePreviewVerticalPadding = canvasHeight - imgHeight;
    this.imageCropInfo.imagePreviewHorizontalPadding = canvasWidth - imgWidth;
    this.imageCropInfo.canvasWidth = canvasWidth;
    this.imageCropInfo.canvasHeight = canvasHeight;

    // Workaround: The very first time the image is loaded from device, we may end up with a canvas that is 0 by 0.
    // In such a case, we load the image by clearing and setting its source again.
    if (canvasWidth === 0 && canvasHeight === 0) {
      const imgSrc = this.imgSrc;
      this.imgSrc = '';
      setTimeout(() => {
        this.imgSrc = imgSrc;
      });
    }
 }

  onImgError(ev: any) {
    //console.log(ev);
    if (this.imgSrc.includes('lookaside.fbsbx.com')) {
      this.http.get(`${this.app.searchEndpoint}/resolve_image_source?url=${this.imgSrc}`, {responseType: 'text'}).subscribe(
        urlResolved => {
          //console.log(urlResolved);
          this.imgSrc = urlResolved;
        },
        err => {
          //console.log(err);
          this.showImageErrorAlert();
        }
      );
    } else if (this.canFallbackToLoadImageFromApi) {
      this.canFallbackToLoadImageFromApi = false;
      this.imgSrc = `https://simplix-life-search.cloudno.de/v1/image_search_v3/image?url=${encodeURIComponent(this.imgSrc)}`;
    } else if (this.imgSrc.includes('data:image')) {
      // When adding the image from device, we get an image load error even though the image src is proper set with
      // image data url in base 64. In this case, we consider that the image will load and we don't show error.
    } else {
      this.showImageErrorAlert();
    }
  }

  onBackBtnClicked() {
    this.modalController.dismiss();
  }

  onImageCropped(e: ImageCroppedEvent) {
    this.imageCropInfo.imageCroppedEvent = e;
    this.imageCropInfo.imagePreviewWidth = this.imgElm.nativeElement.clientWidth;
    this.imageCropInfo.imagePreviewHeight = this.imgElm.nativeElement.clientHeight;
    this.imageCropInfo.imageUrl = this.imgSrc;
    this.imageCropInfo.imageType = this.imgType;
  }

  onConfirmCropBtnClicked() {
    this.modalController.dismiss(this.imageCropInfo);

    if (this.imgSearchModal) {
      this.imgSearchModal.animated = false;
      this.imgSearchModal.dismiss(this.imageCropInfo);
    }
  }

  async showImageErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'app-standard-alert',
      message: this.lang.words.alerts.imageError.message,
      buttons: [this.lang.words.alerts.imageError.button]
    });
    await alert.present();
    await alert.onWillDismiss();

    // Goes back to image search page
    this.navCtrl.pop();
  }
}
