import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { ExpressiaAnalyticsService } from './expressia-analytics/expressia-analytics.service';
import packageInfo  from 'package.json';
import { UserDataApiService } from '../user/user-data/user-data-api/user-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private expressiaAnalytics: ExpressiaAnalyticsService, private app: Globals, private userDataApi: UserDataApiService) {}

  /**
   * Logs an analytics event
   * @param eventName Event's name
   * @param eventParams Event's params
   * @param paramName Param name that should be consolidaded as another event
   * @returns Promise <void>
   */
  async logEvent(eventName: string , eventParams?: any, paramName?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.expressiaAnalytics.logEvent(eventName, eventParams, paramName);
      resolve();
    })
  }

  /**
   * Handles when the authentication is initiated.
   */
  onAuthStateInitiated() {
    this.expressiaAnalytics.onAuthStateInitiated();
  }

  /**
   * Refreshes app info and geolocation.
   */
  refreshAppInfoAndGeolocation() {
    this.logEvent('app_info', { plataform: this.app.getPlatform(), version: packageInfo.version });
    this.refreshUserGeolocation();
  }

  /**
   * Refreshes the user geolocation.
   */
  private async refreshUserGeolocation() {
    this.userDataApi.findUserGeolocation().then(geolocation => {
      this.logEvent('user_geolocation', { geolocation });
    }).catch(err => console.log(err));
  }
}
