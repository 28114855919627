import {
  Component, OnInit, ElementRef, ViewChild, HostListener, Input, Output,
  ChangeDetectorRef, ViewChildren, QueryList, OnChanges, AfterViewChecked, OnDestroy, AfterViewInit
} from '@angular/core';
import { Card } from './card/card.model';
import { EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AacBoard } from '../aac/aac-board/aac-board.model';
import { IonContent } from '@ionic/angular';
import { Globals } from '../globals/globals';
import { CardDragEvent, CardSelectEvent } from './models/card-select-event.model';
import { GridService } from './grid.service';
import { MediaStorageService } from '../media-storage/media-storage.service';
import { Subscription } from 'rxjs';
import { TextToSpeechService } from '../tools/text-to-speech/text-to-speech.service';
import { NotificationService } from '../notification/notification.service';

import * as TweenLite from 'src/assets/scripts/TweenMax.min.js';
import { GridSettings } from './grid-settings/grid-settings.model';
import { AudioPlayerService } from '../utils/audio-player/audio-player.service';
import { LanguageService } from '../language/language.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('gridContainer') gridContainer: ElementRef;
  @ViewChild('audio', { read: ElementRef }) audioElementRef: ElementRef<HTMLAudioElement>;
  @ViewChildren('cmp', { read: ElementRef }) components: QueryList<ElementRef>;
  @ViewChild('floatingCardElm', { read: ElementRef }) floatingCardElmRef: ElementRef;

  // Número de linhas do grid
  @Input() rows = 2;

  // Número de colunas do grid
  @Input() cols = 2;

  // Tamanho do cartão. Se for zero, o tamanho do cartão será calculado
  // com base no número de linhas e colunas do grid
  @Input() cardSize = 0;

  // Cartões do grid
  @Input() cards = [];

  // Uso com o TiX. Neste caso são exibidos os símbolos do TiX
  @Input() tixMode = false;

  // Endereço no qual as imagens dos cartões são referenciadas
  @Input() imageStorageLocation = '';

  // Endereço no qual os áudios dos cartões são referenciadas
  @Input() audioStorageLocation = '';

  // Anima o cartão ao ser selecionado
  @Input() animated = false;

  // Cartões do grid editáveis
  @Input() editable = false;

  @Input() movable = false;

  // Cartões do grid podem ser escondidos (não são mostrados na tela)
  @Input() removable = false;

  // Cartões do grid reordenáveis
  @Input() orderable = false;

  // Tamanho dos cartões do grid configuráveis
  @Input() resizable = true;

  // Cartões do grid podem ser movidos
  @Input() draggable = false;

  // Uso do grid com paginação
  @Input() useGridPaginated = false;

  // Uso do grid com varredura
  @Input() scanMode = false;

  @Input() ionContentElm: IonContent;

  @Input() divContentElm: HTMLDivElement;

  @Input() parentBoard;
  @Input() parentBoardId = '';

  @Input() scanPaused = false;

  @Input() limitCards = null;

  // Displays the card's status bar. For instance, for the activities this status means the shares and saves counter.
  @Input() cardsStatusBarVisible = false;

  @Input() cardButtonsLayout = 'activity';

  // Hide cards that shouldn't be visible (i.e cards with property isVisible set to false)
  @Input() hideInvisibleCards = false;

  @Input() onActivityEditor = false;

  @Input() boardsIdsWithNewTag = {};

  @Input() statusBarBadges = {};

  // Shows the grid with only one line
  @Input() singleLine = false;

  // Grid settings
  @Input() gridSettings: GridSettings = {};

  // Cartão selecionado output
  @Output() select = new EventEmitter<CardSelectEvent>();

  // Cartão selecionado para edição
  @Output() edit = new EventEmitter<any>();

  // Evento emitido quando o botão de apagar do cartão é pressionado
  @Output() delete = new EventEmitter<any>();

  // Evento emitido quando a visibilidade do cartão é alterada
  @Output() toggleVisibility = new EventEmitter<any>();

  // Evento emitido quando o cartão de voltar é selecionado no grid fixo
  @Output() back = new EventEmitter();

  // Evento emitido quando o tamanho do cartão é alterado
  @Output() cardSizeChangeByUser = new EventEmitter<number>();

  @Output() cardOrderChange = new EventEmitter<any>();

  @Output() statusBarAction = new EventEmitter<any>();

  @Output() moreBtnClick = new EventEmitter<any>();

  @Output() gridTypeChange = new EventEmitter<string>();

  @Output() drag = new EventEmitter<CardDragEvent>();

  @Output() hold = new EventEmitter<Card>();

  // Altura do Grid (Calculada pelo próprio grid)
  height = 0;

  // Largura do Grid (Calculada pelo próprio grid)
  width = 0;

  // Padding internos ao grid
  paddingTop = 0;
  paddingBottom = 0;
  paddingLeft = 0;
  paddingRight = 0;

  // Tamanho do cartão definido pelo usuário
  cardSizeSetByUser = 0;

  // Fator de espaçamento horizontal dos cartões no grid [0 a 1]
  horizontalSpacingRatio = 0.1;

  // Fator de espaçamento vertical dos cartões no grid [0 a 1]
  verticalSpacingRatio = 0.1;

  // Tamanho da imagem (Calculada pelo próprio grid)
  // Obs: As imagens são sempre quadradas por enquanto
  cardImgSize = 0;

  // Largura e altura de cada cartão (Calculada pelo próprio grid)
  @Input() cardWidth = 0;
  cardHeight = 0;

  // Altura do título do cartão (proporcional ao tamanho do cartão) [0 a 1]
  cardTitleHeightRatio = 0.3;

  // Largura do símbolo do TiX (proporcial ao tamnho do cartão) [0 a 100]
  keyImgWidth = 40;

  // Cartões que estão sendo exibidos no grid
  displayedCards = [];

  // Cartão selecionado
  cardSelected: AacBoard = new AacBoard();

  // Audio do cartão selecionado
  cardAudio: SafeUrl = '';
  hasAudio = false;

  cardsCopy = [];

  scanIndex = 0;
  scanTimer: any;
  scanStarted = false;
  scanTimerWaitSelection: any;

  pageSize = 0;
  pageNum = 0;
  gridSnapshots = [];
  cardSelectedIsCategory = false;
  isBacking = false;

  mouseDown = false;
  mouseMovementY = 0;

  cardsLengthPrev = 0;
  cardSizeSetByUserOld = 0;
  parentBoardIdOld = '';

  allowPinch = true;

  // Temporário: Estudo da animação do Flexbox
  nodes: any;
  total: number;
  boxes = [];
  boxCards = [];

  floatingCardIndex = -1;
  indexCardMouseDown = -1;

  timeoutHoldingCard: any;
  cardsOrder = [];
  cardsOrdered = [];

  skipCardSelection = false;

  ionContentScrollEvSub: Subscription;

  pinch = {
    lastDistance: 0,
    saveCardSizeTimeout: null,
    isPinching: false
  };

  /* Card that is floating to be reorderd */
  floatingCard;

  // touchStartEvent: TouchEvent;
  pointerDownEvent: PointerEvent;
  mouseMoveInitEvent: MouseEvent;
  resizeEventSub: Subscription;
  itemSizeChangeSub: Subscription;
  gridTypeChangeSub: Subscription;
  goToLastPageEventSub: Subscription;
  releaseCardEventSub: Subscription;
  targetsWithNewTagSub: Subscription;
  activitiesResultsNotifCountSub: Subscription;

  // It is calculated when the grid resizes.
  defaultMargin: number = 0;

  // Margins for the cards when TiX mode is enabled
  tixCardsMargins: Array<{  left: number, right: number, bottom: number, top: number}> = [
    { left: 0, right: 0, bottom: 0, top: 0 }, 
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 },
    { left: 0, right: 0, bottom: 0, top: 0 }
  ];

  constructor(private changeRef: ChangeDetectorRef, public app: Globals, private gridService: GridService,
    public ttsService: TextToSpeechService, public mediaStorageService: MediaStorageService,
    private notificationService: NotificationService, private audioPlayerService: AudioPlayerService, private lang: LanguageService) {
    this.audioStorageLocation = this.app.audioStorageLocation;
    this.imageStorageLocation = this.app.imageStorageLocation;
  }

  ngOnInit() {
    setTimeout(() => {
      this.onResize(null);
    }, 250);

    // Se o tamanho do cartão foi definido pelo usuário, usaremos o valor que ele definiu e manteremos
    // todos os cartões com esse tamanho
    this.gridService.getCardSizeSetdByUser().then(size => {
      this.cardSizeSetByUser = size;
      this.cardSize = this.cardSizeSetByUser;
    });

    if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
      this.ionContentScrollEvSub = this.ionContentElm.ionScroll.subscribe(() => {
        clearTimeout(this.timeoutHoldingCard);
      });
    }

    setTimeout(() => {
      this.targetsWithNewTagSub = this.notificationService.targetsWithNewTagObs.subscribe(targetsWithNewTag => {
        this.boardsIdsWithNewTag = targetsWithNewTag;
      });

      this.activitiesResultsNotifCountSub = this.notificationService.activitiesResultsNotifCountObs.subscribe(activitiesResultsNotifCount => {
        this.statusBarBadges = activitiesResultsNotifCount;
        // console.log(this.statusBarBadges);
      });
    });

    // Listens to resize events generated by grid service.
    this.resizeEventSub = this.gridService.resizeEvent.subscribe(() => {
      this.onResize(null);
      this.refreshGridCards();
    });

    // Listens to item size changes (emitted when the size of the card changes).
    this.itemSizeChangeSub = this.gridService.itemSizeChangeEvent.subscribe((size) => {
      this.cardSizeSetByUser = size;
      this.cardSize = this.cardSizeSetByUser;
      this.saveCardSize();
    });

    // Listens to grid type changes.
    this.gridTypeChangeSub = this.gridService.gridTypeChangeEvent.subscribe((type) => {
      this.gridTypeChange.emit(type);
    });

    // Listens to go to last page event.
    this.goToLastPageEventSub = this.gridService.goToLastPageEvent.subscribe(() => {
      this.onGoToLastPage();
    });

    // Listens to release card event
    this.releaseCardEventSub = this.gridService.releaseCardEvent.subscribe(() => {
      this.releaseCard();
    });
  }

  ngOnDestroy(): void {
    this.scanStarted = false;
    this.stopAudio();
    // console.log(this.scanTimer);
    // console.log(this.scanTimerWaitSelection);
    clearTimeout(this.scanTimer);
    clearTimeout(this.scanTimerWaitSelection);
    if (this.ionContentScrollEvSub) { this.ionContentScrollEvSub.unsubscribe(); }
    this.resizeEventSub.unsubscribe();
    this.itemSizeChangeSub.unsubscribe();
    this.gridTypeChangeSub.unsubscribe();
    this.goToLastPageEventSub.unsubscribe();
    this.releaseCardEventSub.unsubscribe();
    this.targetsWithNewTagSub?.unsubscribe();
    this.activitiesResultsNotifCountSub?.unsubscribe();
  }

  ngAfterViewInit(): void { }

  ngOnChanges(changes: any): void {
    // console.log('ngOnChanges grid');
    // console.log(changes);

    // TEMPORÁRIO: A quantidade de cartões mostrados no modo TiX é limitado a 9 em qualquer situação
    if (this.tixMode && this.cards.length > 9) {
      this.cards = this.cards.slice(0, 9);
    }

    if (changes.useGridPaginated) {
      if (this.useGridPaginated) {
        if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
          this.ionContentElm.scrollY = false;
        }
      } else {
        if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
          this.ionContentElm.scrollY = true;
        }
      }
    }

    if (changes.cards) {
      this.refreshGridCards();
    }
  }

  ngAfterViewChecked(): void {
    // Focus the grid if scanning is active or if the app is being used with TiX.
    // This ensures that keyboard input will be captured.
    if (this.scanMode || this.tixMode) {
      this.gridContainer.nativeElement.focus();
    }

    const cardSizeChanged = this.cardSize > 0 && !this.useGridPaginated && !this.tixMode && this.cardSize !== this.cardImgSize ? true : false;
    const widthChanged = this.width !== this.gridContainer.nativeElement.offsetWidth ? true : false;
    const heightChanged = this.height !== this.gridContainer.nativeElement.offsetHeight ? true : false;
    const cardsLengthChanged = this.cards.length !== this.cardsLengthPrev ? true : false;
    const cardSizeSetByUserChanged = this.cardSizeSetByUser !== this.cardSizeSetByUserOld ? true : false;
    const parentBoardChanged = this.parentBoardId !== this.parentBoardIdOld ? true : false;

    if (cardsLengthChanged) {
      this.setupCardsReorderAni();

      this.cardsOrder = [];
      for (let i = 0; i < this.cards.length; i++) {
        this.cardsOrder.push(i);
      }

      this.cardsOrdered = JSON.parse(JSON.stringify(this.cards));
    }

    if (parentBoardChanged) {
 
      this.cardsOrder = [];
      for (let i = 0; i < this.cards.length; i++) {
        this.cardsOrder.push(i);
      }

      this.cardsOrdered = JSON.parse(JSON.stringify(this.cards));
    }

    if ((cardsLengthChanged || cardSizeChanged || widthChanged || heightChanged || cardSizeSetByUserChanged || parentBoardChanged)) {
      setTimeout(() => {
        this.onResize(null);
        console.log('cardsLengthChanged:', cardsLengthChanged);
        console.log('cardSizeChanged:', cardSizeChanged);
        console.log('widthChanged:', widthChanged);
        console.log('heightChanged:', heightChanged);
        console.log('cardSizeSetByUserChanged:', cardSizeSetByUserChanged);
        console.log('parentBoardChanged:', parentBoardChanged);
        this.setupCardsReorderAni();
      }, 1);
    }

    // Salva a última quantidade de cartões no grid para a referência para a
    // próxima comparação
    this.cardsLengthPrev = this.cards.length;

    this.cardSizeSetByUserOld = this.cardSizeSetByUser;
    this.parentBoardIdOld = this.parentBoardId;

    this.refreshScan();
  }

  refreshGridCards() {
    if (this.useGridPaginated) {
      this.gridPaginatorRefresh();
    } else {
      this.displayedCards = this.cards;
    }
  }

  setupCardsReorderAni() {
    this.nodes = document.querySelectorAll('.grid-card');
    this.total = this.nodes.length;

    this.boxes = [];

    for (let i = 0; i < this.total; i++) {
      const node = this.nodes[i];

      // Initialize transforms on node
      TweenLite.set(node, { x: 0 });

      this.boxes[i] = {
        transform: node._gsTransform,
        x: node.offsetLeft,
        y: node.offsetTop,
        node
      };
    }
  }

  // Calcula o tamanho do cartão automaticamente para o melhor preenchimento do grid.
  // A premissa é que será tentado colocar todos cartões da prancha dentro da área do grid
  // Se não for possível, será respeitado o tamanho mínimo e aparecerá a barra de scroll
  // para percorrer os demais itens da prancha
  calcCardSizeForBestFit() {
    const cardSizeMin = 100; // TODO: Começar com o tamanho mínimo do cartão
    const cardSizeMax = 2000;
    const cardsCount = this.cards.length;
    let cardSize = cardSizeMin;

    // Ajuste rápido (grosso)
    for (let size = cardSizeMin; size < cardSizeMax; size += 10) {
      const cardWidth = size;
      const cardHeight = cardWidth + this.cardTitleHeightRatio * cardWidth + 10; // +10 = Margin Bottom
      const cardsPerRow = Math.floor(this.width / cardWidth);
      const rows = Math.ceil(cardsCount / cardsPerRow);

      // Padding-top do grid screen area => 10px
      if (rows * cardHeight > (this.height - 10)) {
        cardSize = size;
        break;
      }
    }

    // Ajuste fino
    for (let size = cardSize; size > cardSizeMin; size -= 1) {
      const cardWidth = size;
      const cardHeight = cardWidth + this.cardTitleHeightRatio * cardWidth + 10; // +10 = Margin Bottom
      const cardsPerRow = Math.floor(this.width / cardWidth);
      const rows = Math.ceil(cardsCount / cardsPerRow);

      // Padding-top do grid screen area => 10px
      if (rows * cardHeight < (this.height - 10)) {
        cardSize = size;
        break;
      }
    }

    return cardSize;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateGridSize();
  }

  onMouseDown(event) {
    this.mouseDown = true;
  }

  onMouseUp(ev: any) {
    this.releaseCard();
  }

  onMouseMove(event: MouseEvent) {

    // Updates the floating card position if some card is being reordered
    if (this.floatingCard) {
      this.setFloatingCardPosition(event);
    }

    // // Eventuais eventos de movimento do mouse quando rodando em dispositivos móveis são ignorados
    // if (this.app.isRunningOnMobile()) {
    //   return;
    // }

    if (this.mouseDown && this.floatingCardIndex === -1) {
      if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {

        // Skips the card selection if the user is intended to scroll the grid (in this case, the grid moviment in y direction
        // will be greater than 5).
        this.mouseMovementY += event.movementY;
        if (Math.abs(this.mouseMovementY) >= 10) {
          this.skipCardSelection = true;
        }

        this.ionContentElm.getScrollElement().then((el) => {
          if (event.movementY !== 0) {
            el.scrollTop += -event.movementY;
          }
        });
      }

      if (typeof this.divContentElm !== 'undefined' && this.divContentElm) {
        this.divContentElm.scrollTop += -event.movementY;
      }
    } else {
      this.mouseMovementY = 0;
    }
  }

  onMousewheel(event: any) {
    if (this.useGridPaginated) {
      event.preventDefault();

      if (event.wheelDelta > 0) {
        this.nextPage();
      } else if (event.wheelDelta < 0) {
        this.prevPage();
      }

    } else if (this.resizable) {
      event.preventDefault();

      if (event.wheelDelta > 0) {
        if (this.cardSizeSetByUser < this.getCardMaxSize()) {
          this.cardSizeSetByUser += 10;
        }
      }
      if (event.wheelDelta < 0) {
        if (this.cardSizeSetByUser > this.getCardMinSize()) {
          this.cardSizeSetByUser -= 10;
        }
      }
      this.saveCardSize();
    }
  }

  onCardMouseDown(ev: any, index: number, card: any) {
    if (this.draggable && card?.isDraggable) {
      this.holdCard(index, card, ev);
    } else if (this.orderable && !this.pinch.isPinching) {
      this.timeoutHoldingCard = setTimeout(() => {
        this.holdCard(index, card, ev);
      }, 1000);
    }
  }

  onCardTouchStart(ev: TouchEvent, index: number, card: any) {
    if (ev.touches.length > 1) {
      clearTimeout(this.timeoutHoldingCard);
      return;
    }

    this.onCardMouseDown(ev, index, card);
  }

  onCardMouseUp(ev: any, index: number) {
    this.releaseCard();
  }

  onCardMouseMove(ev: any, index: number) {
    // // Eventuais eventos de movimento do mouse quando rodando em dispositivos móveis são ignorados.
    // if (this.app.isRunningOnMobile()) {
    //   return;
    // }

    clearTimeout(this.timeoutHoldingCard);
  }

  onGridTouchMove(ev: TouchEvent) {
    if (ev.touches.length > 1) {
      clearTimeout(this.timeoutHoldingCard);
    }

    if (this.floatingCard) {
      this.setFloatingCardPosition(ev);
    }

    this.pinchZoomRefresh(ev);
    this.cardsReorderByTouchRefresh(ev);
  }

  cardsReorderByTouchRefresh(ev: TouchEvent) {
    if ((this.orderable || this.draggable) && this.floatingCardIndex >= 0) {
      const elm = document.elementFromPoint(ev.touches[0].clientX, ev.touches[0].clientY);
      if ((typeof elm !== 'undefined' && elm) && (elm.id.includes('reorder-spot'))) {
        const reorderSpotNum = elm.id.substr(13);
        const index = parseInt(reorderSpotNum, 10);
        this.onGridReorderArea(index);
      }
    }
  }

  pinchZoomRefresh(ev: TouchEvent) {
    if (this.orderable && ev.touches.length === 2) {
      this.pinch.isPinching = true;

      const x0 = ev.touches[0].clientX;
      const y0 = ev.touches[0].clientY;
      const x1 = ev.touches[1].clientX;
      const y1 = ev.touches[1].clientY;

      const d = Math.sqrt((x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0));

      const delta = d - this.pinch.lastDistance;

      if (delta > 1) {
        const step = delta > 10 ? 10 : delta;

        if (this.cardSizeSetByUser < this.getCardMaxSize()) {
          this.cardSizeSetByUser += step;
        }
        clearTimeout(this.pinch.saveCardSizeTimeout);
        this.pinch.saveCardSizeTimeout = setTimeout(() => this.saveCardSize(), 1000);
      } else if (delta < -1) {
        const step = delta < -10 ? -10 : delta;

        if (this.cardSizeSetByUser > this.getCardMinSize()) {
          this.cardSizeSetByUser += step;
        }
        clearTimeout(this.pinch.saveCardSizeTimeout);
        this.pinch.saveCardSizeTimeout = setTimeout(() => this.saveCardSize(), 1000);
      }
      this.pinch.lastDistance = d;
    }
  }

  saveCardSize() {
    this.gridSettings.itemSize = this.cardSizeSetByUser;
    this.gridService.saveCardSizeChangedByUser(this.cardSizeSetByUser);
  }

  onGridTouchCancel(ev: any) {
    this.pinch.isPinching = false;
  }

  onGridPointerDown(ev: PointerEvent) {
    this.pointerDownEvent = ev;

    if (this.scanMode) {
      this.onInput();
    }
  }

  onGridPointerUp(ev: PointerEvent) {
    if (this.scanMode) {
      // Pointer up does nothing when the scan mode is enabled.
    } else {
      this.pinch.isPinching = false;

      const elements = document.elementsFromPoint(ev.clientX, ev.clientY);
  
      const cardButton = elements.find(element => element.tagName === 'ION-BUTTON');
      const card = elements.find(element => element.tagName === 'APP-CARD');
  
      if ((!cardButton && card) || cardButton && cardButton.classList.contains('play-button')) {
        const deltaX = Math.abs(ev.clientX - this.pointerDownEvent.clientX);
        const deltaY = Math.abs(ev.clientY - this.pointerDownEvent.clientY);
  
        if (deltaX <= 30 && deltaY <= 30) {
          const cardIndex = this.displayedCards.findIndex(c => c._id === card.id);
          this.onCardSelected(this.displayedCards[cardIndex], cardIndex);
        }
      }
    }
  }

  holdCard(index: number, card: any, ev: any) {
    this.floatingCardIndex = this.cardsOrdered.findIndex(obj => obj._id === card._id);
    this.indexCardMouseDown = index;

    // Card that is being hold
    this.floatingCard = card;

    // Hides the card at its current position on grid.
    const appCardElem = this.components.toArray()[index].nativeElement;
    const cardElem = appCardElem.children[0];
    cardElem.style.opacity = 0;

    // The card itself will be floating while its being hold.
    setTimeout(() => {
      this.setFloatingCardPosition(ev);
      if (this.floatingCardElmRef) {
        this.floatingCardElmRef.nativeElement.style.opacity = 1;
      }
    });

    // Desativa a rolagem dos cartões
    if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
      this.ionContentElm.scrollY = false;
    }

    // Emit an event telling the card that is being holded.
    this.hold.emit(card);
  }

  releaseCard() {
    this.floatingCard = undefined;
    this.mouseDown = false;

    if (this.floatingCardIndex !== -1) {
      const newCardsOrder = [];
      for (let i = 0; i < this.cardsOrdered.length; i++) {
        if (this.cardsOrdered[this.cardsOrder[i]]._id.includes('special-card')) {
          // Special cards are not part of the board's cards, and therefore are not included in the new cards order.
        } else {
          newCardsOrder.push(this.cardsOrdered[this.cardsOrder[i]]._id);
        }
      }

      this.cardOrderChange.emit(newCardsOrder);
    }

    this.floatingCardIndex = -1;
    clearTimeout(this.timeoutHoldingCard);

    if (this.indexCardMouseDown !== -1) {
      const appCardElem = this.components.toArray()[this.indexCardMouseDown].nativeElement;
      const cardElem = appCardElem.children[0];
      // cardElem.classList.remove('app-scan-card-active');
      cardElem.style.opacity = 1;
      this.indexCardMouseDown = -1;
    }

    // Habilita rolagem dos cartões
    if (!this.useGridPaginated && typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
      this.ionContentElm.scrollY = true;
    }

    /**
     * Fix a bug that ignored the next click for selecting a card after we drag the grid.
     * The timeout here is important to make sure that it will be executed after we finish the
     * events that take place as soon as the grid is released.
     */
    setTimeout(() => this.skipCardSelection = false);
  }

  /**
   * Sets the position of the floating card, which is place centrally on mouse cursor or touch point.
   * @param ev Mouse or touch event
   */
  setFloatingCardPosition(ev: any) {
    // Gets the rectangle that envolves the grid container. It's used to get its top offset related to the page and therefore
    // to allow centering de card on cursor/touch position
    const gridRect = this.gridContainer.nativeElement.getBoundingClientRect();

    // Grid offset top related to parent container
    const gridOffsetTop = this.gridContainer.nativeElement.offsetTop;
    const gridClientOffsetTop = gridRect.top;
    const gridClientOffsetLeft = gridRect.left;

    // Cursor / touch coordinates related to the grid container
    let clientX = 0;
    let clientY = 0;

    if (ev instanceof TouchEvent) {
      clientX = ev.touches[0].clientX;
      clientY = ev.touches[0].clientY;
    } else {
      clientX = ev.clientX;
      clientY = ev.clientY;
    }

    // Centers the floating card on cursor/touch position
    if (this.floatingCardElmRef && !this.floatingCard.img && !this.floatingCard.isActivity) {
      this.floatingCardElmRef.nativeElement.style.left = `${clientX - gridClientOffsetLeft - this.cardWidth / 2}px`;
      this.floatingCardElmRef.nativeElement.style.top = `${clientY - gridClientOffsetTop + gridOffsetTop - (this.cardTitleHeightRatio * this.cardImgSize) / 2}px`;
    } else if (this.floatingCardElmRef) {
      this.floatingCardElmRef.nativeElement.style.left = `${clientX - gridClientOffsetLeft - this.cardWidth / 2}px`;
      this.floatingCardElmRef.nativeElement.style.top = `${clientY - gridClientOffsetTop + gridOffsetTop - this.cardHeight / 2}px`;
    } 
  }

  getCardMaxSize() {
    if (this.width >= this.height) {
      this.cardHeight = this.cardImgSize + this.cardTitleHeightRatio * this.cardImgSize;
      const maxSize = this.height / (1 + this.cardTitleHeightRatio);
      return maxSize;
    } else {
      return this.width;
    }
  }

  getCardMinSize() {
    // O tamanho mínimo da cartão é considerado como 20% da menor dimensão da área de visualização do grid
    const minViewDimension = this.width > this.height ? this.height : this.width;
    return 0.2 * minViewDimension;
  }

  gridPaginatorRefresh() {
    if (this.useGridPaginated) {
      // if (JSON.stringify(this.cards) !== JSON.stringify(this.cardsCopy)) {
      //   this.paginateGrid();
      // }
      this.cardsCopy = [...this.cards];
      this.paginateGrid();
    }
  }

  refreshScan() {
    if (this.scanMode && !this.scanStarted && !this.scanPaused && this.app.user.preferences.scanInterval) {
      // Número de cartões visíveis no grid
      const cardsCount = this.components.toArray().length;

      // Só inicia a varredura após os cartões já terem sido criados
      if (cardsCount > 0) {

        if (this.scanIndex < this.components.toArray().length) {
          const appCardElem = this.components.toArray()[this.scanIndex].nativeElement;
          appCardElem.classList.remove('app-opaque');
          const cardElmSelected = this.components.toArray()[this.scanIndex].nativeElement.children[0];
          cardElmSelected.classList.remove('app-scan-card-active');
        }
        this.startScan();
      }
    }
  }

  paginateGrid() {
    // // Se o array de cartões estiver vazio, não temos o que paginar e só adicionamos o cartão de voltar
    // if (this.cards.length === 0) {
    //   const cardBack = new Card();
    //   cardBack._id = 'back';
    //   cardBack.title = 'Voltar';
    //   cardBack.img = 'prev-page-icon.png';
    //   cardBack.audio = '';
    //   this.cards.splice(0, 0, cardBack);
    //   this.pageNum = 0;
    //   this.displayedCards = this.cards;
    //   return;
    // }

    // console.log('PAGINATE GRID');
      
    // this.pageSize = this.cards.length;

    const cols = this.getGridCols();
    const rows = this.getGridRows();

    this.pageSize = rows * cols;
    // this.pageNum = 0;

    if (this.isBacking) {
      const gridSnapshot = this.gridSnapshots.pop();
      // this.cards = gridSnapshot.cards;
      this.pageNum = gridSnapshot.pageNum;
      this.isBacking = false;
    } else if (this.cardSelectedIsCategory) {
      // const cardBack = new Card();
      // cardBack._id = 'back';
      // cardBack.title = 'Voltar';
      // cardBack.img = 'prev-page-icon.png';
      // cardBack.audio = '';
      // this.cards.splice(0, 0, cardBack);
      this.cardSelectedIsCategory = false;
      this.pageNum = 0;
    }

    if (this.gridSettings.navegationBtwPages === 'prev-next-buttons') {
      if (this.parentBoard.title !== 'root') {
        const cardBack = new Card();
        cardBack._id = 'special-card-back-' + this.parentBoard._id;
        cardBack.title = this.lang.words.common.back.toLocaleUpperCase();
        cardBack.img = 'prev-page-icon.png';
        cardBack.isOrderable = false;
        cardBack.isEditable = false;
        cardBack.isRemovable = false;
        this.cardsCopy.splice(0, 0, cardBack);
      }
  
      for (let i = (this.pageSize - 1); i < (this.cardsCopy.length - 1); i += this.pageSize) {
        const cardNext = new Card();
        const cardPrev = new Card();
        cardNext._id = 'special-card-next-' + i;
        cardNext.title = this.lang.words.common.next.toLocaleUpperCase();
        cardNext.img = 'next-page-icon.png';
        cardNext.isOrderable = false;
        cardNext.isEditable = false;
        cardNext.isRemovable = false;
  
        cardPrev._id = 'special-card-prev-' + i;
        cardPrev.title = this.lang.words.common.previous.toLocaleUpperCase();
        cardPrev.img = 'prev-page-icon.png';
        cardPrev.isOrderable = false;
        cardPrev.isEditable = false;
        cardPrev.isRemovable = false;
  
        if (this.cardsCopy[i]._id !== 'next') {
          this.cardsCopy.splice(i, 0, cardNext);
          this.cardsCopy.splice(i + 1, 0, cardPrev);
        }
      }
    }

    if (this.gridSettings.paginateSpacingStrategy === 'space-hv-inside') {
      const horizontalSpacing = this.getGridHorizontalSpacing();
      const verticalSpacing = this.getGridVerticalSpacing();

      for (let i = 0; i < this.cardsCopy.length; i++) {
        const card = this.cardsCopy[i];
        if ((i + 1) % this.gridSettings.cols === 1) {
          card.marginLeft = 10;
        } else {
          card.marginLeft = horizontalSpacing / 2;
        }
  
        if ((i + 1) % this.gridSettings.cols === 0) {
          card.marginRight = 10;
        } else {
          card.marginRight = horizontalSpacing / 2;
        }
  
        if (Math.floor(i / this.gridSettings.cols) === 0) {
          card.marginTop = 10;
        } else {
          card.marginTop = verticalSpacing / 2;
        }
  
        if ((Math.floor(i / this.gridSettings.cols) === (this.gridSettings.rows - 1))) {
          card.marginBottom = 10;
        } else {
          card.marginBottom = verticalSpacing / 2;
        }
      }
    } else {
      const horizontalSpacing = this.getGridHorizontalSpacing();
      const verticalSpacing = horizontalSpacing;

      for (let i = 0; i < this.cardsCopy.length; i++) {
        const card = this.cardsCopy[i];
        card.marginLeft = horizontalSpacing / 2;
        card.marginRight = horizontalSpacing / 2;
        card.marginTop = verticalSpacing / 2;
        card.marginBottom = verticalSpacing / 2;
      }
    }

    /*

    */

    // this.cardsCopy.forEach(card => {
    //   card.marginLeft = '10px',
    //   card.marginRight = '10px',
    //   card.marginTop = '10px',
    //   card.marginBottom = '10px'
    // });

    // Checks if the page num is within the limit. In some cases, when the grid rows and cols changes
    // and the all items fits in the previous page, the currently page num should be ajusted.
    this.checkPageNumLimit();

    const startIndex = this.pageNum * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedCards = this.cardsCopy.slice(startIndex, endIndex);

    setTimeout(() => {
      document.querySelectorAll<any>('.grid-card').forEach(item =>  item.style.order = 'unset');

      this.setupCardsReorderAni();

      this.cardsOrder = [];
      for (let i = 0; i < this.cardsCopy.length; i++) {
        this.cardsOrder.push(i);
      }
  
      this.cardsOrdered = JSON.parse(JSON.stringify(this.cardsCopy));
    }, 1);
  }

  /**
   * Goes to next page when the grid is paginated.
   */
  nextPage() {
    if (this.pageNum < Math.ceil(this.cardsCopy.length / this.pageSize) - 1) {
      this.pageNum++;
      const startIndex = this.pageNum * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.displayedCards = this.cardsCopy.slice(startIndex, endIndex);

      setTimeout(() => {
        this.setupCardsReorderAni();
      }, 1);
    }
  }

  /**
   * Goes to previous page when the grid is paginated.
   */
  prevPage() {
    if (this.pageNum > 0) {
      this.pageNum--;
      const startIndex = this.pageNum * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.displayedCards = this.cardsCopy.slice(startIndex, endIndex);

      setTimeout(() => {
        this.setupCardsReorderAni();
      }, 1);
    }
  }

  /**
   * Handles the go to last page event is emitted
   */
  onGoToLastPage() {
    this.pageNum = Math.ceil(this.cardsCopy.length / this.pageSize) - 1;
    const startIndex = this.pageNum * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedCards = this.cardsCopy.slice(startIndex, endIndex);

    setTimeout(() => {
      this.setupCardsReorderAni();
    }, 1);
  }

  /**
   * Checks if the page num is within the limit. If it's no the case, the page num
   * will set to the last page.
   */
  checkPageNumLimit() {
    const lastPage = Math.ceil(this.cardsCopy.length / this.pageSize) - 1;
    if (this.pageNum > lastPage) {
      this.pageNum = lastPage;
    }
  }

  getGridHorizontalSpacing() {
    let horizontalSpacing = this.gridSettings?.horizontalSpacing || 10;
    return horizontalSpacing;
    
    // if (this.gridSettings.paginateSpacingStrategy === 'space-hv-inside') {
    //   return horizontalSpacing;
    // } else {
    //   const totalHorizontalSpacing = horizontalSpacing * (this.getGridCols()) + 20;
    //   const screenMinSize = Math.min(this.width, this.height);
    //   if (totalHorizontalSpacing > screenMinSize) {
    //     horizontalSpacing = screenMinSize / (this.getGridCols() + 20);
    //   }
    //   return horizontalSpacing;
    // }
  }

  getGridVerticalSpacing() {
    let verticalSpacing = this.gridSettings?.verticalSpacing || 10;
    return verticalSpacing;
    
    // if (this.gridSettings.paginateSpacingStrategy === 'space-hv-inside') {
    //   return verticalSpacing;
    // } else {
    //   const totalVerticalSpacing = verticalSpacing * (this.getGridRows()) + 10;
    //   const screenMinSize = Math.min(this.width, this.height);
    //   if (totalVerticalSpacing > 0.5 * screenMinSize) {
    //     verticalSpacing = 0.5 * screenMinSize / this.getGridRows();
    //   }
    //   return verticalSpacing;
    // }
  }

  getGridCols() {
    return this.gridSettings?.cols || 4;
  }

  getGridRows() {
    return this.gridSettings?.rows || 3;
  }

  startScan() {
    this.scanIndex = -1;
    this.scanStarted = true;
    clearTimeout(this.scanTimer);
    clearTimeout(this.scanTimerWaitSelection);
    this.updateScan();
  }

  updateScan() {
    if (!this.scanStarted) { return; }

    // Número de cartões visíveis no grid
    const cardsCount = this.components.toArray().length;

    const lastScanIndex = this.scanIndex >= 0 ? this.scanIndex : 0;

    // Passa para o próximo cartão
    this.scanIndex++;

    // Reinicia a varredura ao final do último cartão
    if (this.scanIndex >= cardsCount) {
      this.scanIndex = 0;
    }

    // Remove o destaque da varredura no último item que estava ativo
    const lastAppCardElem = this.components.toArray()[lastScanIndex].nativeElement;
    lastAppCardElem.classList.remove('app-opaque');
    const lastCardElem = lastAppCardElem.children[0];
    lastCardElem.classList.remove('app-scan-card-active');

    // Aplica o destaque no item ativo da varredura
    const appCardElem = this.components.toArray()[this.scanIndex].nativeElement;
    appCardElem.classList.add('app-opaque');
    const cardElem = appCardElem.children[0];
    cardElem.classList.add('app-scan-card-active');

    if (this.app.user.preferences.vocalizeCardsOnScan) {
      const card = this.displayedCards[this.scanIndex];
      this.playAudio(this.mediaStorageService.resolveAudioSrc(card.audio), card).then(() => {
        this.scanTimer = setTimeout(() => { /*console.log('AUDIO CONCLUIDO');*/ this.updateScan(); }, this.app.user.preferences.scanInterval);
      });
    } else {
      this.scanTimer = setTimeout(() => { this.updateScan(); }, this.app.user.preferences.scanInterval);
    }
  }

  onKeydown(ev: KeyboardEvent) {
    if (ev.code === 'Space' || ev.code === 'Enter' || ev.code === 'NumpadEnter') {
      this.onInput();
    }

    if (this.tixMode && (ev.code.includes('Digit') || ev.code.includes('Numpad'))) {
      const keyNum = parseInt(ev.key, 10);

      if (keyNum !== 0 && !isNaN(keyNum)) {
        const cardIndex = this.keyToIndex(keyNum);
        this.onCardSelected(this.cards[cardIndex], cardIndex);
      }
    }
  }

  onInput() {
    if (this.scanStarted) {
      const card = this.displayedCards[this.scanIndex];
      if (typeof card === 'undefined') { return; }

      clearTimeout(this.scanTimer);
      clearTimeout(this.scanTimerWaitSelection);
      this.stopAudio();
      this.ttsService.cancelSpeak();

      setTimeout(() => {
        // console.log('COTINUANDO...');
        this.onCardSelected(card, this.scanIndex);
        if (card._id.includes('next') || card._id.includes('prev') || card._id.includes('back')) {
          this.scanStarted = false;
          this.scanPaused = true;
          this.scanTimerWaitSelection = setTimeout(() => {
            this.startScan();
          }, this.app.user.preferences.scanInterval);
        } else {
          this.scanStarted = false;
          this.scanPaused = true;
        }
      }, 10);
    }
  }

  onCardSelected(card: any, cardIndex: number, ev: any = null) {
    if (this.skipCardSelection) {
      this.skipCardSelection = false;
      return;
    }

    if (this.scanMode && ev) {
      ev.stopPropagation();
      this.onInput();
      return;
    }

    if (card._id.includes('next')) {
      this.nextPage();
      return;
    } else if (card._id.includes('prev')) {
      this.prevPage();
      return;
    } else if (card._id.includes('back')) {
      this.isBacking = true;
      this.back.emit();
      return;
    } else if (card.isCategory) {
      this.gridSnapshots.push({ cards: JSON.parse(JSON.stringify(this.cards)), pageNum: this.pageNum });
      this.cardSelectedIsCategory = true;
    }

    const cardSelectEvent = new CardSelectEvent();
    cardSelectEvent.cardSelected = card;
    cardSelectEvent.cardElementRef = this.components.toArray()[cardIndex].nativeElement;
    cardSelectEvent.cardIndex = cardIndex;
    cardSelectEvent.triggerEv = ev;

    // TODO: ACHO QUE NAO TEM NECESSIDADE DA VARIAVEL cardSelected
    this.cardSelected = card;

    this.select.emit(cardSelectEvent);
    this.changeRef.detectChanges();
  }

  onHoldCard(index: number, card: any, ev: any) {
    this.holdCard(index, card, ev);
  }

  onEditCard(card: any) {
    this.edit.emit(card);
  }

  onDeleteCard(card: any) {
    this.delete.emit(card);
  }

  onVisibilityToggled(card: any) {
    this.toggleVisibility.emit(card);
  }

  onStatusBarAction(card: any, action: string) {
    this.statusBarAction.emit({ card: card, action: action });
  }

  onMoreBtnClicked(card: any, ev: any) {
    this.moreBtnClick.emit({ card: card, click: ev });
  }

  updateGridDimensions() {
    // Workaround 24-06-2020:
    // As dimensões do grid são atualizada para 0 assim que a página deixa de ser a ativa. Ao retornar para a página,
    // essas dimensões podem não ser atualizadas, e permanecem 0. Como workaround, vamos continuar usando o último
    // valor do tamanho do cartão se alguma das dimensões for nula.
    // Obs: Esse comportamento só foi observado até o momento rodando nativamente no Android. Possivelmente está
    // relacionados às compatibilidades/ peculiaridades do WebView mobile
    if (this.gridContainer.nativeElement.offsetWidth === 0 || this.gridContainer.nativeElement.offsetHeight === 0) {
      return;
    }

    // Novos valores da largura e altura
    this.width = this.gridContainer.nativeElement.offsetWidth;
    this.height = this.gridContainer.nativeElement.offsetHeight;
  }

  // Atualiza a largura e altura do Grid
  updateGridSize() {

    // Workaround 24-06-2020:
    // As dimensões do grid são atualizada para 0 assim que a página deixa de ser a ativa. Ao retornar para a página,
    // essas dimensões podem não ser atualizadas, e permanecem 0. Como workaround, vamos continuar usando o último
    // valor do tamanho do cartão se alguma das dimensões for nula.
    // Obs: Esse comportamento só foi observado até o momento rodando nativamente no Android. Possivelmente está
    // relacionados às compatibilidades/ peculiaridades do WebView mobile
    if (this.gridContainer.nativeElement.offsetWidth === 0 || this.gridContainer.nativeElement.offsetHeight === 0) {
      return;
    }

    // Novos valores da largura e altura.
    this.width = this.gridContainer.nativeElement.offsetWidth;
    this.height = this.gridContainer.nativeElement.offsetHeight;

    // Calculates the default margin.
    this.defaultMargin = this.width * 0.005;

    // Calculates card's grid margins when TiX mode is enabled.
    if (this.tixMode) {
      this.calculateTiXCardsMargins();
    }

    // Padding do grid
    this.paddingTop = parseFloat(window.getComputedStyle(this.gridContainer.nativeElement, null).getPropertyValue('padding-top'));
    this.paddingBottom = parseFloat(window.getComputedStyle(this.gridContainer.nativeElement, null).getPropertyValue('padding-bottom'));
    this.paddingLeft = parseFloat(window.getComputedStyle(this.gridContainer.nativeElement, null).getPropertyValue('padding-left'));
    this.paddingRight = parseFloat(window.getComputedStyle(this.gridContainer.nativeElement, null).getPropertyValue('padding-right'));

    if (this.tixMode) {
      this.calcImgSize();
    } else if (this.cardSizeSetByUser > 0 && !this.useGridPaginated) {
      this.cardSize = this.cardSizeSetByUser;
      this.calcCardSize();
    } else if (this.useGridPaginated) {
      this.calcImgSize();
    } else {
      // this.cardSize = this.calcCardSizeForBestFit();
      this.cardSize = this.cardSizeSetByUser;
      this.calcCardSize();
    }

    // Workaround 24-06-2020: Sem essa chamada, o Grid não é atualizado corretamente ao
    // inserir um novo cartão e também na mudança de orientação do celular.
    // Obs: Esse problema ocorreu na versão Android nativo
    this.changeRef.detectChanges();
  }

  calcCardSize() {
    this.cardImgSize = this.cardSize;
    this.cardWidth = this.cardImgSize;
    this.cardHeight = this.cardImgSize + this.cardTitleHeightRatio * this.cardImgSize;
  }

  // Calcula o tamanho da imagem do cartão
  calcImgSize() {
    if (this.useGridPaginated) {
      const cols = this.getGridCols();
      const rows = this.getGridRows();

      // Altura disponível na tela para preenchimento dos cartões. São levados em conta os padding top e bottom
      // e também o espaçamento vertical entre os cartões. 

      let totalHorizontalSpacing = 0;
      let totalVerticalSpacing = 0;

      if (this.gridSettings.paginateSpacingStrategy === 'space-hv-inside') {
        const horizontalSpacing = this.getGridHorizontalSpacing();
        const verticalSpacing = this.getGridVerticalSpacing();

        totalHorizontalSpacing = horizontalSpacing * (cols - 1) + 20 + 20; // 20 (margin left + margin right do container) + 20 (margin left + margin right do cartão)
        totalVerticalSpacing = verticalSpacing * (rows - 1) + 20; // 20 (margin top + margin bottom do cartão)
      } else {
        const horizontalSpacing = this.getGridHorizontalSpacing();
        const verticalSpacing = horizontalSpacing;

        totalHorizontalSpacing = horizontalSpacing * (cols) + 20;
        totalVerticalSpacing = verticalSpacing * (rows) + 10;
      }

      const availableWidth = this.width - this.paddingLeft - this.paddingRight - totalHorizontalSpacing;
      const availableHeight = this.height - this.paddingTop - this.paddingBottom - totalVerticalSpacing;

      this.cardWidth = Math.floor(availableWidth / cols);
      this.cardHeight =  Math.floor(availableHeight /rows);
      this.cardImgSize = Math.floor((this.cardHeight - this.cardWidth > this.cardHeight * 0.30) ? this.cardWidth : this.cardHeight * 0.70);
    } else if (this.tixMode) {
      // FORÇA SER 2. OLHAR ISSO
      this.cols = 3;
      this.rows = 3;

      // Porção referente ao espaçamento vertical
      const verticalSpacingFraction = this.verticalSpacingRatio * (this.rows - 1);

      // Altura do título de cada cartão é proporcional ao tamanho do cartão em si, e por isso,
      // consideramos uma linhha equivalente para levar em conta a presença dos títulos
      const gridRowsEquivalent = this.rows * (1 + this.cardTitleHeightRatio) + verticalSpacingFraction;

      // Área ocupada na vertical sem os cartões
      const filledHeight = this.paddingTop + this.paddingBottom;

      // Cálculo preliminar da altura de cada cartão
      const cardHeight = (this.height - filledHeight) / gridRowsEquivalent;

      // Quando usado com o TiX, a largura de cada símbolo é proporcional à largura de cada cartão, e por isso,
      // consideramos uma coluna equivalente para levar em conta a presença dos símbolos
      let gridColsEquivalent = this.tixMode ? this.cols * 1.0 + (this.keyImgWidth * this.cols * 1.0) / 100 : this.cols * 1.0;

      // Adiciona na coluna equivalente a porção referente ao espaçamento horizontal
      const horSpacingFraction = this.horizontalSpacingRatio * (this.cols - 1);
      gridColsEquivalent = gridColsEquivalent + horSpacingFraction * 1.0;

      // Área ocupada na horizontal sem os cartões
      const filledWidth = this.paddingLeft + this.paddingRight;

      // Cálculo preliminar da largura de cada cartão
      const cardWidth = (this.width - filledWidth * 1.0) / gridColsEquivalent;

      // Como as imagens dos cartões são quadradas, usamos o menor valor da largura
      // ou altura como sendo o tamnho da imagem
      this.cardImgSize = cardHeight > cardWidth ? cardWidth : cardHeight;
      this.cardWidth = this.cardImgSize;
      this.cardHeight = this.cardImgSize + this.cardTitleHeightRatio * this.cardImgSize;
    } else {
      // Falls back. Should not reach here!
      this.calcCardSize();
    }
  }

  /**
   * Calculates the margins for the cards when TiX mode is enabled
   */
  calculateTiXCardsMargins() {
    for (let i = 0; i < 9; i++) {
      this.tixCardsMargins[i].left = this.cardLeftMargin(i);
      this.tixCardsMargins[i].right = this.cardRightMargin(i);
      this.tixCardsMargins[i].bottom = this.cardBottomMargin(i);
      this.tixCardsMargins[i].top = this.cardTopMargin(i);
    }
  }

  // Cálculo da margem esquerda
  cardLeftMargin(index: number) {
    let leftMargin = 0;
    if (index % this.cols > 0) {
      // Margem direita aplicada nos cartões mais a direita para manter o símbolo do TiX visível quando no modo TiX.
      // Fora do modo TiX, essa margem é 0
      const rightMargin = this.tixMode ? (this.keyImgWidth * this.cardWidth) / 100 : 0;

      // Largura ocupada pelo cartão e paddings da esquera e direita e eventual margem no cartão mais a direita
      const filledWidth = this.cardWidth * this.cols + this.paddingLeft + this.paddingRight + rightMargin;

      // Se o cartão e os paddings do grid não estiverem preenchendo toda largura do grid,
      // aplicamos padding nos cartões para ajustarem ao espaço que falta ser preenchido
      if (filledWidth < this.width) {
        leftMargin = (this.width - filledWidth) / (this.cols - 1);
      }
    }

    /**
     * Removes one pixel from left margin when tix mode is on. This avoids messing up the layout specially on preview page.
     */
    leftMargin = this.tixMode ? leftMargin - 1 : leftMargin;

    return leftMargin;
  }

  // Cálculo da margem direita dos cartões mais a direita quando são mostrado os símbolos do TiX
  cardRightMargin(index: number) {
    let rightMargin = 0;
    if (this.tixMode && (index % this.cols) === (this.cols - 1)) {
      rightMargin = (this.keyImgWidth * this.cardWidth) / 100;
    }
    return rightMargin;
  }

  // Cálculo da margem inferior aplicada aos cartões com excessão dos cartões na última linha
  cardBottomMargin(index: number) {
    let bottomMargin = 0;

    // Condição: Todos cartões com excessão daqueles na última linha do grid
    const condition = (index < (this.rows - 1) * this.cols) ? true : false;

    if ((this.rows > 1) && condition) {
      bottomMargin = this.verticalSpacingRatio * this.cardImgSize;
    }

    return bottomMargin;
  }

  // Cálculo da margem superior aplicada aos cartões
  cardTopMargin(index: number) {
    return 0;
  }

  // Cálculo do tamanho do cartão no caso do Grid possuir
  // uma quantidade fixa de itens por página
  cardSizeConstItems(largeSide: number, smallSide: number, itemsPerPage: number) {
    let itemsLargestSide = itemsPerPage;
    let cardSize = 0;
    const freeSpaceLargeSide = largeSide; // TODO: Considerar magem e título do cartão, espaçamentos e paddings
    const freeSpaceSmallSide = smallSide; // TODO: Considerar espaçamentos e paddings
    cardSize = freeSpaceLargeSide / itemsLargestSide;

    largeSide = this.height;
    smallSide = this.width;

    let cols = 1;
    let rows = itemsPerPage;

    let oldCols = 1;
    let oldRows = itemsPerPage;

    for (let i = itemsPerPage; i > 0; i = i / 2) {
      const cardSizeLargeSide = largeSide / itemsLargestSide;
      const cardSizeSmallSide = smallSide / cols;

      if (Math.abs(cardSizeLargeSide - cardSizeSmallSide) <= 100) {
        if (cardSizeLargeSide < cardSizeSmallSide) {
          cardSize = cardSizeLargeSide;
        } else {
          cardSize = cardSizeSmallSide;
        }
        break;
      }

      itemsLargestSide = Math.ceil(1.0 * (itemsLargestSide / 2));

      oldCols = cols;
      oldRows = rows;

      rows = itemsLargestSide;
      cols++;

      cardSize = cardSizeLargeSide;
    }
    return cardSize;
  }

  cardKeyImgSrc(index: number) {
    let keyImgSrc = '';
    if (this.tixMode) {
      switch (index) {
        case 0: keyImgSrc = '../../assets/images/keys/lua.png'; break;
        case 1: keyImgSrc = '../../assets/images/keys/raio.png'; break;
        case 2: keyImgSrc = '../../assets/images/keys/losango.png'; break;
        case 3: keyImgSrc = '../../assets/images/keys/mais.png'; break;
        case 4: keyImgSrc = '../../assets/images/keys/y.png'; break;
        case 5: keyImgSrc = '../../assets/images/keys/vezes.png'; break;
        case 6: keyImgSrc = '../../assets/images/keys/triangulo.png'; break;
        case 7: keyImgSrc = '../../assets/images/keys/estrela.png'; break;
        case 8: keyImgSrc = '../../assets/images/keys/gota.png'; break;
      }
    }
    return keyImgSrc;
  }

  keyToIndex(keyNum: number) {
    switch (keyNum) {
      case 1: return 6;
      case 2: return 7;
      case 3: return 8;
      case 4: return 3;
      case 5: return 4;
      case 6: return 5;
      case 7: return 0;
      case 8: return 1;
      case 9: return 2;
    }
  }

  playAudio(audioSrc: string, card: any): Promise<void> {
    return new Promise((resolve, reject) => {

      // Se o cartão não tiver um arquivo de áudio, será vocalizado o nome do cartão com voz sintetizada
      if (audioSrc === '') {
        this.ttsService.speak(card.title).finally(() => resolve());
        return;
      }

      this.stopAudio();
      if (audioSrc !== '') {

        if (this.app.isRunningOnIos) {
          if (audioSrc.includes('ogg')) {
            audioSrc = audioSrc.replace('.ogg', '.mp3');
          }
        }

        this.audioPlayerService.play(audioSrc).finally(() => {
          resolve();
        });

      } else {
        resolve();
      }
    });
  }

  stopAudio() {
    this.audioPlayerService.stop();
  }

  onBtnClicked(cardIndex) {
  }

  onPinchIn(ev: any) {
    ev.preventDefault();
    const step = Math.round(Math.abs(ev.velocity) * 100);
    if (this.allowPinch && step > 4) {
      this.allowPinch = true;
      if (this.cardSizeSetByUser > this.getCardMinSize()) {
        this.cardSizeSetByUser -= step;
      }
    }
    this.cardSizeSetByUserOld = 0;
  }

  onPinchOut(ev: any) {
    ev.preventDefault();
    const step = Math.round(Math.abs(ev.velocity) * 100);
    if (this.allowPinch && step > 4) {
      this.allowPinch = true;
      if (this.cardSizeSetByUser < this.getCardMaxSize()) {
        this.cardSizeSetByUser += step;
      }
    }
    this.cardSizeSetByUserOld = 0;
  }

  onTouchMove(ev: any) { }

  onPanDown(ev: any) {
    //console.log(ev);
    const step = Math.round(Math.abs(ev.velocity) * 100);
    if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
      this.ionContentElm.getScrollElement().then((el) => {
        el.scrollTop += -step;
      });
    }

    if (typeof this.divContentElm !== 'undefined' && this.divContentElm) {
      this.divContentElm.scrollTop += -step;
    }
  }

  onPanUp(ev: any) {
    //console.log(ev);
    const step = Math.round(Math.abs(ev.velocity) * 100);
    if (typeof this.ionContentElm !== 'undefined' && this.ionContentElm) {
      this.ionContentElm.getScrollElement().then((el) => {
        el.scrollTop += step;
      });
    }

    if (typeof this.divContentElm !== 'undefined' && this.divContentElm) {
      this.divContentElm.scrollTop += step;
    }
  }

  onGridReorderArea(index: number) {
    const card = this.displayedCards[index];

    // For paginated grid, the index is ajusted to reflect the overall index taking into account the current page.
    if (this.useGridPaginated) {
      index = index + this.pageNum * this.pageSize;
    }

    if (this.draggable) {
      this.drag.emit({ floatingCard: this.floatingCard, targetCard: card, cardElementRef: this.components.toArray()[index].nativeElement });
    } else if (card.isOrderable === false) {
      // The card is not reordable. So nothing to do here unless it its prev or next card when paginated grid is used.
      if (this.useGridPaginated && card._id.includes('next')) {
        this.reorderCard(this.floatingCardIndex, index + 2);
        this.releaseCard();
        this.nextPage();
      } else if (this.useGridPaginated && card._id.includes('prev')) {
        this.reorderCard(this.floatingCardIndex, index - 2);
        this.releaseCard();
        this.prevPage();
      }
    } else if (this.floatingCardIndex >= 0) {
      this.reorderCard(this.floatingCardIndex, index);
    }
  }

  reorderCard(floatingCardIndex: number, newPosIndex: number) {
    const floatingCardOrderIndex = this.cardsOrder.findIndex(value => value === floatingCardIndex);

    if (floatingCardOrderIndex === newPosIndex) { return; }

    this.cardsOrder = this.arrayMove(this.cardsOrder, floatingCardOrderIndex, newPosIndex);

    for (let i = 0; i < this.cardsOrder.length; i++) {
      // console.log(this.cardsOrdered[i]._id); 
      const appCardElm = this.components.toArray().find(item => item.nativeElement.id === this.cardsOrdered[i]._id);
      if (appCardElm) {
        appCardElm.nativeElement.style.order = this.cardsOrder.findIndex(value => value === i);
      }
    }

    this.layout();
  }

  arrayMove(arr = [], oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  }

  layout() {
    for (let i = 0; i < this.total; i++) {
      const box = this.boxes[i];

      const lastX = box.x;
      const lastY = box.y;

      box.x = box.node.offsetLeft;
      box.y = box.node.offsetTop;

      // Continue if box hasn't moved
      if (lastX === box.x && lastY === box.y) { continue; }

      // Reversed delta values taking into account current transforms
      const x = box.transform.x + lastX - box.x;
      const y = box.transform.y + lastY - box.y;

      // Tween to 0 to remove the transforms
      TweenLite.fromTo(box.node, 0.5, { x, y }, { x: 0, y: 0 });
    }
  }

  identify(index, item) {
    return item._id;
  }
}
