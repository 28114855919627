import { Injectable } from '@angular/core';
import { ActivityBoard } from '../activities/activity-board/activity-board.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals/globals';
import { AacBoard } from '../aac/aac-board/aac-board.model';
import { User } from '../user/user.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserContact } from '../user/user-contacts/user-contact/user-contact.model';
import { ActivityBoardChange } from '../activities/activity-board/activity-board-change.model';
import { ExpressiaAnalyticsEvent } from '../analytics/expressia-analytics/expressia-analytics-event.model';
import { MarketingPopup } from '../marketing/marketing-popup/marketing-popup.model';
import { IDatabaseMedia } from '../media-storage/database-media.model';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { Feedback } from '../home/feedback/feedback.model';
import { ICreateAacBoardOpts } from '../database/database.service';

// tslint:disable:object-literal-shorthand

@Injectable({
  providedIn: 'root'
})
export class CloudDatabaseService {

  constructor(private app: Globals, private http: HttpClient, private firestore: AngularFirestore, private storageService: StorageService) {
  }

  createActivityBoard(board: ActivityBoard, parentBoard: ActivityBoard = null): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      board._id = this.firestore.createId();
      board.createdAt = Date.now();
      board.updatedAt = Date.now();
      board.owner = this.app.user.uid;

      let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
      if (parentBoard && parentBoard.shared) {
        board.shared = true;
        board.viewedBy = [this.app.user.uid];
        board.membersUid = parentBoard.membersUid;
        collectionPath = `shared-spaces/activities/activity-boards`;
      }

      batch.set(this.firestore.firestore.collection(collectionPath).doc(board._id), JSON.parse(JSON.stringify(board)), { merge: true });
      batch.commit();

      if (parentBoard) {
        this.updateActivityBoard(parentBoard, { cards: this.app.firebaseArrayUnion(board._id), updatedAt: Date.now() });
      }

      resolve(board);
    });
  }

  updateActivityBoard(board: ActivityBoard, updates = null): Promise<ActivityBoard> {
    return new Promise((resolve, reject) => {
      if (updates) {
        board.updatedAt = Date.now();
        updates.updatedAt = Date.now();

        let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
        if (board.shared) {
          collectionPath = `shared-spaces/activities/activity-boards`;
        }

        this.firestore.collection<ActivityBoard>(collectionPath).doc(board._id).update(updates).then().catch(err => {
          console.log(err);
          if (err.toString().includes('No document to update')) {
            // Creates the document since it doesn't exit.
            this.firestore.collection(collectionPath).doc(board._id).set(board, { merge: true });
            this.firestore.collection<ActivityBoard>(collectionPath).doc(board._id).update(updates);
          }
        });
 
       resolve(board);
      } else {
        board.updatedAt = Date.now();
  
        let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
        if (board.shared) {
          collectionPath = `shared-spaces/activities/activity-boards`;
        }
  
        this.firestore.collection<ActivityBoard>(collectionPath).doc(board._id).update(JSON.parse(JSON.stringify(board)));
        resolve(board);
      }
    });
  }

  updateActivityBoardProps(board: ActivityBoard, newProps: any): Promise<void> {
    return new Promise((resolve, reject) => {
      let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
      if (board.shared) {
        collectionPath = `shared-spaces/activities/activity-boards`;
      }

      this.firestore.collection<ActivityBoard>(collectionPath).doc(board._id).update(newProps);

      resolve();
    });
  }

  updateActivitiesBoards(boardChanges: ActivityBoardChange[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      for (const change of boardChanges) {
        change.changes.updatedAt = Date.now();

        let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
        // TODO: Pensar em uma forma de ler propriedade shared do cartão para definir a coleção
        // if (this.activitiesService.isBoardInSharedSpace(change.boardId)) {
        //   collectionPath = `shared-spaces/activities/activity-boards${this.app.langSuffix}`;
        // }

        batch.set(this.firestore.firestore.collection(collectionPath)
          .doc(change.boardId), JSON.parse(JSON.stringify(change.changes)), { merge: true });
      }

      batch.commit();

      resolve();
    });
  }

  updateManyActivitiesBoards(boardChanges: ActivityBoardChange[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      for (const change of boardChanges) {
        let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
        if (change.board.shared) {
          collectionPath = `shared-spaces/activities/activity-boards`;
        }

        batch.set(this.firestore.firestore.collection(collectionPath).doc(change.board._id), change.changes, { merge: true });
      }

      batch.commit();

      resolve();
    });
  }

  deleteActivityBoard(board: ActivityBoard, parentBoard: ActivityBoard, innerCards: ActivityBoard[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      let collectionPath = `users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`;
      if (board.shared) {
        collectionPath = `shared-spaces/activities/activity-boards`;
      }

      // Obs: innerCards inclui também o cartão principal que está sendo removido
      for (const card of innerCards) {
        batch.delete(this.firestore.firestore.collection(collectionPath).doc(card._id));
      }

      batch.commit();

      if (parentBoard) {
        this.updateActivityBoard(parentBoard, { cards: this.app.firebaseArrayRemove(board._id), updatedAt: Date.now() });
      }

      // Envia os nomes das midias associadas com os cartões removidos para a coleção de reciclagem
      const batchMediaRecycle = this.firestore.firestore.batch();

      for (const card of innerCards) {
        if (card.img) {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.img });
        }

        if (card.audio) {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.audio });
        }

        if (card.questionImgSrc) {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.questionImgSrc });
        }

        if (card.questionAudio) {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.questionAudio });
        }
      }
      batchMediaRecycle.commit();

      resolve();
    });
  }

  createAacBoard(board: AacBoard, parentBoard: AacBoard = null, opts: ICreateAacBoardOpts = {}): Promise<AacBoard> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      board._id = (opts && opts.keepId) ? board._id : this.firestore.createId();
      board.createdAt = Date.now();
      board.updatedAt = Date.now();

      batch.set(this.firestore.firestore.collection(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`)
        .doc(board._id), JSON.parse(JSON.stringify(board)));

      if (parentBoard) {
        this.updateAacBoard(parentBoard, { cards: this.app.firebaseArrayUnion(board._id), updatedAt: Date.now() });
      }

      batch.commit();

      resolve(board);
    });
  }

  updateAacBoard(board: AacBoard, updates = null): Promise<AacBoard> {
    return new Promise((resolve, reject) => {
      if (updates) {
         board.updatedAt = Date.now();
         updates.updatedAt = Date.now();

        this.firestore.collection<AacBoard>(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`).doc(board._id).update(updates).then().catch(err => {
          console.log(err);
          if (err.toString().includes('No document to update')) {
            // Creates the document since it doesn't exit.
            this.firestore.collection(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`).doc(board._id).set(board, { merge: true });
            this.firestore.collection<AacBoard>(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`).doc(board._id).update(updates);
          }
        });
  
        resolve(board);
      } else {
        board.updatedAt = Date.now();
        this.firestore.collection<AacBoard>(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`).doc(board._id)
          .set(JSON.parse(JSON.stringify(board)), { merge: true });
  
        resolve(board);
      }
    });
  }

  deleteAacBoard(board: AacBoard, parentBoard: AacBoard, innerCards: AacBoard[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      // Obs: innerCards inclui também o cartão principal que está sendo removido
      for (const card of innerCards) {
        batch.delete(this.firestore.firestore.collection(`users/${this.app.user.uid}/aac-boards${this.app.langSuffix}`)
          .doc(card._id));
      }

      batch.commit();

      this.updateAacBoard(parentBoard, { cards: this.app.firebaseArrayRemove(board._id), updatedAt: Date.now() });

      // Envia os nomes das midias associadas com os cartões removidos para a coleção de reciclagem
      const batchMediaRecycle = this.firestore.firestore.batch();

      for (const card of innerCards) {
        if (card.img !== '') {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.img });
        }

        if (card.audio !== '') {
          batchMediaRecycle.set(this.firestore.firestore.collection('media-recycle')
            .doc(this.firestore.createId()), { uid: this.app.user.uid, mediaName: card.audio });
        }
      }

      batchMediaRecycle.commit();

      resolve();
    });
  }

  updateUserAsync(user: User): Promise<any> {
    const updateDoc = JSON.parse(JSON.stringify(user));
    delete updateDoc._id;
    delete updateDoc.token;
    delete updateDoc.__v;
    delete updateDoc.createdAt;
    delete updateDoc.updatedAt;

    // As configurações de uso com o TiX e de varredura não são salvas
    delete updateDoc.preferences.useScanMode;
    delete updateDoc.preferences.useWithTix;

    return this.firestore.collection('users').doc(user.uid).update(updateDoc);
  }

  updateUser(updates: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.firestore.collection('users').doc(this.app.user.uid).set(updates, { merge: true });
      resolve();
    });
  }

  // Atualiza apenas o bloco de estatísticas
  updateStatsAsync(user: User): Promise<any> {
    const stats = JSON.parse(JSON.stringify(user.statistics));
    const data = {
      statistics: stats
    };
    return this.firestore.collection('users').doc(user.uid).set(data, { merge: true });
  }

  watchUserContacts(uid: string) {
    return this.firestore.collection<UserContact>(`users/${uid}/contacts`).valueChanges();
  }

  updateUserContactAsync(uid: string, contact: UserContact): Promise<any> {
    return this.firestore.collection(`users/${uid}/contacts`).doc(contact.uid).set(contact, { merge: true });
  }

  setContactStatus(uid: string, contact: UserContact, newStatus: string) {
    return this.firestore.collection(`users/${uid}/contacts`).doc(contact.uid).set({ status: newStatus }, { merge: true });
  }

  updateSharedSpaceUserInfo(uid: string, sharedSpace: ActivityBoard, newUserInfo: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const oldUserInfo = sharedSpace.membersInfo.find(member => member.uid === uid);

      this.firestore.collection(`shared-spaces/activities/activity-boards`).doc(sharedSpace._id).update({
        membersInfo: this.app.firebaseArrayRemove(oldUserInfo)
      });

      this.firestore.collection(`shared-spaces/activities/activity-boards`).doc(sharedSpace._id).update({
        membersInfo: this.app.firebaseArrayUnion(newUserInfo)
      });

      resolve();
    });
  }

  deleteUserProfilePhoto(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (user.profile && user.profile.photo) {
        this.firestore.collection('media-recycle').add({ uid: this.app.user.uid, mediaName: user.profile.photo, registeredAt: Date.now() });
      }

      this.firestore.collection('users').doc(user.uid).update({ profile: { photo: '' } });
      resolve();
    });
  }

  deleteMedia(mediaName: string): Promise<any> {
    // tslint:disable-next-line:object-literal-shorthand
    return this.firestore.collection('media-recycle').add({ uid: this.app.user.uid, mediaName: mediaName, registeredAt: Date.now() });
  }

  async logEvent(ev: ExpressiaAnalyticsEvent): Promise<any> {
    return this.firestore.collection('analytics/events/all-events').add(ev);
  }

  getMarketingPopup(): Promise<MarketingPopup> {
    return new Promise((resolve, reject) => {
      this.firestore.collection('marketing').doc('popup').get().toPromise().then(doc => {
        resolve(doc.data() as MarketingPopup);
      }).catch(err => reject(err));
    });
  }

  createCloudDatabaseMedia(media: IDatabaseMedia): Promise<any> {
    return this.firestore.collection('cloud-database-medias').doc(media.name).set(media, { merge: true });
  }

  getCloudDatabaseMedia(mediaName: string): Promise<any> {
    return this.firestore.collection('cloud-database-medias').doc(mediaName).get().pipe(map(doc => doc.data())).toPromise();
  }

  deleteDescriptionPhoto(activity: ActivityBoard, user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (activity.description && activity.description.image) {
        this.firestore.collection('media-recycle').add({
          uid: this.app.user.uid,
          mediaName: activity.description.image,
          registeredAt: Date.now()
        });
      }

      // this.firestore.collection(`users/${this.app.user.uid}/activity-boards${this.app.langSuffix}`).doc(activity._id)
      //   .set({ description: { image: '' } }, { merge: true });

      resolve();
    });
  }

  saveSpokenSentence(sentenceCards: AacBoard[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      let sentence = {
        _id: this.firestore.createId(),
        cards: sentenceCards,
        isFavorite: false,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        date: Date().toString()
      };

      batch.set(this.firestore.firestore.collection(`users/${this.app.user.uid}/spoken-sentences${this.app.langSuffix}`).doc(sentence._id),
        JSON.parse(JSON.stringify(sentence)));

      batch.commit();

      resolve();
    })
  }

  updateSpokenSentence(sentence: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      batch.update(this.firestore.firestore.collection(`users/${this.app.user.uid}/spoken-sentences${this.app.langSuffix}`).doc(sentence._id),
        sentence);

      batch.commit();
      resolve();
    });
  }

  sendFeedback(feedback: Feedback): Promise<Feedback> {
    feedback._id = this.firestore.createId();
    feedback.author = this.app.user.uid;
    feedback.createdAt = Date.now();

    return new Promise((resolve, reject) => {
      const batch = this.firestore.firestore.batch();

      batch.set(this.firestore.firestore.collection('feedbacks').doc(feedback._id), JSON.parse(JSON.stringify(feedback)));
      batch.commit();

      resolve(feedback);
    })
  }

  updateFeedback(feedback: Feedback): Promise<Feedback> {
    return new Promise((resolve, reject) => {
      this.firestore.firestore.collection('feedbacks').doc(feedback._id).set(JSON.parse(JSON.stringify(feedback)), { merge: true });

      resolve(feedback);
    })
  }

  updateSharedActivity(sharedActivityDataId: string, updates: any) {
    return this.firestore.firestore.collection('shared-activity').doc(sharedActivityDataId).set(updates, { merge: true });
  }
}
