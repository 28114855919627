import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'imgSrcPipe'
})
export class ImgSrcPipePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(src: string, ...args: unknown[]): string | SafeUrl {
    if (src.includes('localhost')) {
      return this.sanitizer.bypassSecurityTrustUrl(src);
    } else {
      return src;
    }
  }
}
